import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { get } from 'lib/network'
import { apiUrl } from 'lib/url'
import { pubWithSale, Event } from 'lib/events'
import { getSaleByNumber, getSaleItemsBeingShipped } from 'reducers/sales'
import { showNotification } from 'actions/synchronous'
import { applyShippingOptionToSale } from 'actions/asynchronous'
import ShippingOption from 'components/sales/ShippingOption'
import TopBar from 'components/shared/TopBar'
import IconWithLabel from 'components/shared/IconWithLabel'
import { NotificationTypes } from 'reducers/notifications'
import { orderTotalExceedsValueLimit, getOrderTotalWarning } from 'lib/ValueLimit.jsx'
import logo from 'svg/logo.svg'

export class AddShippingOptionToSale extends Component {
  state = {
    possibleShippingOptions: [],
    isLoading: false,
    error: null,
  }

  async componentDidMount() {
    this.setState({ isLoading: true })

    pubWithSale(Event.SaleShippingOptions.PAGE_VIEW)

    const { response, responseJson } = await get(apiUrl('shipping_options'), {
      sale_number: this.props.match.params.saleNumber,
    })

    this.setState({ isLoading: false })

    if (response.ok) {
      this.setState({ possibleShippingOptions: responseJson.shipping_options })
    } else {
      this.props.showErrorNotification('Error: Unable to receive shipping options for this sale.')
    }
  }

  handleSelectShippingOption = shippingOption => {
    this.setState({ error: null })

    this.props.applyShippingOptionToSale(shippingOption).then(() => {
      const error = this.getValueLimitWarning(shippingOption)

      if (error) {
        this.setState({ error })
      } else {
        pubWithSale(Event.SaleShippingOptions.SELECT_SHIPPING_OPTION, {
          shipping_option: shippingOption.key,
        })

        const { saleNumber, storefrontId } = this.props.match.params
        this.props.history.push(
          `/storefronts/${storefrontId}/sales/${saleNumber}/shipping-complete`,
        )
      }
    })
  }

  getValueLimitWarning(shippingOption) {
    if (!this.props.orderPreview) {
      return null
    }

    const { country } = this.props.sale.shipping_address
    const orderTotal = this.props.orderPreview.total

    if (orderTotalExceedsValueLimit({ country, orderTotal, shippingOption })) {
      return getOrderTotalWarning({ country, shippingOption })
    }
  }

  render() {
    const { params } = this.props.match

    if (this.state.isLoading) {
      return (
        <div>
          <TopBar center={<img src={logo} alt="Welcome to Everlane" className="w16" />} />
          <p className="tc gray-6 f4 ma3">Loading…</p>
        </div>
      )
    }

    return (
      <div>
        <TopBar
          center={<img src={logo} alt="Welcome to Everlane" className="w16" />}
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}/address/add`}
              className="no-underline"
              onClick={() => pubWithSale(Event.SaleShippingOptions.BACK_TO_ADD_ADDRESS)}
            >
              <IconWithLabel icon="arrow-left" iconClassName="f5 pr1" label="Back" color="black" />
            </Link>
          }
        />
        <div className="ma2">
          <p>Please select a shipping option:</p>
          <div>
            {this.state.possibleShippingOptions.map(shippingOption => (
              <ShippingOption
                key={shippingOption.key}
                shippingOptionKey={shippingOption.key}
                onClick={() => this.handleSelectShippingOption(shippingOption)}
                name={shippingOption.description}
                price={shippingOption.amount}
                amountOfTimeToShip={shippingOption.details}
              />
            ))}
          </div>
          {this.state.error && <span className="red-4">{this.state.error}</span>}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    orderPreview: state.orderPreview,
    sale: getSaleByNumber(state.sales, ownProps.match.params.saleNumber),
    saleItemsBeingShipped: getSaleItemsBeingShipped(state.sales, saleNumber),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showErrorNotification: text => dispatch(showNotification(NotificationTypes.ERROR, text)),
    applyShippingOptionToSale: shippingOption =>
      dispatch(applyShippingOptionToSale(ownProps.match.params.saleNumber, shippingOption)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddShippingOptionToSale)

import React from 'react'
import Header from 'containers/Header'
import ProductSearchV2 from 'components/product-search/ProductSearchV2'

export default function StockCheck({ history, match }) {
  return (
    <div>
      <Header textColor="white" />
      <ProductSearchV2 addToSaleOnExactMatch={false} />
    </div>
  )
}

import React from 'react'
import { pubWithSale, Event } from 'lib/events'
import Button, { ButtonStyles } from 'components/shared/Button'
import { Status } from 'reducers/sales'
import { openCashDrawer } from 'lib/cashDrawer'
import { Link } from 'react-router-dom'

export default function CTA({
  location,
  sale,
  saleStatus,
  orderTotal,
  onClick,
  isLoading,
  shouldPromptForStripeTerminal,
  onIssueChange,
  isMobile,
  printerIpAddress,
  stripeError = false,
}) {
  function handleClick(event) {
    switch (saleStatus) {
      case Status.NO_CUSTOMER:
        pubWithSale(Event.Cart.ADD_CUSTOMER_TO_SALE, {
          location: 'primary CTA',
        })
        break
      case Status.NO_PAYMENT:
        pubWithSale(Event.Cart.ADD_PAYMENT_TO_SALE)
        break
      case Status.NO_ADDRESS:
        pubWithSale(Event.Cart.ADD_SHIPPING_ADDRESS_TO_SALE)
        break
      default:
      // no event
    }

    const isCashTransactionInProgress = sale.paid_in_cash && !sale.change_issued && sale.cash_amount
    const isCashAmountExact = orderTotal === sale.cash_amount

    if (isCashTransactionInProgress && !isCashAmountExact) {
      onIssueChange()
      openCashDrawer(printerIpAddress)
      return
    }

    onClick(event)
  }

  const readyForPayment = orderTotal && parseFloat(orderTotal.replace(/[^\d.]/, '')) !== 0

  const text = (() => {
    if (isLoading) {
      return 'Loading…'
    }

    switch (saleStatus) {
      case Status.NO_CUSTOMER:
        return 'Add a Customer'
      case Status.NO_ITEMS:
        return 'Add a Product'
      case Status.NO_PAYMENT:
        if (!orderTotal) {
          return '…'
        }
        if (readyForPayment && shouldPromptForStripeTerminal) {
          return `Charge ${orderTotal} via Stripe`
        }
        if (stripeError) {
          return `Charge ${orderTotal} via Stripe`
        }
        return 'Add Payment Method'

      case Status.NO_ADDRESS:
        return 'Add Shipping Address'
      case Status.READY_FOR_COMPLETION:
        if (!orderTotal) {
          return '...'
        }
        if (parseFloat(orderTotal.replace(/[^\d.]/, '')) === 0) {
          return `Complete Sale (${orderTotal})`
        }
        return `Charge ${orderTotal} to ${sale.payment_method.brand} ${sale.payment_method.last_digits}`

      case Status.IN_PROGRESS_CASH_TRANSACTION:
        return sale.change_issued
          ? `Change Due $${(
              parseFloat(sale.cash_amount.replace(/[^\d.]/, '')) -
              parseFloat(orderTotal.replace(/[^\d.]/, ''))
            ).toFixed(2)}`
          : `Accept ${sale.cash_amount}`
      default:
        return 'Loading…'
    }
  })()

  let isDisabled = {
    [Status.NO_PAYMENT]: location.pathname.includes('payment'),
    [Status.NO_CUSTOMER]: location.pathname.includes('customer'),
    [Status.NO_ITEMS]:
      location.pathname.includes('product') || location.pathname.includes('online-cart'),
    [Status.NO_ADDRESS]: location.pathname.includes('address'),
  }[saleStatus]

  if (readyForPayment) {
    isDisabled = false
  }

  const renderStripeErrorMessage = () => {
    return (
      <div className="ma2 pa2 ba b--gray-8 red-5">
        Stripe connection failure. Manually add a payment method or{' '}
        <Link
          onClick={e => {
            e.preventDefault()
            window.location.reload()
          }}
        >
          refresh
        </Link>{' '}
        the page to try again.
      </div>
    )
  }

  return (
    <>
      {stripeError && renderStripeErrorMessage()}
      <Button
        size="full-width"
        className={isMobile ? 'h6 f4' : 'h10'}
        buttonStyle={ButtonStyles.SECONDARY}
        onClick={handleClick}
        disabled={stripeError || isLoading || isDisabled}
        rounded={false}
      >
        {text}
      </Button>
    </>
  )
}

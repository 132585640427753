import cookies from 'js-cookie'

export function saveDataToBrowser(key, data) {
  return cookies.set(key, data)
}

export function getDataFromBrowser(key) {
  return cookies.get(key)
}

export function removeDataFromBrowser(key) {
  return cookies.remove(key)
}

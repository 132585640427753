import React from 'react'
import { Link } from 'react-router-dom'
import { any, bool, func, object, oneOf, string } from 'prop-types'
import classNames from 'classnames'

export const ButtonStyles = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
}

// An ever evolving default button class for ease of styling, you can pass in
// size (small, medium, large) and color options.
export default function Button({
  size = 'medium',
  color = null,
  style = {},
  type = 'submit',
  fontFamilyClassName = 'sans-serif',
  className,
  onClick,
  children,
  disabled,
  to,
  outlined,
  innerRef,
  buttonStyle,
  rounded = true,
}) {
  const defaultClassName =
    'tc inline-flex items-center justify-center br0 no-underline button-reset outline-0 pointer'

  const sizeClassName = {
    small: 'h6 ph2 pv0 f4',
    medium: 'h6 ph3 pv0 f4',
    large: 'h10 ph4 pv0 f3',
    'full-width': 'h6 w-100 pv0 f3',
  }[size]

  let buttonStyleClassName
  switch (buttonStyle) {
    case ButtonStyles.PRIMARY:
      buttonStyleClassName = 'bg-white black'
      break
    case ButtonStyles.SECONDARY:
      buttonStyleClassName = disabled ? 'bg-gray-8 gray-4' : 'bg-blue-1 white'
      break
    case ButtonStyles.TERTIARY:
      buttonStyleClassName = disabled ? 'bg-gray-8 gray-4' : 'bg-gray-3 white'
      break
    case ButtonStyles.QUATERNARY:
      buttonStyleClassName = disabled ? 'gray-6 b--gray-6' : 'gray-2 b--gray-2 bg-white'
      break
    default:
      break
  }

  let fontColorClassName
  // If a "light" color is specified (e.g. with a lightness greater than 6, like "blue-7"),
  // the button should have dark text of the same hue. We can achieve this by swapping out
  // the lightness value with an opposite darker value. Note: all buttons that aren't
  // "light" get treated with white text.
  const lightness = Number(color?.match(/[6-9]/)?.[0])
  if (outlined) {
    fontColorClassName = color
  } else if (color && lightness > 6) {
    fontColorClassName = `${color.replace(lightness, 9 - lightness)}`
  } else if (color) {
    fontColorClassName = 'white'
  }

  const buttonClassName = classNames(
    defaultClassName,
    className,
    sizeClassName,
    fontColorClassName,
    buttonStyleClassName,
    fontFamilyClassName,
    {
      [`b--${color}`]: outlined && color,
      [`bg-${color}`]: !outlined && color,
      'bg-white': outlined,
      'no-pointer-events': disabled,
      ba: buttonStyle === ButtonStyles.QUATERNARY || outlined,
      bn: buttonStyle !== ButtonStyles.QUATERNARY && !outlined,
      br3: rounded,
    },
  )

  if (to) {
    return (
      <Link
        innerRef={innerRef}
        to={to}
        style={{
          ...style,
          WebkitTapHighlightColor: 'transparent',
        }}
        className={buttonClassName}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }
  return (
    <button
      onTouchStart={() => {}}
      ref={innerRef}
      type={type}
      disabled={disabled}
      style={{
        ...style,
        WebkitTapHighlightColor: 'transparent',
      }}
      className={buttonClassName}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  size: oneOf(['small', 'medium', 'large', 'full-width']),
  buttonStyle: oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
  color: string,
  style: object,
  type: oneOf(['submit', 'button']),
  className: string,
  onClick: func,
  children: any,
  disabled: bool,
  to: string,
  outlined: bool,
}

import React, { Component } from 'react'
import classNames from 'classnames'
import Dialog from 'components/shared/Dialog'
import SourceButton from 'components/sales/SourceButton'

const WAREHOUSE_GC_UPC = '0-00001-20588-6'

export default class WarehouseDialog extends Component {
  state = {
    storefrontButtonStatus: this.props.variant.upc === WAREHOUSE_GC_UPC ? 'disabled' : 'enabled',
    onlineButtonStatus: ['waitlistable', 'sold_out'].includes(this.props.variant.orderable_state)
      ? 'disabled'
      : 'enabled',
  }

  handleMakeRequest = addToSale => {
    const buttonStatusStateKey = {
      [this.props.onChooseOnline]: 'onlineButtonStatus',
      [this.props.onChooseStorefront]: 'storefrontButtonStatus',
    }[addToSale]

    this.setState({ [buttonStatusStateKey]: 'loading' })
    addToSale(this.props.variant).then(response => {
      this.setState({ [buttonStatusStateKey]: 'success' })
      setTimeout(this.props.onRequestClose, 1000)
    })
  }

  render() {
    return (
      <Dialog title="Select a Source" onRequestClose={this.props.onRequestClose} height="20rem">
        <p className="tc f4 w-70 center" style={{ marginBottom: 50 }}>
          It looks like this product is out-of-stock in this storefront. How would you like to
          fulfill this item?
        </p>
        <div
          className={classNames('flex justify-center', {
            'flex-column items-center': this.props.isMobile,
          })}
        >
          <SourceButton
            pipColor={this.props.variant.orderable_state === 'preorderable' ? 'gold-7' : 'blue-5'}
            status={this.state.onlineButtonStatus}
            onClick={() => this.handleMakeRequest(this.props.onChooseOnline)}
            className={this.props.isMobile ? 'mb2' : ''}
          >
            Ship From Online
          </SourceButton>
          <SourceButton
            pipColor="violet-7"
            status={this.state.storefrontButtonStatus}
            onClick={() => this.handleMakeRequest(this.props.onChooseStorefront)}
          >
            Sell From{' '}
            {this.props.isMobile ? this.props.storefront.display_name : this.props.storefront.name}
          </SourceButton>
        </div>
      </Dialog>
    )
  }
}

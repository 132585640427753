import store from 'store'
import { receiveAssociate, setAssociateExpired } from 'actions/synchronous'
import { removeAssociateAuthToken } from 'lib/tokens'

const LOCAL_STORAGE_LOCK_KEY = 'l'

export default function logOut() {
  removeAssociateAuthToken()
  store.dispatch(receiveAssociate(null))
  window.location.reload()
}

export function softLogOut() {
  store.dispatch(setAssociateExpired(true))
}

export function logOutAssociate() {
  removeAssociateAuthToken()
  store.dispatch(receiveAssociate(null))
}

export function lock() {
  window.localStorage.setItem(LOCAL_STORAGE_LOCK_KEY, 1)
}

export function unlock() {
  window.localStorage.removeItem(LOCAL_STORAGE_LOCK_KEY)
}

export function isLocked() {
  return window.localStorage.getItem(LOCAL_STORAGE_LOCK_KEY) !== null
}

export function logOutAfterNoActivity(history) {
  // Once this function gets called (happens in the initial page load), mark the time
  let lastPageViewTime = new Date().getTime()

  // The History object allows us to listen to all routing changes,
  // whenever we see the URL change, update the timestamp. This is how we deem "activity".
  history.listen(() => {
    lastPageViewTime = new Date().getTime()
  })

  // Every minute we check to see if there has been any activity within the
  // last hour. If there hasn't been, we log out the user, which will land them
  // back on the log in page.
  const intervalId = setInterval(() => {
    const now = new Date().getTime()
    const diffInMilliseconds = now - lastPageViewTime
    const diffInHours = Math.floor(diffInMilliseconds / 1000 / 60 / 60)

    if (diffInHours >= 1) {
      logOut()
    }
  }, 60 * 1000)

  return () => clearInterval(intervalId)
}

import React from 'react'
import Button, { ButtonStyles } from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'
import Barcode from 'components/returns-queue/Barcode'

export default function PrintLabelDialog({
  upc,
  title,
  size,
  price,
  onFormSubmit,
  onRequestClose,
}) {
  return (
    <Dialog height="auto" title="Print Label" onRequestClose={onRequestClose}>
      <div className="bg-white ph4">
        <div className="relative printable" style={{ height: '300px' }}>
          <div className="w-60 fl pt5">
            <span className="fw5 db mb1">Style:</span>
            <span className="dib mb2">{title}</span>
            <span className="fw5 db mb1">Size:</span> {size}
            <hr className="db mv2 b--gray-9 bt-0" />
            <span className="db tc f4">${price}</span>
          </div>
          <Barcode
            text={upc}
            style={{
              transform: 'rotate(-90deg)',
              height: '90px',
              position: 'absolute',
              right: '-85px',
              top: '80px',
            }}
          />
        </div>
        <Button
          buttonStyle={ButtonStyles.TERTIARY}
          size="full-width"
          className="f4"
          onClick={onFormSubmit}
        >
          Print Label
        </Button>
      </div>
    </Dialog>
  )
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import PinPad from 'components/auth/Pinpad'
import Notifications from 'components/shared/Notifications'
import LoadingOverlay from 'components/shared/LoadingOverlay'
import { signInAssociate } from 'actions/asynchronous'
import { showNotification } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import logOut, { lock, isLocked } from 'lib/session'
import { post } from 'lib/network'
import { retailApiUrl } from 'lib/url'

const UNLOCKED_ICON =
  'https://res.cloudinary.com/everlane/image/upload/dpr_1.5%2cf_auto%2cq_85/v1/i/61e4ce12_4acb.png'
const LOCKED_ICON =
  'https://res.cloudinary.com/everlane/image/upload/dpr_1.5%2cf_auto%2cq_85/v1/i/fb99a5bf_e301.png'

const ALLOWED_NUMBER_OF_ATTEMPTS = 3

class PleaseSignIn extends Component {
  static propTypes = {
    signInAssociate: func.isRequired,
    showPinPad: bool,
    shouldRefreshOnSuccess: bool,
  }

  static defaultProps = {
    shouldRefreshOnSuccess: true,
  }

  state = {
    pincodeError: '',
    isLoading: false,
    strikes: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.strikes !== prevState.strikes &&
      this.state.strikes >= ALLOWED_NUMBER_OF_ATTEMPTS
    ) {
      lock()
      this.setState({
        pincodeError: 'POS has been locked for security reasons.',
      })
    }
  }

  handleSubmitPincode = async pin => {
    this.setState({ isLoading: true })
    const { response, responseJson } = await post(retailApiUrl('storefronts/login'), {
      pin,
    })

    if (response.ok) {
      this.props.signInAssociate(responseJson.access_token)
      if (this.props.shouldRefreshOnSuccess) {
        window.location.reload(true)
      }
    } else {
      this.setState({
        pincodeError: responseJson.message,
        strikes: this.state.strikes + 1,
      })
    }

    this.setState({ isLoading: false })
  }

  renderHeader() {
    if (this.props.showPinPad) {
      return (
        <div>
          <h4 className="normal sans-serif tc f4 ma0 mb3">
            {this.state.isLoading ? 'Logging You In…' : 'Enter your PIN to proceed.'}
          </h4>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="flex flex-column justify-center">
        {this.state.isLoading && <LoadingOverlay />}
        <Notifications />
        <img
          onClick={logOut}
          alt={isLocked() ? 'locked' : 'unlocked'}
          src={isLocked() ? LOCKED_ICON : UNLOCKED_ICON}
          className="absolute right-2 top-2"
        />
        <div className="flex flex-column items-center justify-end col6 center">
          {this.state.pincodeError && (
            <div
              className="sans-serif absolute top-2 ph2 pv1 red center"
              style={{
                backgroundColor: 'rgb(255, 245, 245)',
                color: 'rgb(253, 103, 105)',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {this.state.pincodeError}
            </div>
          )}
          {this.renderHeader()}
          {this.props.showPinPad && (
            <PinPad
              isLoading={this.state.isLoading}
              onStartInput={() => this.setState({ pincodeError: '' })}
              onSubmit={this.handleSubmitPincode}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signInAssociate: token => dispatch(signInAssociate(token)),
    showError: message => dispatch(showNotification(NotificationTypes.ERROR, message)),
  }
}

export default connect(null, mapDispatchToProps)(PleaseSignIn)

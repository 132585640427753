import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import TopBar from 'components/shared/TopBar'
import Icon from 'components/shared/Icon'

function Dialog({
  onRequestClose,
  title,
  color = 'transparent',
  children,
  isOverflowHidden = true,
  simple = false,
  width = '35rem',
  height = '19rem',
  textColor = 'black',
  exitable = true,
  isMobile = false,
  isFullScreen = false,
}) {
  return (
    <div
      className={classNames(
        'z-9999 top-0 left-0 w-100 h-100 bg-black-30 flex absolute items-center justify-center sans-serif',
        {
          'overflow-auto': isMobile && isFullScreen,
        },
      )}
    >
      <div
        className={classNames(
          'z-4 bg-white',
          {
            'w-90 overflow-y-scroll touch-momentum-scroll': isMobile,
          },
          {
            'absolute top-0': isMobile && isFullScreen,
          },
          {
            'flex justify-between flex-column overflow-hidden': !isMobile,
          },
        )}
        style={isMobile ? {} : { maxHeight: height, width }}
      >
        {!simple && (
          <TopBar
            textColor={textColor}
            color={color}
            center={title}
            isMobile={isMobile}
            right={
              <Icon glyph="x" className="f4 pointer" onClick={onRequestClose} color={textColor} />
            }
          />
        )}
        {simple && exitable && (
          <div className="relative">
            <Icon
              glyph="x"
              className="f4 pointer absolute"
              style={{ color: '#9e9e9e', top: 14, right: 3 }}
              onClick={onRequestClose}
            />
          </div>
        )}
        <div
          className={classNames('h-auto ', {
            pr3: !isMobile,
            pb3: !isMobile,
            pl3: !isMobile,
            pa2: isMobile,
            'overflow-hidden': isOverflowHidden,
            'overflow-auto': !isOverflowHidden,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isMobile: state.ui.isMobile,
  }
}

export default connect(mapStateToProps)(Dialog)

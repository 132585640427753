import React from 'react'
import Icon from 'components/shared/Icon'

export default function SidebarDropdown({
  isExpanded,
  associateName,
  activeStorefrontName,
  onClick,
  children,
}) {
  return (
    <div
      className="overflow-hidden"
      style={{
        transition: 'height 250ms',
        height: isExpanded ? 'auto' : 72,
      }}
    >
      <div
        className="flex flex-column justify-center items-between ph3 h9 bb b--gray-9 lh-solid relative overflow-hidden"
        onClick={onClick}
      >
        <Icon
          glyph="arrow-right"
          className="absolute right-0 f3 mr3"
          color="gray-6"
          style={{
            transition: 'transform 250ms',
            transform: isExpanded ? 'rotate(270deg)' : 'rotate(90deg)',
          }}
        />
        <div className="f4 mb1 fw5 block">
          {!associateName || associateName === ' ' ? 'Storefont' : associateName}
        </div>

        <p className="f6 gray-6 ma0 no-underline">{activeStorefrontName}</p>
      </div>
      {children}
    </div>
  )
}

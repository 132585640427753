import React from 'react'
import LineItem from 'components/shared/LineItem'

export function getSaleItems(orders) {
  const items = orders.map(order => {
    return {
      id: order.id,
      time: order.time,
      number: order.order_number,
      customer_name: order.customer_name.trim().length ? order.customer_name : order.customer_email,
      customer_email: order.customer_email,
      allow_cancel: order.allow_cancel,
      total_count: orders.metadata,
      ambassador: order.ambassador,
      total: order.total,
      total_minus_taxes: order.total_minus_taxes,
      paid_in_cash: order.paid_in_cash,
      line_items: order.items_purchased.map((item, index) => (
        <LineItem
          key={item.id}
          imageUrl={item.image_url}
          source={item.source}
          title={item.title}
          size={item.size}
          upc={item.upc}
          color={item.color}
          quantity={item.quantity}
          restockDate={item.restock_date}
          isFirstItem={index === 0}
          isLastItem={index === order.items_purchased.length - 1}
        />
      )),
    }
  })

  return items
}

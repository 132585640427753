const initialState = {
  results: [],
  customerMetadata: [],
  query: null,
}

export default function orderSearch(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_ORDER_SEARCH_RESULTS':
      return {
        results: action.payload.orders,
        customerMetadata: action.payload.customer_metadata,
        query: action.payload.query,
      }

    case 'CLEAR_ORDER_SEARCH_RESULTS':
      return initialState

    default:
      return state
  }
}

import React, { Component } from 'react'
import { object, string } from 'prop-types'
import JsBarcode from 'jsbarcode'

export default class Barcode extends Component {
  static propTypes = {
    text: string.isRequired,
    style: object,
  }

  static defaultProps = {
    style: {},
  }

  constructor(props) {
    super(props)

    this.svg = React.createRef()
  }

  componentDidMount() {
    new JsBarcode(this.svg.current, this.props.text)
  }

  render() {
    return <canvas style={this.props.style} ref={this.svg} />
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { string, func, bool } from 'prop-types'

import Icon from 'components/shared/Icon'
import CustomerDetailsForm from 'components/customers/CustomerDetailsForm'

class SignUp extends Component {
  static propTypes = {
    onCreateCustomer: func.isRequired,
    email: string,
    isMobile: bool,
    handleBackClick: func,
  }

  handleFormSubmit = customer => {
    this.props.onCreateCustomer({
      email: customer.email,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email_optout: customer.email_optout,
      phone_number: customer.phone_number,
      sms_opt_in: customer.sms_opt_in,
    })
  }

  render() {
    return (
      <div className="w-100 center bg-gray-2 white flex flex-column items-center justify-center ph5 relative vh-100">
        {this.props.handleBackClick && (
          <Icon
            glyph="arrow-left"
            onClick={this.props.handleBackClick}
            className="absolute left-2 top-2"
          />
        )}
        <div
          className={classNames('f2 lh-title tc serif ph3', {
            mb4: !this.props.isMobile,
            mb3: this.props.isMobile,
          })}
        >
          Looks like you&rsquo;re new!
        </div>
        <div
          className={classNames('tc', {
            pb7: !this.props.isMobile,
            pb4: this.props.isMobile,
          })}
        >
          Please enter your name and email preferences for this account.
        </div>

        <CustomerDetailsForm onFormSubmit={this.handleFormSubmit} initialEmail={this.props.email} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { isMobile: state.ui.isMobile }
}

export default connect(mapStateToProps)(SignUp)

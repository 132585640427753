import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { pubWithSale, Event } from 'lib/events'
import { getSaleByNumber } from 'reducers/sales'
import { getStorefrontById } from 'reducers/storefronts'
import TabBar from 'components/shared/TabBar'
import TopBar from 'components/shared/TopBar'
import IconWithLabel from 'components/shared/IconWithLabel'
import ProductSearchV2 from 'components/product-search/ProductSearchV2'

function AddProductToSale(props) {
  return (
    <div>
      <TopBar
        color="blue-4"
        textColor="white"
        left={
          <Link
            to={`/storefronts/${props.match.params.storefrontId}/sales`}
            onClick={() => pubWithSale(Event.Sale.PAUSE_SALE)}
            className="no-underline"
          >
            <IconWithLabel icon="arrow-left" iconClassName="f5 pr1" label="Pause Sale" />
          </Link>
        }
        center="Sale"
      />
      <TabBar
        borderColor="blue-1"
        tabs={[
          {
            title: 'Search for Items',
            to: `/storefronts/${props.storefront.id}/sales/${props.sale.number}/product`,
            notificationCount: 0,
            showNotificationCount: false,
          },
          {
            title: 'Online Cart',
            to: `/storefronts/${props.storefront.id}/sales/${props.sale.number}/online-cart`,
            notificationCount: props.sale.customer?.cart?.line_items.length,
            showNotificationCount: !!props.sale.customer?.cart?.line_items.length,
          },
        ]}
      />
      <ProductSearchV2 onContinueMobile={props.onContinueMobile} />
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    sale: getSaleByNumber(state.sales, ownProps.match.params.saleNumber),
    storefront: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId),
  }
}

export default connect(mapStateToProps)(AddProductToSale)

// Returns the Timezone offset in the format of "-08:00" (example for PST)
export function getTimezoneOffset() {
  let clockOffset

  const pad = (number, length) => {
    let paddedNumber
    paddedNumber = `${number}`
    while (paddedNumber.length < length) {
      paddedNumber = `0${paddedNumber}`
    }
    return paddedNumber
  }

  const offsetFromUtc = new Date().getTimezoneOffset()

  clockOffset = offsetFromUtc < 0 ? '+' : '-'
  clockOffset += pad(parseInt(Math.abs(offsetFromUtc / 60), 10), 2)
  clockOffset += ':'
  clockOffset += pad(Math.abs(offsetFromUtc % 60), 2)

  return clockOffset
}

// Takes a timestamp and returns a user friendly representation, e.g.:
// 4-14-2017, 1:20 PM
export function formatTimestamp(timestamp, format = 'long') {
  const date = new Date(timestamp)

  if (format === 'short') {
    return date.toLocaleString([], {
      month: 'short',
      day: 'numeric',
    })
  }
  if (format === 'medium') {
    return date.toLocaleDateString([], {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getYear().toString().substr(1, 2)
  const dateString = `${month}-${day}-${year}`
  const timeString = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  return `${dateString}, ${timeString}`
}

export function getAmountOfHoursStoreHasBeenOpen(storefront) {
  const date = new Date()

  const openingTime = storefront.open_times[date.getDay()].open_at

  const openingHour = Number(openingTime.substring(0, openingTime.indexOf(':')))

  const currentHour = date.getHours()

  if (currentHour > openingHour) {
    return currentHour - openingHour
  }
  if (currentHour === openingHour) {
    return 1
  }
  return 0
}

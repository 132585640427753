import React, { Fragment } from 'react'
import classNames from 'classnames'
import { staticImageUrl } from 'lib/url'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import TinyPill from 'components/shared/TinyPill'

export default function LineItem({
  imageUrl,
  title,
  size,
  color,
  quantity,
  source,
  upc,
  fulfillmentMethodKey,
  orderableState,
  restockDate,
  usedFirstTry,
  isFirstItem,
  isLastItem,
  showDetails,
  firstTryAllowed,
  isReturn,
}) {
  const tdClassName = classNames({
    pt1: !isFirstItem,
    'pb1 bb b--gray-9': !isLastItem,
  })

  const onlineOrderColor = orderableState === 'preorderable' || restockDate ? 'gold-7' : 'blue-5'

  return (
    <div className="flex">
      <div
        className={`lh-none relative z-0 ${tdClassName}`}
        style={{ width: '45%', maxWidth: '200px' }}
      >
        <img src={staticImageUrl(imageUrl, { size: 200 })} className="w-100 h-auto" alt={title} />
      </div>

      <div className={`lh-copy pl2 w-50 ${tdClassName}`}>
        <span className="f5 fw5">{title}</span>
        <br />
        <span className="f5 gray-6">Size: {size}</span>
        <br />
        {color && <span className="f5 gray-6">Color: {color}</span>}
        <br />
        {showDetails && <span className="f5 gray-6">UPC: {upc}</span>}
        <br />
        {showDetails && (
          <span className="f5 gray-6">
            Source:{' '}
            {source === 'Online' ? (
              <span>
                <span className={classNames('w5', onlineOrderColor)}>Online</span>
                <br />
                {restockDate && (
                  <span className="f5 gold-7">
                    Ships on <FormattedDateTime format="short">{restockDate}</FormattedDateTime>
                  </span>
                )}
              </span>
            ) : (
              <span className="fw5 violet-7">{source}</span>
            )}
          </span>
        )}
        {isReturn && (
          <>
            {usedFirstTry && <TinyPill color="gray-3">First Try Redeemed</TinyPill>}
            {!usedFirstTry && !firstTryAllowed && <TinyPill color="red-4">First Try Used</TinyPill>}
          </>
        )}
      </div>
    </div>
  )
}

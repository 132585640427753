import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import store from 'store'
import { isProduction } from 'lib/env'

export const notify = e => {
  if (isProduction()) {
    Bugsnag.notify(e)
  }
}

export default function () {
  Bugsnag.start({
    apiKey: 'f1066324e118d9b7b85004fd25f99596',
    plugins: [new BugsnagPluginReact()],
    appType: 'browser',
    onError: event => {
      event.setUser(store.getState().associate?.id)
      return true
    },
  })
}

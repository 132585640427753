import React, { useEffect } from 'react'
import TopBar from 'components/shared/TopBar'
import IconWithLabel from 'components/shared/IconWithLabel'
import Button, { ButtonStyles } from 'components/shared/Button'
import { pubWithReturn, Event } from 'lib/events'

export default function Exception({ backPath, color, customerName, history }) {
  useEffect(() => {
    pubWithReturn(Event.Return.EXCEPTION)
  }, [])

  return (
    <div className="vh-100">
      <TopBar
        color={color}
        textColor="white"
        left={
          <IconWithLabel
            onClick={() => history.push(backPath)}
            color="white"
            icon="arrow-left"
            iconClassName="f5 pr1"
            label="Returns Home"
          />
        }
        center="Return Exception"
      />
      <div className="mt6 sans-serif f4 tc w-40 center-vh lh-copy">
        We we unable to refund this return, the return has been put into an exception state. CX will
        be notified about this return and will handle it from here.
        <br />
        <br />
        {customerName} will be emailed when their refund has been processed.
        <div className="mt4 sans-serif f3 tc center lh-copy">
          <Button buttonStyle={ButtonStyles.SECONDARY} to={backPath}>
            Go Back To Returns Home
          </Button>
        </div>
      </div>
    </div>
  )
}

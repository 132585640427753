import React from 'react'

export default function SplitScreen({ mainPane, sidePane }) {
  return (
    <div className="w-100 flex min-vh-100">
      <section className="w-70 br b--gray-6 relative min-vh-100">{mainPane}</section>

      <section className="w-30 relative min-vh-100">{sidePane}</section>
    </div>
  )
}

import React from 'react'
import classNames from 'classnames'

export default function Input({
  className,
  style,
  value,
  onChange,
  id,
  label,
  placeholder,
  TextField,
  disabled,
  required = true,
  type = 'text',
}) {
  return (
    <div className={className} style={style}>
      {label && (
        <label className="db f5" htmlFor={id} style={{ marginBottom: '0.2rem' }}>
          {label}
          {required && '*'}
        </label>
      )}
      {TextField || (
        <input
          required={required}
          type={type}
          className={classNames('ba b--gray-6 f4 h6 ph1 w-100 sans-serif', {
            'select-placeholder': disabled,
          })}
          name={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  )
}

import React, { Component, Fragment } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'css/react_dates_overrides.css'
import moment from 'moment'
import { connect } from 'react-redux'
import { Breakpoint } from 'react-socks'
import classNames from 'classnames'
import { get, post } from 'lib/network'
import {
  startRequest,
  finishRequest,
  showNotification,
  showDialog,
  hideDialog,
} from 'actions/synchronous'
import { retailApiUrl } from 'lib/url'
import { pub, Event } from 'lib/events'
import TransfersTable from 'components/inventory-transfers/TransfersTable'
import InventoryTransfers from 'components/inventory-transfers/mobile/InventoryTransfers'
import { DateRangePicker } from 'react-dates'
import Icon from 'components/shared/Icon'
import { Link } from 'react-router-dom'
import Checkbox from 'components/shared/Checkbox'
import { NotificationTypes } from 'reducers/notifications'
import { isValidTransferNumber, isValidContainerIdentifier } from 'lib/validation'
import Button, { ButtonStyles } from 'components/shared/Button'
import ToggleSwitch from 'components/shared/ToggleSwitch'
import Pagination from 'components/shared/Pagination'
import SearchField from 'components/inventory-transfers/SearchField'

const DATE_FORMAT = 'Y-MM-DD'

export class InboundInventoryTransfers extends Component {
  state = {
    inventoryTransfers: [],
    page: 1,
    startDate: null,
    endDate: null,
    showTodayOnly: false,
    showProcessedOnly: false,
    totalCount: null,
    shouldShowTransfers: false,
    queryType: null,
    query: null,
  }

  limit = 40

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showProcessedOnly !== this.state.showProcessedOnly ||
      prevState.page !== this.state.page ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      (!prevState.shouldShowTransfers && this.state.shouldShowTransfers)
    ) {
      this.fetchInventoryTransfers()
    }
  }

  fetchInventoryTransfers = async (query, queryType) => {
    this.props.startRequest()

    const data = {
      page: this.state.page,
      limit: this.limit,
      start_date: this.state.startDate || '',
      end_date: this.state.endDate || '',
      processed_only: this.state.showProcessedOnly,
    }

    if (query && queryType) {
      data.query = query
      data.query_type = queryType
    }

    try {
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${this.props.match.params.storefrontId}/inventory_transfers`),
        data,
      )

      if (response.ok) {
        this.setState({
          inventoryTransfers: responseJson.inventory_transfers,
          totalCount: responseJson.metadata.count,
        })
      } else {
        this.props.showError(responseJson.message)
      }
    } catch (e) {
      this.props.showError(
        <span>
          An error occured while fetching try on requests.
          <br />
          Share this message with an engineer:
          <br />
          <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
        </span>,
      )
    } finally {
      this.props.finishRequest()
    }
  }

  handleSearchTransfers = query => {
    let queryType

    if (isValidTransferNumber(query)) {
      queryType = 'it_number'
    } else if (isValidContainerIdentifier(query)) {
      queryType = 'container_identifier'
    } else {
      return this.props.showError(
        <div>
          <h3 className="fw5">Invalid IT or Container identifier</h3>
          <p className="f5">
            {query} was scanned.
            <br />
            IT numbers start with "IT" and are 15 or 16 characters long.
            <br />
            Container identifiers are numeric with at most 20 digits.
          </p>
        </div>,
      )
    }

    this.setState({ queryType, query })

    this.fetchInventoryTransfers(query, queryType)
  }

  handleToggleProcessedFilter = () => {
    this.setState(prevState => {
      return { showProcessedOnly: !prevState.showProcessedOnly }
    })
  }

  handlePageChange = ({ page }) => {
    this.setState({ page })
  }

  undoProcessing = async inventoryTransfer => {
    this.props.startRequest()

    const showError = message => {
      this.props.showError(
        <span>
          An error occured while undoing this Inventory Transfer.
          <br />
          Share this message with an engineer:
          <br />
          <code className="mt2 dib bg-gray-9 pv1 ph2 f5">
            {message?.length ? message : 'A server error occured.'}
          </code>
        </span>,
      )
    }

    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${inventoryTransfer.id}/undo`,
        ),
      )

      if (response.ok) {
        this.fetchInventoryTransfers()
        pub(Event.InboundInventoryTransfers.UNDO_PROCESS)
      } else {
        showError(responseJson.message)
      }
    } catch (e) {
      showError(e.message)
    } finally {
      this.props.finishRequest()
    }
  }

  processIntoInventory = async inventoryTransfer => {
    this.props.startRequest()
    const transferId = inventoryTransfer.id

    try {
      const { response, responseJson } = await post(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${transferId}/confirm`,
        ),
      )

      if (response.ok) {
        this.props.hideDialog()
        this.setState({ processedInventoryId: transferId })
        setTimeout(() => {
          this.setState({
            processedInventoryId: null,
            shouldShowTransfers: false,
            inventoryTransfers: [],
            showTodayOnly: false,
            showProcessedOnly: false,
            totalCount: null,
            queryType: null,
            query: null,
          })
        }, 2000)

        pub(Event.InboundInventoryTransfers.PROCESS)
      } else {
        this.props.showError(responseJson.message)
      }
    } catch (e) {
      this.props.showError(
        <span>
          An error occured while fetching try on requests.
          <br />
          Share this message with an engineer:
          <br />
          <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
        </span>,
      )
    } finally {
      this.props.finishRequest()
    }
  }

  handleClearFilters = () => {
    this.setState({
      startDate: null,
      endDate: null,
      showTodayOnly: false,
      showProcessedOnly: false,
    })
  }

  renderMobileFilters() {
    return (
      <div className="flex-date-picker w-100 h-100 flex-column">
        <div className="w-100 ph3">
          <div className="flex w-100 pt2 pb1">
            <div className="w-33">
              <input
                type="date"
                key={this.state.startDate}
                value={this.state.startDate}
                placeholder="Start&nbsp;Date"
                max={moment().format(DATE_FORMAT)}
                className={classNames('bn bg-white f5 gray-2', {
                  'fake-placeholder': !this.state.startDate,
                })}
                onChange={event => this.setState({ startDate: event.target.value })}
              />
            </div>

            <div className="w-33 flex justify-center">
              <input
                type="date"
                key={this.state.endDate}
                value={this.state.endDate}
                placeholder="End&nbsp;Date"
                max={moment().format(DATE_FORMAT)}
                className={classNames('bn bg-white f5 gray-2', {
                  'fake-placeholder': !this.state.endDate,
                })}
                onChange={event => this.setState({ endDate: event.target.value })}
              />
            </div>

            <div className="w-33 flex justify-end">
              <Checkbox
                id="today-only-filter"
                label="Today Only"
                fontSize="f5"
                checked={this.state.showTodayOnly}
                onChange={() => {
                  let startDate = null
                  let endDate = null

                  if (this.state.showTodayOnly) {
                    this.setState({ startDate, endDate, showTodayOnly: false })
                  } else {
                    startDate = endDate = moment().format(DATE_FORMAT)
                    this.setState({ startDate, endDate, showTodayOnly: true })
                  }
                }}
                className="nowrap"
              />
            </div>
          </div>

          <div className="flex w-100 pt1 pb2 f5 justify-center">
            <ToggleSwitch
              showBothLabels
              activeLabel="Processed"
              inActiveLabel="Unprocessed"
              initialActiveState={this.state.showProcessedOnly}
              onClick={async isActive => {
                this.handleToggleProcessedFilter()
                return { didUpdate: true }
              }}
            />
          </div>
        </div>

        <div className="flex w-100 pv1 f5 justify-center bg-gray-9 bt b--gray-9">
          <span>{this.state.totalCount} Transfers</span>

          {(this.state.startDate?.length ||
            this.state.endDate?.length ||
            this.state.showProcessedOnly ||
            this.state.showTodayOnly) && (
            <>
              <span className="dib mh1">|</span>
              <Link className="f5" onClick={this.handleClearFilters}>
                Clear all filters
              </Link>
            </>
          )}
        </div>
      </div>
    )
  }

  renderTabletFilters() {
    return (
      <div className="flex-date-picker w-100 h-100">
        <div className="gray-5 pr4 ml3">Filters:</div>
        <Icon color="gray-5" glyph="clock" />
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="start"
          endDate={this.state.endDate}
          endDateId="end"
          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          isOutsideRange={() => false}
          minimumNights={0}
        />
        <Checkbox
          id="today-only-filter"
          label="Today Only"
          fontSize="f5"
          checked={this.state.showTodayOnly}
          onChange={() => {
            let startDate = null
            let endDate = null

            if (this.state.showTodayOnly) {
              this.setState({ startDate, endDate, showTodayOnly: false })
            } else {
              startDate = endDate = moment()
              this.setState({ startDate, endDate, showTodayOnly: true })
            }
          }}
          className="nowrap mr2"
        />
        <Checkbox
          id="processed-it-filter"
          label="Processed ITs Only"
          fontSize="f5"
          checked={this.state.showProcessedOnly}
          onChange={this.handleToggleProcessedFilter}
          className="nowrap"
        />
        <div className="flex justify-end w-100 pr3">
          <span className="tr f5">{this.state.totalCount} Transfers</span>

          {(this.state.startDate?.length ||
            this.state.endDate?.length ||
            this.state.showProcessedOnly ||
            this.state.showTodayOnly) && (
            <>
              <span className="dib mh1">|</span>
              <Link className="f5" onClick={this.handleClearFilters}>
                Clear all filters
              </Link>
            </>
          )}
        </div>
      </div>
    )
  }

  render() {
    const hasPagination = this.state.totalCount > this.limit
    const searchReturnedNothing = this.state.query && this.state.inventoryTransfers.length === 0

    return (
      <>
        <div className="bb b--gray-9 w-100">
          <SearchField
            autoFocus
            clearOnSubmit
            className="b--none"
            placeholderText="Search by IT or Container identifier"
            onSubmit={this.handleSearchTransfers}
            isLoading={this.props.isLoading}
          />
        </div>

        {this.state.shouldShowTransfers && (
          <>
            <Breakpoint phone only>
              {this.renderMobileFilters()}
            </Breakpoint>
            <Breakpoint tablet up>
              {this.renderTabletFilters()}
            </Breakpoint>
          </>
        )}

        {this.state.inventoryTransfers.length > 0 && (
          <>
            <Breakpoint tablet up>
              <TransfersTable
                currentPage={this.state.page}
                height="auto"
                items={this.state.inventoryTransfers}
                processedInventoryId={this.state.processedInventoryId}
                onCTAClick={inventoryTransfer => {
                  if (inventoryTransfer.processable) {
                    this.processIntoInventory(inventoryTransfer)
                  } else {
                    this.props.history.push(
                      `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/transfer/${inventoryTransfer.id}`,
                    )
                  }
                }}
                onUndoProcessClick={inventoryTransfer => {
                  if (window.confirm('Are you sure you want to undo this processed transfer?')) {
                    this.undoProcessing(inventoryTransfer)
                  }
                }}
              />
            </Breakpoint>

            <Breakpoint phone only>
              <InventoryTransfers
                storefrontId={this.props.match.params.storefrontId}
                history={this.props.history}
                onCTAClick={inventoryTransfer => {
                  if (inventoryTransfer.processable) {
                    this.processIntoInventory(inventoryTransfer)
                  } else {
                    this.props.history.push(
                      `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/transfer/${inventoryTransfer.id}`,
                    )
                  }
                }}
                onAuditClick={inventoryTransfer => {
                  this.props.history.push(
                    `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/transfer/${inventoryTransfer.id}/audit`,
                  )
                }}
                processedInventoryId={this.state.processedInventoryId}
              >
                {this.state.inventoryTransfers}
              </InventoryTransfers>
            </Breakpoint>
          </>
        )}

        {(!this.state.shouldShowTransfers || searchReturnedNothing) && (
          <div className="flex flex-column bg-gray-9 flex-auto pb12">
            <div className="tc gray-6 flex flex-grow-1 flex-column justify-center">
              {this.state.query ? (
                <span className="red fw5">Could not find {this.state.query}.</span>
              ) : (
                <span>Scan an IT to get started or</span>
              )}
              <span
                onClick={() =>
                  this.setState({
                    shouldShowTransfers: true,
                    query: null,
                    queryType: null,
                  })
                }
              >
                view all inbound transfers
              </span>
            </div>

            <div className="bt b--gray-9 w-100 pa3 bg-white shadow-2 absolute bottom-0">
              <Button
                onClick={() =>
                  this.setState({
                    shouldShowTransfers: true,
                    query: null,
                    queryType: null,
                  })
                }
                buttonStyle={ButtonStyles.SECONDARY}
                size="full-width"
                className="f5"
              >
                View All Inbound Transfers
              </Button>
            </div>
          </div>
        )}

        {hasPagination && (
          <Pagination
            width="full"
            totalCount={this.state.totalCount}
            onPageChange={({ page }) => this.setState({ page })}
            limit={this.limit}
          />
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: message => dispatch(showNotification(NotificationTypes.ERROR, message)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
  }
}

export default connect(null, mapDispatchToProps)(InboundInventoryTransfers)

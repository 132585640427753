import React from 'react'
import classNames from 'classnames'
import FormattedDateTime from 'components/shared/FormattedDateTime'

import copyIcon from 'svg/copy.svg'
import check from 'svg/check.svg'

export default function InventoryCountBubble({ variant, storefront, isMobile }) {
  const currentStorefrontInventory = variant.storefront_counts.find(
    storefrontCount => Number(storefrontCount.storefront_id) === Number(storefront.id),
  )
  const nearbyStorefrontInventory = storefront.nearby_storefront
    ? variant.storefront_counts.find(
        storefrontCount => storefrontCount.storefront_id === storefront.nearby_storefront.id,
      )
    : null
  function AvailabilityPip({ color }) {
    return <span className={classNames(color, 'f4')}>• </span>
  }

  const availableQuantity = currentStorefrontInventory.available_quantity
  const nearbyStorefrontAvailableQuantity =
    nearbyStorefrontInventory && nearbyStorefrontInventory.available_quantity

  const displayInfoForOrderable = {
    shippable: {
      message: `${variant.shippable_quantity}`,
      color: 'blue-5',
    },
    preorderable: {
      message: (
        <span>
          {variant.preorderable_quantity} - Backordered
          {variant.restock_date && (
            <span className="db" style={{ marginLeft: '62px' }}>
              Ships on <FormattedDateTime format="short">{variant.restock_date}</FormattedDateTime>
            </span>
          )}
        </span>
      ),
      color: 'gray-9',
    },
    waitlistable: {
      message: (
        <span>
          0 - Waitlist
          {variant.restock_date && (
            <span className="db" style={{ marginLeft: '62px' }}>
              Restock on{' '}
              <FormattedDateTime format="short">{variant.restock_date}</FormattedDateTime>
            </span>
          )}
        </span>
      ),
      color: 'gray-9',
    },
    sold_out: {
      message: `0 - Sold Out`,
      color: 'gray-9',
    },
  }[variant.orderable_state]

  function Triangle() {
    return (
      <div
        className="z-1 absolute bg-white"
        style={{
          content: '',
          width: '10px',
          height: '10px',
          border: '1px solid #9e9e9e',
          borderRight: 'none',
          borderBottom: 'none',
          top: '-6px',
          transform: 'rotate(45deg)',
        }}
      />
    )
  }

  return (
    <div
      className={classNames(
        'lh-title bg-white f5 ma0 pv1 ph2 flex flex-column nowrap z-1 ba b--gray-6',
        { 'absolute h8': !isMobile },
        { 'overflow-x-scroll touch-momentum-scroll mb1 mh2': isMobile },
      )}
      style={isMobile ? {} : { top: '3rem', height: 'inherit' }}
    >
      {!isMobile && <Triangle />}

      <p className="ma0">
        <b>UPC:</b> {variant.upc}{' '}
        <img
          src={copyIcon}
          className="w2 h2 v-mid pointer"
          onClick={event => {
            event.target.src = check
            navigator.clipboard.writeText(variant.upc)

            setTimeout(() => {
              event.target.src = copyIcon
            }, 1000)
          }}
        />
        <br />
        <b>Origin Country:</b> {variant.country}
      </p>

      <p className="ma0">
        <AvailabilityPip color={availableQuantity > 0 ? 'violet-7' : 'gray-9'} />
        <span className="gray-2">
          {isMobile ? storefront.display_name : storefront.name}: {availableQuantity}
        </span>
      </p>

      {nearbyStorefrontInventory && (
        <p className="ma0">
          <AvailabilityPip color={nearbyStorefrontAvailableQuantity > 0 ? 'indigo-7' : 'gray-9'} />
          <span className="gray-2">
            {isMobile
              ? storefront.nearby_storefront.display_name
              : storefront.nearby_storefront.name}
            : {nearbyStorefrontAvailableQuantity}
          </span>
        </p>
      )}

      <p className="ma0">
        <AvailabilityPip color={displayInfoForOrderable.color} />
        <span className="gray-2">Online: {displayInfoForOrderable.message}</span>
      </p>
    </div>
  )
}

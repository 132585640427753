import { useEffect, useState } from 'react'
import { connectReader, discoverReaders, getTerminal } from 'lib/stripe'
import Button from 'components/shared/Button'

export default function ReaderList({ onReaderSelect, stripeLocationId, buttonColor = 'blue-4' }) {
  const [terminal, setTerminal] = useState(null)
  const [attemptedReader, setAttemptedReader] = useState(null)
  const [selectedReader, setSelectedReader] = useState(null)
  const [readers, setReaders] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const discover = async () => {
      setIsLoading(true)

      const terminal = await getTerminal()
      const selectedReader = terminal.getConnectedReader()

      setTerminal(terminal)
      setSelectedReader(selectedReader)

      const discoverResult = await discoverReaders(terminal, {
        location: stripeLocationId,
      })

      setReaders(discoverResult)
      setIsLoading(false)
    }

    discover()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectReader = async reader => {
    setIsLoading(true)
    setErrorMessage(null)
    setAttemptedReader(reader)

    // Disconnects the reader if they are selecting the already connected one
    if (terminal.getConnectedReader()?.serial_number === reader?.serial_number) {
      await terminal.disconnectReader()
      setSelectedReader(null)
      setIsLoading(false)
      setAttemptedReader(null)
      return
    }

    try {
      await connectReader(terminal, reader)
      setSelectedReader(reader)
      onReaderSelect?.(reader)
    } catch (e) {
      setErrorMessage(
        'We could not communicate with the reader. Please double-check that it is turned on and using the same WiFi network as the POS.',
      )
    } finally {
      setIsLoading(false)
      setAttemptedReader(null)
    }
  }

  return (
    <div>
      {isLoading && (
        <div className="loading-animation loading-animation--dark w5 h5 br-100 bl center" />
      )}

      {errorMessage && (
        <p className="red-4 center bg-gray-9 ba b--red-4 br3 pa1 tl lh-copy">{errorMessage}</p>
      )}

      {!isLoading && readers.length === 0 && (
        <p className="red-4 center bg-gray-9 ba b--red-4 br3 pa1 tl lh-copy">
          No nearby readers were found. Are you sure they are turned on?
        </p>
      )}

      <ul className="striped--light-gray list pa0 tl w-100">
        {readers.map(reader => {
          const buttonText = (() => {
            if (attemptedReader?.serial_number === reader.serial_number) {
              return 'Connecting'
            }

            if (selectedReader?.serial_number === reader.serial_number) {
              return 'Connected'
            }

            return 'Connect'
          })()

          return (
            <li className="pa1 flex justify-between items-center" key={reader.label}>
              {`${reader.label} (${reader.serial_number})`}{' '}
              <Button
                outlined
                key={reader.serial_number}
                color={buttonColor}
                size="small"
                className="ml2"
                onClick={() => handleSelectReader(reader)}
              >
                {buttonText}
              </Button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

import getEnv, { isProduction } from 'lib/env'
import roleConfig from 'config/roles'
import cloudinaryUrl from 'lib/cloudinary_url'

export function objectToQueryParams(obj) {
  return `?${Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&')}`
}

export function everlaneUrl(path) {
  const everlaneUrl = process.env.REACT_APP_EVERLANE_URL

  if (path[0] === '/') {
    return everlaneUrl + path
  }

  return `${everlaneUrl}/${path}`
}

export function apiUrl(path) {
  const apiVersion = process.env.REACT_APP_EVERLANE_API_VERSION

  if (path[0] === '/') {
    return everlaneUrl(`/api/v${apiVersion}${path}`)
  }

  return everlaneUrl(`/api/v${apiVersion}/${path}`)
}

export function retailApiUrl(path) {
  if (path[0] === '/') {
    return everlaneUrl(`/retail_api${path}`)
  }

  return everlaneUrl(`/retail_api/${path}`)
}

export function webSocketUrl() {
  // Generates a URL that points to our WebSocket endpoint, e.g.:
  // wss://everlane.com/retail_api/websocket or `ws://localhost:3001/retail_api/websocket`
  const url = new URL(process.env.REACT_APP_EVERLANE_URL)
  url.protocol = isProduction() ? 'wss:' : 'ws:'
  url.pathname = '/retail_api/websocket'

  return url.href
}

export function staticImageUrl(image, options = {}) {
  let path

  if (!image) {
    return null
  }

  // The API may be sending down fully qualified URLs, which will then end up
  // being passed in here. All we really care about is the actual file name,
  // e.g. `i/5fgd_5fds.jpg`, our Imgix and Cloudinary functions can generate
  // full URLs based off of that.
  if (image.includes('res.cloudinary.com')) {
    // The Cloudinary URL for an image might contain an arbitrary number of
    // slashes before the bit we want, which is the filename and the direct folder
    // above it (which could be `i` or `static`).
    // So we are extracting `i/e4feea9d_1ce7.jpg` from
    // `https://res.cloudinary.com/everlane/image/upload/f_auto/v1/i/e4feea9d_1ce7.jpg`
    const matches = image.match(/((?:i|static)\/.+)/i)
    if (matches && matches[1]) {
      path = matches[1]
    } else {
      path = image
    }
  } else if (image.includes('imgix.net')) {
    path = image.split('imgix.net/')[1].replace(/\?.+/, '')
  } else {
    path = image
  }

  return cloudinaryUrl(path, options)
}

export function getQueryParam(name) {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(name) ? decodeURIComponent(queryParams.get(name)) : null
}

export function getAllowedPathsForRoles(roles) {
  return Object.entries(roleConfig).reduce((pathsToKeep, [role, { paths }]) => {
    if (roles.includes(role)) {
      return [...pathsToKeep, ...paths]
    }
    return pathsToKeep
  }, [])
}

export function getAllowedPathsForGroup(group, attrs) {
  const { acceptsCash } = attrs
  if (
    ['Retail Floor Lead', 'Retail Store Lead', 'Retail Headquarters Employee'].includes(group) &&
    acceptsCash
  ) {
    return ['/storefronts/:storefrontId/float']
  }

  return []
}

import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import PleaseSignIn from 'containers/auth/PleaseSignIn'
import StorefrontInterface from 'containers/StorefrontInterface'
import NotFound from 'components/shared/NotFound'
import { getAllowedPathsForRoles } from 'lib/url'

function PageContent({ associate, isMobile }) {
  if (!associate) {
    return (
      <Switch>
        <Route
          render={routeProps => (
            <div className="flex justify-center vh-100">
              <PleaseSignIn {...routeProps} showPinPad />
            </div>
          )}
        />
      </Switch>
    )
  }

  const isAssociateAllowedToSeeSales = getAllowedPathsForRoles(associate.roles).some(path =>
    path.includes('sales'),
  )

  const storetfrontId = associate.geolocated_storefront_id || associate.primary_storefront_id
  let defaultPath = `/storefronts/${storetfrontId}/back-of-house/special`
  if (isAssociateAllowedToSeeSales) {
    defaultPath = `/storefronts/${storetfrontId}/sales`
  }

  return (
    <Switch>
      <Redirect exact from="/" to={defaultPath} />
      <Route path="/storefronts/:storefrontId" component={StorefrontInterface} />
      <Route exact path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  )
}

function mapStateToProps(state) {
  return {
    associate: state.associate,
    isMobile: state.ui.isMobile,
  }
}

export default withRouter(connect(mapStateToProps)(PageContent))

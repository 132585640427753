import React from 'react'
import classNames from 'classnames'
import { any, bool, array, func, object, string } from 'prop-types'

export default function Select({
  className,
  inputClassName,
  labelClassName,
  arrowStyle = {},
  style,
  value,
  options,
  onChange,
  id,
  label,
  showBlank,
  error,
  required,
  disabled,
  defaultValue,
}) {
  return (
    <div className={classNames('relative gray-6', className)} style={style}>
      <span className="absolute f2 fw1 right-1" style={{ top: '2.3rem', ...arrowStyle }}>
        ⌄
      </span>
      {label && (
        <label
          className={classNames('db f5', labelClassName)}
          htmlFor={id}
          style={{ marginBottom: '0.2rem' }}
        >
          {label}
          {required && '*'}
        </label>
      )}
      <select
        type="text"
        className={classNames(
          'ba b--gray-6 br0 input-reset bg-white f4 h6 ph1 w-100 sans-serif',
          inputClassName,
          {
            'select-placeholder': !value,
          },
        )}
        name={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {showBlank && <option>{defaultValue}</option>}
        {options.map(({ title, value }, index) => (
          <option key={value || index} value={value}>
            {title || options[index]}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.propTypes = {
  className: string,
  inputClassName: string,
  labelClassName: string,
  arrowStyle: object,
  style: string,
  value: string,
  options: array,
  onChange: func,
  id: any,
  label: string,
  showBlank: bool,
  error: string,
  required: bool,
  disabled: bool,
  defaultValue: string,
}

import React from 'react'
import classNames from 'classnames'
import { staticImageUrl } from 'lib/url'

export default function ReturnUnit({ imageUrl, title, size, upc, description, returnReason }) {
  return (
    <div>
      <div className="lh-none relative z-0 w-30 fl mr2">
        <img src={staticImageUrl(imageUrl, { size: 88 })} className="h11 w11" alt={title} />
      </div>

      <div className={classNames('w-60 lh-copy fl', { mb2: description })}>
        <span className="f5">{title}</span>
        <br />
        <span className="f5 gray-6">Size: {size}</span>
        <br />
        <span className="f5 gray-6">UPC: {upc}</span>
        <br />
        {returnReason && (
          <span className="f5 gray-6">
            Return Reason: <span className="red nowrap">{returnReason}</span>
          </span>
        )}
      </div>

      {description && (
        <p className="ph2 mr2 pv1 cb gray-5" style={{ backgroundColor: '#f7f7f7' }}>
          {description}
        </p>
      )}
    </div>
  )
}

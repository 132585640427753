import React, { Component, Fragment } from 'react'
import { shape, string, number, bool } from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchReturnReceipt, createNewStorefrontTicket } from 'actions/asynchronous'
import { getStorefrontById } from 'reducers/storefronts'
import { showDialog, hideDialog } from 'actions/synchronous'
import { getReturnReceiptById } from 'reducers/returnReceipts'
import SplitScreen from 'components/shared/SplitScreen'
import AddItemsToReturn from 'containers/returns/AddItemsToReturn'
import AddItemsToReturnMobile from 'containers/returns/mobile/AddItemsToReturn'
import AddRefundMethodToReturn from 'containers/returns/AddRefundMethodToReturn'
import AddRefundMethodToReturnMobile from 'containers/returns/mobile/AddRefundMethodToReturn'
import ReturnReceipt from 'containers/returns/ReturnReceipt'
import Exception from 'components/returns/Exception'
import StorefrontTicketCTA from 'components/shared/StorefrontTicketCTA'
import StorefrontTicketDialog from 'components/shared/StorefrontTicketDialog'
import SelectReturnReasons from 'containers/returns/mobile/SelectReturnReasons'

class ReturnInterface extends Component {
  static propTypes = {
    returnReceipt: shape({ id: number }),
    match: shape({
      params: shape({
        returnReceiptId: string.isRequired,
      }),
    }).isRequired,
    storefrontName: string.isRequired,
    isMobile: bool,
  }

  state = {
    lineItemsMarkedAsOpened: [],
    isSelectingReturnReasons: false,
    hasSelectedReturnReasons: false,
  }

  componentDidMount() {
    this.props.fetchReturnReceipt()
  }

  componentDidUpdate(prevProps) {
    const { storefrontId } = this.props.match.params

    if (
      !prevProps.returnReceipt &&
      this.props.returnReceipt &&
      this.props.returnReceipt.state === 'processed'
    ) {
      return this.props.history.push(`/404?storefrontId=${storefrontId}`)
    }

    if (
      !prevProps.returnReceipt &&
      this.props.returnReceipt?.received_return_units?.every(
        rru => rru.selected_return_reasons.length >= 1,
      )
    ) {
      this.setState({ hasSelectedReturnReasons: true })
    }
  }

  handleMarkItemAsOpened = item => {
    this.setState(state => ({
      lineItemsMarkedAsOpened: [...state.lineItemsMarkedAsOpened, item.id],
    }))
  }

  handleSelectItemsMobile = () => {
    this.setState({ isSelectingReturnReasons: true })
  }

  handleDeselectItemsMobile = () => {
    this.setState({
      isSelectingReturnReasons: false,
      hasSelectedReturnReasons: false,
    })
  }

  renderMainPane() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/items`}
          render={routeProps => {
            if (!this.props.isMobile) {
              return (
                <AddItemsToReturn
                  {...routeProps}
                  onMarkLineItemAsOpened={this.handleMarkItemAsOpened}
                />
              )
            }

            // for mobile
            return this.state.isSelectingReturnReasons || this.state.hasSelectedReturnReasons ? (
              <SelectReturnReasons
                {...routeProps}
                lineItemsMarkedAsOpened={this.state.lineItemsMarkedAsOpened}
                onBack={this.handleDeselectItemsMobile}
                allowEditing
              />
            ) : (
              <AddItemsToReturnMobile
                {...routeProps}
                onMarkLineItemAsOpened={this.handleMarkItemAsOpened}
                onSelectItemsMobile={this.handleSelectItemsMobile}
              />
            )
          }}
        />

        <Route
          path={`${this.props.match.path}/refund`}
          component={this.props.isMobile ? AddRefundMethodToReturnMobile : AddRefundMethodToReturn}
        />

        <Redirect to={`${this.props.match.url}/items`} />
      </Switch>
    )
  }

  renderSidePane() {
    return (
      <Switch>
        <Route
          render={props => (
            <ReturnReceipt
              {...props}
              lineItemsMarkedAsOpened={this.state.lineItemsMarkedAsOpened}
              allowEditing
            />
          )}
        />
      </Switch>
    )
  }

  renderStorefrontTicketCTA() {
    return (
      <StorefrontTicketCTA
        isMobile={this.props.isMobile}
        onClick={() => {
          this.props.showDialog(
            <StorefrontTicketDialog
              onRequestClose={this.props.hideDialog}
              storefrontName={this.props.storefrontName}
              initialCustomerEmail={this.props.returnReceipt.customer.email}
              initialCustomerFullName={this.props.returnReceipt.customer.full_name}
              initialOrderNumber={this.props.returnReceipt.order_number}
              onFormSubmit={formData => this.props.createNewStorefrontTicket(formData)}
            />,
          )
        }}
      />
    )
  }

  render() {
    if (!this.props.returnReceipt) {
      return null
    }

    const height = document.documentElement.clientHeight
    const content = this.props.isMobile ? (
      <div className="relative w-100" style={{ height }}>
        {this.renderMainPane()}
      </div>
    ) : (
      <SplitScreen mainPane={this.renderMainPane()} sidePane={this.renderSidePane()} />
    )

    return (
      <>
        {this.renderStorefrontTicketCTA()}

        {this.props.returnReceipt.state === 'exception' ? (
          <Route
            render={props => (
              <Exception
                {...props}
                backPath={`/storefronts/${this.props.match.params.storefrontId}/returns`}
                color="red-5"
                customerName={this.props.returnReceipt.customer.full_name}
              />
            )}
          />
        ) : (
          content
        )}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    storefrontName: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId).name,
    isMobile: state.ui.isMobile,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { returnReceiptId } = ownProps.match.params

  return {
    fetchReturnReceipt: () => dispatch(fetchReturnReceipt(returnReceiptId)),
    createNewStorefrontTicket: data =>
      dispatch(createNewStorefrontTicket(ownProps.match.params.storefrontId, data)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnInterface)

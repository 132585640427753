import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { pubWithSale, Event } from 'lib/events'
import { createOrder, fetchOrderPreview, fetchSale } from 'actions/asynchronous'
import { showDialog, hideDialog, showNotification } from 'actions/synchronous'
import { getSaleByNumber } from 'reducers/sales'
import { getStorefrontById } from 'reducers/storefronts'
import Button, { ButtonStyles } from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'
import IconWithLabel from 'components/shared/IconWithLabel'
import TopBar from 'components/shared/TopBar'
import LoadingOverlay from 'components/shared/LoadingOverlay'
import { post } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import { getTerminal, collectPaymentMethod, processPayment, getConnectedReader } from 'lib/stripe'
import { NotificationTypes } from 'reducers/notifications'
import ReaderList from 'components/shared/ReaderList'
import classNames from 'classnames'

const CaptureStripeTerminalPayment = function ({
  sale,
  orderPreview,
  match,
  isMobile,
  showDialog,
  hideDialog,
  showNotification,
  history,
  createOrder,
  stripeLocationId,
}) {
  const { storefrontId, saleNumber } = match.params

  const [terminal, setTerminal] = useState(null)
  const [reader, setReader] = useState(null)
  const [connectionStatus, setConnectionStatus] = useState('connecting')
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [paymentSecret, setPaymentSecret] = useState(null)
  const [wasPaymentCancelled, setWasPaymentCancelled] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldShowReaderList, setShouldShowReaderList] = useState(false)

  async function attemptOrderCreation(stripePaymentIntentId) {
    const { storefrontId, saleNumber } = match.params
    const uniqueId = sale.shipping_address ? sale.shipping_address.unique_id : ''

    const handleError = errorMessage => {
      pubWithSale(Event.Cart.CHARGE_SALE_PAYMENT, {
        outcome: 'failure',
      })

      showNotification(`Error charging card. The computer had this to say: ${errorMessage}`)
    }

    try {
      const { response, responseJson } = await createOrder({
        uniqueId,
        stripePaymentIntentId,
      })

      if (response.ok) {
        pubWithSale(Event.Cart.CHARGE_SALE_PAYMENT, {
          outcome: 'success',
        })

        history.push(`/storefronts/${storefrontId}/signature/success?saleNumber=${saleNumber}`)
      } else {
        handleError(responseJson.message)
      }
    } catch (e) {
      handleError()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function attemptPaymentCollection() {
    const amount = orderPreview.total_decimal * 100
    const { response, responseJson } = await post(retailApiUrl('stripe/create_payment_intent'), {
      amount: Math.round(amount),
    })

    if (!response.ok) {
      alert('failure creating secret')
    }

    const clientSecret = responseJson.client_secret
    setPaymentSecret(clientSecret)

    try {
      const paymentIntent = await collectPaymentMethod(terminal, clientSecret)
      setPaymentStatus('processing')

      await processPayment(terminal, paymentIntent)
      attemptOrderCreation(paymentIntent.id)
    } catch (e) {
      setPaymentSecret(null)
      setWasPaymentCancelled(true)
      setErrorMessage(e.message)
      setPaymentStatus(terminal?.getPaymentStatus() || null)

      try {
        await terminal.cancelCollectPaymentMethod()
      } catch (e) {}
    }
  }

  useEffect(() => {
    async function establishTerminalConnection() {
      const term = await getTerminal()
      setTerminal(term)

      try {
        const reader = await getConnectedReader()
        setReader(reader)
        setPaymentStatus(term?.getPaymentStatus() || null)
      } catch (e) {
        setReader(null)
      }

      setConnectionStatus(term.getConnectionStatus())
    }

    establishTerminalConnection()

    return async () => {
      const term = await getTerminal()
      try {
        await term?.cancelCollectPaymentMethod()
      } catch (e) {}

      setPaymentSecret(null)
    }
  }, [])

  useEffect(() => {
    setPaymentStatus(terminal?.getPaymentStatus() || null)
  }, [paymentSecret, connectionStatus, terminal, reader, wasPaymentCancelled])

  useEffect(() => {
    if (connectionStatus === 'connected' && !paymentSecret && !wasPaymentCancelled) {
      attemptPaymentCollection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, paymentSecret, wasPaymentCancelled, terminal])

  useEffect(() => {
    if (errorMessage) {
      showDialog(
        <Dialog
          simple
          onRequestClose={() => {
            hideDialog()
            setErrorMessage(null)
            setWasPaymentCancelled(false)
          }}
          height="auto"
        >
          <div
            className={classNames('tc flex flex-column items-center justify-center pv3', {
              ph1: isMobile,
              ph7: !isMobile,
            })}
          >
            <span
              className={classNames('dib mb3 lh-title', {
                'f4 fw6': isMobile,
                f2: !isMobile,
              })}
            >
              The order was not completed and the card was not charged. The computer had this to
              say:
            </span>

            <span
              className={classNames('gray-6 lh-copy', {
                f5: isMobile,
                f3: !isMobile,
              })}
            >
              {errorMessage}
            </span>
            <br />
            <Button
              size="full-width"
              buttonStyle={ButtonStyles.SECONDARY}
              onClick={() => {
                hideDialog()
                setErrorMessage(null)
                setWasPaymentCancelled(false)
              }}
            >
              Let the customer try again
            </Button>
          </div>
        </Dialog>,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, isMobile])

  const handleSelectReader = async reader => {
    setIsLoading(true)
    try {
      setReader(reader)
    } catch (e) {
      showNotification('Failed to connect reader. Is it on?')
    } finally {
      setIsLoading(false)
    }
  }

  const headerClassName = 'fw5 f2 mt0 mb2'
  const bodyClassName = 'gray-6 f5 w-60 center db lh-copy mt0 mb4'
  const spinner = (
    <div className="loading-animation loading-animation--dark w5 h5 br-100 bl center" />
  )

  return (
    <div className="bg-gray-9 flex flex-column h-100">
      <TopBar
        color="blue-4"
        textColor="white"
        center="Collect Payment with Stripe Reader"
        left={
          <Link
            to={`/storefronts/${storefrontId}/sales/${saleNumber}/payment`}
            className="no-underline flex"
          >
            <IconWithLabel color="white" icon="arrow-left" iconClassName="f5 pr1" label="Sale" />
          </Link>
        }
      />

      <div className="flex justify-center items-center flex-column h-100 tc">
        {isLoading && <LoadingOverlay />}

        {paymentStatus === 'ready' && (
          <>
            <h2 className={headerClassName}>Ready for payment</h2>
            <span className={bodyClassName}>
              You are connected to the reader "{reader?.label}"
              <br />({reader?.serial_number})
            </span>
          </>
        )}

        {paymentStatus === 'processing' && (
          <>
            <h2 className={headerClassName}>Processing payment</h2>
            {spinner}
          </>
        )}

        {paymentStatus === 'waiting_for_input' && (
          <>
            <h2 className={headerClassName}>Waiting for payment</h2>
            <span className={bodyClassName}>
              The user should tap or swipe their card now.
              <br />
              You are connected to the reader "{reader?.label}"
              <br />({reader?.serial_number}).
            </span>
          </>
        )}

        {connectionStatus === 'not_connected' && (
          <div
            className={classNames('pa3 lh-solid', {
              'w-60': !isMobile,
            })}
          >
            {shouldShowReaderList && (
              <>
                <h2 className={headerClassName}>Find a nearby reader</h2>
                <p className={bodyClassName}>
                  Make sure your reader is online and on the same network as your device.
                </p>

                <ReaderList
                  onReaderSelect={handleSelectReader}
                  stripeLocationId={stripeLocationId}
                />
              </>
            )}

            {!shouldShowReaderList && (
              <>
                <h2 className={headerClassName}>Could not connect to a reader.</h2>
                <p className={bodyClassName}>
                  Please make sure your reader is online and on the same network as your device.
                </p>
                <Button color="green-5" size="medium" onClick={() => setShouldShowReaderList(true)}>
                  Find a nearby reader
                </Button>
              </>
            )}
          </div>
        )}

        {connectionStatus === 'connecting' && (
          <>
            <h2 className={headerClassName}>Attempting to connect a reader</h2>
            {spinner}
          </>
        )}
      </div>

      {isMobile && (
        <div className="fixed bottom-0 left-0 w-100 bg-white gray-5">
          <div className="w-100 h4 f4 fw5 lh-solid bt b--gray-9 flex items-center justify-between ph2">
            <span>Total:</span>
            <span>{orderPreview.total}</span>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params
  return {
    orderPreview: state.orderPreview,
    sale: getSaleByNumber(state.sales, saleNumber),
    isMobile: state.ui.isMobile,
    stripeLocationId: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId)
      .stripeLocationId,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    createOrder: params => dispatch(createOrder(saleNumber, params)),
    fetchSale: () => dispatch(fetchSale(saleNumber)),
    fetchOrderPreview: () => dispatch(fetchOrderPreview(saleNumber)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    showNotification: message => dispatch(showNotification(NotificationTypes.ERROR, message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureStripeTerminalPayment)

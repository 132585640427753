import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { pubWithSale, Event } from 'lib/events'
import { isValidPhoneNumber, isValidChinesePhoneNumber } from 'lib/validation'
import { createAddressForSale } from 'actions/asynchronous'
import { getSaleByNumber, getSaleItemsBeingShipped } from 'reducers/sales'
import TopBar from 'components/shared/TopBar'
import IconWithLabel from 'components/shared/IconWithLabel'
import AddressForm from 'components/sales/AddressForm'
import SaleDescriptionSidebar from 'components/sales/SaleDescriptionSidebar'
import logo from 'svg/logo.svg'

class CreateAddressForSale extends Component {
  static propTypes = {
    createAddressForSale: func.isRequired,
  }

  state = {
    formError: null,
  }

  componentDidMount() {
    pubWithSale(Event.SaleNewAddress.PAGE_VIEW)
  }

  addressNeedsUniqueId() {
    const url = window.location.pathname
    const isEditPage = url.substr(url.lastIndexOf('/') + 1) === 'edit'

    return (
      isEditPage &&
      (this.props.sale.shipping_address.unique_id === '' ||
        !this.props.sale.shipping_address.unique_id)
    )
  }

  handleCreateAddressForSale = (address, saveForFutureUse) => {
    const { params } = this.props.match

    if (
      (address.country === 'CN' && (!address.phone || !isValidChinesePhoneNumber(address.phone))) ||
      (address.country !== 'US' && (!address.phone || !isValidPhoneNumber(address.phone)))
    ) {
      return this.setState({ formError: 'Please enter a valid phone number.' })
    }

    pubWithSale(Event.SaleNewAddress.ADD_ADDRESS)

    if (saveForFutureUse) {
      pubWithSale(Event.SaleNewAddress.STORE_ADDRESS)
    }

    this.props
      .createAddressForSale(this.props.sale.customer.email, address, saveForFutureUse)
      .then(() => {
        this.props.history.push(
          `/storefronts/${params.storefrontId}/sales/${params.saleNumber}/shipping`,
        )
      })
  }

  render() {
    const { params } = this.props.match
    const salePath = `/storefronts/${params.storefrontId}/sales/${params.saleNumber}`
    const customerHasOtherAddresses = this.props.sale.customer.addresses.length > 0

    if (!this.props.sale.customer) {
      return <Redirect to={salePath} />
    }

    return (
      <div>
        <TopBar
          left={
            <Link
              to={customerHasOtherAddresses ? `${salePath}/address/add` : `${salePath}/product`}
              onClick={() => {
                if (customerHasOtherAddresses) {
                  pubWithSale(Event.SaleNewAddress.BACK_TO_EXISTING_ADDRESS)
                } else {
                  pubWithSale(Event.SaleNewAddress.BACK_TO_SALE)
                }
              }}
              className="no-underline"
            >
              <IconWithLabel
                color="black"
                icon="arrow-left"
                iconClassName="f5 pr1"
                label={customerHasOtherAddresses ? 'Add Existing Address' : 'Sale'}
              />
            </Link>
          }
          center={<img src={logo} alt="Welcome to Everlane" className="w16" />}
        />
        <div className="flex">
          <div className="w-40 pr2">
            <SaleDescriptionSidebar
              sale={this.props.sale}
              itemsBeingShipped={this.props.saleItemsBeingShipped}
            />
          </div>
          <div className="w-60 pl2">
            <div className="w-80">
              <h4 className="f4 mb2 mt6 normal">Please add a shipping address for these items:</h4>
              <AddressForm
                error={this.state.formError}
                onSubmit={this.handleCreateAddressForSale}
                existingAddress={this.props.sale && this.props.sale.shipping_address}
                needsUniqueId={this.addressNeedsUniqueId()}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    sale: getSaleByNumber(state.sales, saleNumber),
    saleItemsBeingShipped: getSaleItemsBeingShipped(state.sales, saleNumber),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    createAddressForSale: (customerEmail, address, saveForFutureUse) =>
      dispatch(createAddressForSale(customerEmail, saleNumber, address, saveForFutureUse)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAddressForSale)

import React, { Component, Fragment } from 'react'
import { array, func, number, string, bool } from 'prop-types'
import NoItemsMessage from 'components/shared/NoItemsMessage'
import ScrollableTable from 'components/shared/ScrollableTable'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import Button, { ButtonStyles } from 'components/shared/Button'
import classNames from 'classnames'

export default class ItemsTable extends Component {
  static propTypes = {
    currentPage: number.isRequired,
    items: array.isRequired,
    onCancel: func,
    onSendReceipt: func.isRequired,
    height: string,
    isSalesHistoryPage: bool,
  }

  renderTable() {
    const sales = this.props.items.map(item => [
      <>
        {item.ambassador && `${item.ambassador},`} <br />
        <FormattedDateTime>{item.time}</FormattedDateTime>
      </>,
      item.number,
      `$${parseFloat(item.total_minus_taxes || item.total)}${item.paid_in_cash ? ' (Cash)' : ''}`,
      item.customer_name.trim().length ? item.customer_name : item.customer_email,
      <div className="flex flex-column">{item.line_items.map(item => item)}</div>,
      <div className="flex flex-column justify-end items-center">
        <Button
          className="fr f5 mb2 ml1"
          size="medium"
          buttonStyle={ButtonStyles.SECONDARY}
          onClick={() => this.props.onSendReceipt(item, { type: 'sale' })}
        >
          Send Receipt
        </Button>
        <Button
          className="fr f5 mb2 ml1"
          size="medium"
          buttonStyle={ButtonStyles.QUATERNARY}
          onClick={() => this.props.onSendReceipt(item, { type: 'gift' })}
        >
          Send Gift Receipt
        </Button>
        {this.props.isSalesHistoryPage && this.props.onCancel && (
          <Button
            className={classNames('fr f5 ml1', { white: !item.allow_cancel })}
            size="medium"
            color={!item.allow_cancel ? 'gray-9' : 'red-4'}
            disabled={!item.allow_cancel}
            onClick={() => this.props.onCancel(item.number)}
          >
            Cancel Order
          </Button>
        )}
      </div>,
    ])

    const columns = [
      { heading: 'Ambassador, Time', width: '23%' },
      { heading: 'Order No.', width: '15%' },
      { heading: 'Total', width: '15%' },
      { heading: 'Customer', width: '15%' },
      { heading: 'Items Purchased', width: '40%' },
      { heading: '', width: '15%' }, // space for the cancel button
    ]

    return (
      <ScrollableTable
        columns={columns}
        rows={sales}
        keys={this.props.items.map(item => item.id)}
      />
    )
  }

  render() {
    return (
      <div className="w-100 pb5" style={{ height: this.props.height }} key={this.props.currentPage}>
        {this.props.items.length === 0 ? (
          <NoItemsMessage>There are no completed sales at this time.</NoItemsMessage>
        ) : (
          this.renderTable()
        )}
      </div>
    )
  }
}

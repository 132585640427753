const initialState = {
  list: [],
  filter: null,
}

export default function receivedReturnUnits(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_RECEIVED_RETURN_UNITS': {
      return { ...state, list: action.payload.storefront_received_units }
    }

    case 'SET_RETURN_RECEIVED_UNITS_UPC_QUERY': {
      return { ...state, upcQuery: action.payload }
    }

    default:
      return state
  }
}

export function getReceivedReturnUnitsForState(state, desiredReturnState) {
  const returnUnits = state.list.filter(returnUnit => returnUnit.state === desiredReturnState)

  if (state.upcQuery) {
    return returnUnits.filter(returnUnit => returnUnit.item.upc.includes(state.upcQuery))
  }
  return returnUnits
}

import React, { Component, Fragment } from 'react'
import { bool, shape, string, func, oneOf, arrayOf, number } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { pubWithReturn, Event } from 'lib/events'
import { getReturnReceiptById, getReturnReceiptStatus, Status } from 'reducers/returnReceipts'
import {
  selectReturnReason,
  removeReceivedReturnUnit,
  removeCustomerFromReturnReceipt,
  deleteReturnReceipt,
  processReturnReceipt,
} from 'actions/asynchronous'
import {
  getGenderOfReturnUnit,
  isFirstEligibleFirstTryUnit,
  hasLimitedReturnReasons,
  getReturnReasons,
} from 'lib/firstTryProgram'
import TopBar from 'components/shared/TopBar'
import CustomerBanner from 'components/shared/CustomerBanner'
import AddCustomerLink from 'components/shared/AddCustomerLink'
import Scrollable from 'components/shared/Scrollable'
import ReceivedReturnUnit from 'components/returns/ReceivedReturnUnit'
import LimitedReturnReasonMessage from 'components/returns/LimitedReturnReasonMessage'
import FirstTryUseMessage from 'components/returns/FirstTryUseMessage'
import CTA from 'components/returns/CTA'
import Icon from 'components/shared/Icon'
import OrderSummary from 'components/shared/OrderSummary'
import FadeAndHighlight from 'components/shared/transitions/FadeAndHighlight'

class ReturnReceipt extends Component {
  static propTypes = {
    match: shape({ location: shape({ pathname: string }) }).isRequired,
    returnReceipt: shape({ order_number: string }).isRequired,
    returnReceiptStatus: oneOf(Object.keys(Status)),
    selectReturnReason: func,
    removeReceivedReturnUnit: func,
    deleteReturnReceipt: func,
    processReturnReceipt: func,
    lineItemsMarkedAsOpened: arrayOf(number),
    allowEditing: bool,
  }

  static defaultProps = {
    allowEditing: true,
  }

  state = {
    isLoading: false,
  }

  isGiftReturn = () => {
    return this.props.returnReceipt.is_gift_return
  }

  handleClickTrashIcon = () => {
    if (window.confirm('Are you sure that you want to cancel this return?')) {
      pubWithReturn(Event.Cart.TRASH_RETURN)
      this.props.deleteReturnReceipt()
      this.props.history.push(`/storefronts/${this.props.match.params.storefrontId}/returns`)
    }
  }

  handleCTAClick = () => {
    const { storefrontId, returnReceiptId } = this.props.match.params

    if (this.props.returnReceiptStatus === Status.READY_FOR_COMPLETION) {
      pubWithReturn(Event.Cart.PROCESS_RETURN_REFUND)
      this.props.processReturnReceipt(this.props.returnReceipt.refundMethod).then(() => {
        this.props.history.push(
          `/storefronts/${storefrontId}/signature/success?returnNumber=${returnReceiptId}`,
        )
      })
    } else if (this.props.returnReceiptStatus === Status.NO_CUSTOMER) {
      this.props.history.push(
        `/storefronts/${storefrontId}/customers/availability?returnNumber=${returnReceiptId}`,
      )
    } else if (this.props.returnReceiptStatus === Status.NO_REFUND_METHOD) {
      pubWithReturn(Event.Cart.SELECT_REFUND_METHOD)
      this.props.history.push(`${this.props.match.url}/refund`)
    }
  }

  handleSelectReturnReason = async (receivedReturnUnitId, reasonId) => {
    // This check allows us to basically no-op if the associate re-selected the
    // empty (e.g. the default) return reason from the dropdown.
    if (reasonId.length === 0) {
      return
    }

    const receivedReturnUnit = this.props.returnReceipt.received_return_units.find(
      rru => rru.id === receivedReturnUnitId,
    )

    const reason = receivedReturnUnit.available_return_reasons.find(
      reason => reason.id === Number(reasonId),
    )

    pubWithReturn(Event.Cart.SELECT_RETURN_REASON, {
      return_reason: reason.heading,
    })

    this.setState({ isLoading: true })
    await this.props.selectReturnReason(receivedReturnUnitId, reasonId)
    this.setState({ isLoading: false })
  }

  handleDeleteReceivedReturnUnit = receivedReturnUnitId => {
    if (window.confirm('Are you sure you want to remove this item from the return?')) {
      const { title } = this.props.returnReceipt.received_return_units.find(
        rru => rru.id === Number(receivedReturnUnitId),
      )

      pubWithReturn(Event.Cart.REMOVE_ITEM_FROM_RETURN, {
        product_title: title,
      })

      this.props.removeReceivedReturnUnit(receivedReturnUnitId)
    }
  }

  handleRemoveCustomer = () => {
    pubWithReturn(Event.Cart.REMOVE_CUSTOMER_FROM_RETURN)
    this.props.removeCustomer()
  }

  renderReceivedReturnUnit = receivedReturnUnit => {
    const receivedReturnUnits = this.props.returnReceipt.received_return_units

    return (
      <Fragment key={receivedReturnUnit.id}>
        <ReceivedReturnUnit
          id={receivedReturnUnit.id}
          imageUrl={receivedReturnUnit.image_url}
          size={receivedReturnUnit.size}
          upc={receivedReturnUnit.upc}
          price={receivedReturnUnit.price}
          originalPrice={receivedReturnUnit.original_price}
          title={receivedReturnUnit.title}
          color={receivedReturnUnit.color}
          source={receivedReturnUnit.source}
          availableInStore={receivedReturnUnit.available_in_store}
          availableReturnReasons={getReturnReasons(
            receivedReturnUnit,
            receivedReturnUnits,
            this.props.lineItemsMarkedAsOpened,
          )}
          selectedReturnReasons={receivedReturnUnit.selected_return_reasons}
          onSelectReturnReason={this.handleSelectReturnReason}
          allowEditing={this.props.allowEditing}
          onDelete={() => this.handleDeleteReceivedReturnUnit(receivedReturnUnit.id)}
        />
        {hasLimitedReturnReasons(receivedReturnUnit, receivedReturnUnits) && (
          <LimitedReturnReasonMessage
            returnReasonsByName={getReturnReasons(
              receivedReturnUnit,
              receivedReturnUnits,
              this.props.lineItemsMarkedAsOpened,
            ).map(reason => reason.name)}
          />
        )}
        {isFirstEligibleFirstTryUnit(
          receivedReturnUnit,
          receivedReturnUnits,
          getGenderOfReturnUnit(receivedReturnUnit),
        ) && <FirstTryUseMessage />}
      </Fragment>
    )
  }

  render() {
    return (
      <div className="relative w-100" style={{ height: document.documentElement.clientHeight }}>
        <TopBar
          color="red-5"
          textColor="white"
          left={
            this.props.allowEditing && <span className="f6">{this.props.returnReceipt.id}</span>
          }
          center="Return"
          right={
            this.props.allowEditing && (
              <Icon glyph="trash-can" onClick={this.handleClickTrashIcon} />
            )
          }
        />
        {this.props.returnReceipt.customer ? (
          <CustomerBanner
            customer={this.props.returnReceipt.customer}
            allowEditing={this.props.allowEditing}
            onRemoveCustomerClick={this.handleRemoveCustomer}
          />
        ) : (
          <AddCustomerLink
            onClick={() => pubWithReturn(Event.Cart.ADD_CUSTOMER_TO_RETURN)}
            storefrontId={this.props.match.params.storefrontId}
            returnReceiptId={this.props.returnReceipt.id}
          />
        )}
        <Scrollable>
          <FadeAndHighlight>
            {this.props.returnReceipt.received_return_units.map(this.renderReceivedReturnUnit)}
          </FadeAndHighlight>
        </Scrollable>
        <OrderSummary
          {...this.props.returnReceipt.summary}
          negate
          ctaButton={
            this.props.allowEditing && (
              <CTA
                rounded={false}
                disabled={this.state.isLoading}
                returnReceipt={this.props.returnReceipt}
                returnReceiptStatus={this.props.returnReceiptStatus}
                onClick={this.handleCTAClick}
                location={this.props.location}
              />
            )
          }
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    returnReceipt: getReturnReceiptById(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
    ),
    returnReceiptStatus: getReturnReceiptStatus(
      state.returnReceipts,
      ownProps.match.params.returnReceiptId,
      ownProps.location.pathname,
    ),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { returnReceiptId } = ownProps.match.params

  return {
    removeCustomer: () => dispatch(removeCustomerFromReturnReceipt(returnReceiptId)),
    processReturnReceipt: refundMethod =>
      dispatch(processReturnReceipt(returnReceiptId, refundMethod)),
    deleteReturnReceipt: () => dispatch(deleteReturnReceipt(ownProps.match.params.returnReceiptId)),
    selectReturnReason: (receivedReturnUnitId, reasonId) =>
      dispatch(selectReturnReason(returnReceiptId, receivedReturnUnitId, reasonId)),
    removeReceivedReturnUnit: receivedReturnUnitId =>
      dispatch(removeReceivedReturnUnit(returnReceiptId, receivedReturnUnitId)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReturnReceipt))

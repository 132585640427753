import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { get, post, httpDelete } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import { isUpc } from 'lib/validation'
import { pub, Event } from 'lib/events'
import { playErrorSound, playSuccessSound } from 'lib/sound'
import { startRequest, finishRequest, showNotification } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import { getStorefrontById } from 'reducers/storefronts'

import Scrollable from 'components/shared/Scrollable'
import SearchField from 'components/inventory-transfers/SearchField'
import InventoryChangesetTile from 'components/inventory-changesets/InventoryChangesetTile'

export default function InventoryChangesetIndex({ history, match, reason = 'Cycle Count' }) {
  const dispatch = useDispatch()
  const storefronts = useSelector(state => state.storefronts)
  const [inventoryChangesets, setInventoryChangesets] = useState([])
  const [storefront, setStorefront] = useState()

  useEffect(() => {
    setStorefront(getStorefrontById(storefronts, match.params.storefrontId))
    pub(Event.CycleCounts.PAGE_VIEW)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (storefront?.warehouse_id) {
      fetchCycleCounts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storefront])

  const fetchCycleCounts = async (query, queryType) => {
    try {
      dispatch(startRequest())

      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${match.params.storefrontId}/inventory_changesets`),
        {
          warehouse_id: storefront.warehouse_id,
          reason,
        },
      )
      if (response.ok) {
        setInventoryChangesets(responseJson.inventory_changesets)
        // responseJson.metadata.total_count shouldnt be needed here as ICs are cleared every 24 hours
      } else {
        dispatch(showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (e) {
      dispatch(
        showNotification(
          NotificationTypes.ERROR,
          <span>
            An error occured while fetching try on requests.
            <br />
            Share this message with an engineer:
            <br />
            <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
          </span>,
        ),
      )
    } finally {
      dispatch(finishRequest())
    }
  }

  const deleteChangeset = async changesetId => {
    try {
      dispatch(startRequest())

      const { response } = await httpDelete(
        retailApiUrl(
          `storefronts/${match.params.storefrontId}/inventory_changesets/${changesetId}`,
        ),
      )
      if (response.ok) {
        fetchCycleCounts()
      }
    } catch (err) {
      dispatch(showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(finishRequest())
    }
  }

  const placeholderText =
    reason == 'Cycle Count'
      ? 'Scan item to begin a new Cycle Count'
      : 'Scan item to begin a new Damaged Inventory Count'

  return (
    <div>
      <div className="bb b--gray-9 w-100">
        <SearchField
          autoFocus
          clearOnSubmit
          className="b--none"
          placeholderText={placeholderText}
          isLoading={false}
          onSubmit={async upc => {
            const trimmedUpc = upc.trim()

            if (isUpc(trimmedUpc)) {
              // Create new inventory changeset
              const { response, responseJson } = await post(
                retailApiUrl(`storefronts/${storefront.id}/inventory_changesets/`),
                {
                  warehouse_id: storefront.warehouse_id,
                  reason,
                  note: `${reason} for storefront: ${storefront.name} ${storefront.id}`,
                  upc: trimmedUpc,
                },
              )

              const path = reason == 'Cycle Count' ? 'cycle-counts' : 'damages'

              if (response.ok) {
                const { id: newInventoryChangesetId } = responseJson
                playSuccessSound()
                window.location = `/storefronts/${storefront.id}/${path}/${newInventoryChangesetId}`
              } else {
                playErrorSound()
                dispatch(
                  showNotification(
                    NotificationTypes.ERROR,
                    'Unable to find the UPC, please scan again',
                  ),
                )
              }
            }
          }}
        />
      </div>

      <div className="bb mt3 b--gray-9 w-100 ph2">
        <h4 className="tc">
          {reason == 'Cycle Count' ? 'Existing Cycle Counts' : 'Existing Damaged Inventory Counts'}
        </h4>
        <Scrollable>
          {inventoryChangesets.map(inventoryChangeset => (
            <InventoryChangesetTile
              key={`it-${inventoryChangeset.id}`}
              inventoryChangeset={inventoryChangeset}
              storefront={storefront}
              onDelete={deleteChangeset}
              reason={reason}
            />
          ))}
        </Scrollable>
      </div>
    </div>
  )
}

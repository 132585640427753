import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pubWithSale, pubWithReturn, Event } from 'lib/events'
import { addCustomerToSale, addCustomerToReturnReceipt } from 'actions/asynchronous'
import { showNotification } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import EmailAvailabilityForm from 'containers/customers/EmailAvailabilityForm'
import { getQueryParam, objectToQueryParams } from 'lib/url'

class EmailAvailability extends Component {
  saleNumber = getQueryParam('saleNumber')

  returnNumber = getQueryParam('returnNumber')

  rootPath = `/storefronts/${this.props.match.params.storefrontId}`

  componentDidMount() {
    if (this.saleNumber) {
      pubWithSale(Event.SaleAuth.PAGE_VIEW)
    } else {
      pubWithReturn(Event.ReturnAuth.PAGE_VIEW)
    }
  }

  handleCustomerExists = async customer => {
    if (this.saleNumber) {
      pubWithSale(Event.SaleAuth.SUBMIT_EMAIL)
    } else {
      pubWithReturn(Event.ReturnAuth.SUBMIT_EMAIL)
    }

    const emailForParams = window.btoa(customer.email)

    if (this.saleNumber) {
      const { response } = await this.props.addCustomerToSale(customer.email, this.saleNumber)

      if (response.ok) {
        this.props.history.push({
          pathname: `${this.rootPath}/customers/success`,
          search: objectToQueryParams({
            email: emailForParams,
            saleNumber: this.saleNumber,
            customerExisted: true,
          }),
        })
      }
    } else if (this.returnNumber) {
      if (customer.is_banned) {
        return this.props.showErrorNotification(
          'This customer cannot be added to the return, please try a different email',
        )
      }

      const { response } = await this.props.addCustomerToReturnReceipt(
        customer.email,
        this.returnNumber,
      )

      if (response.ok) {
        this.props.history.push({
          pathname: `${this.rootPath}/customers/success`,
          search: objectToQueryParams({
            email: emailForParams,
            returnNumber: this.returnNumber,
            customerExisted: true,
          }),
        })
      }
    } else {
      this.props.history.push({
        pathname: `${this.rootPath}/customers/success`,
        search: objectToQueryParams({
          email: emailForParams,
          customerExisted: true,
        }),
      })
    }
  }

  handleCustomerDoesNotExist = email => {
    const encodedEmail = btoa(encodeURIComponent(email))

    if (this.saleNumber) {
      pubWithSale(Event.SaleAuth.SUBMIT_EMAIL)

      this.props.history.push({
        pathname: `${this.rootPath}/customers/new`,
        search: objectToQueryParams({
          email: encodedEmail,
          saleNumber: this.saleNumber,
        }),
      })
    } else if (this.returnNumber) {
      this.props.history.push({
        pathname: `${this.rootPath}/customers/new`,
        search: objectToQueryParams({
          email: encodedEmail,
          returnNumber: this.returnNumber,
        }),
      })
    } else {
      this.props.history.push({
        pathname: `${this.rootPath}/customers/new`,
        search: objectToQueryParams({
          email: encodedEmail,
          saleNumber: this.saleNumber,
        }),
      })
    }
  }

  handleBackArrow = () => {
    if (this.saleNumber) {
      pubWithSale(Event.SaleAuth.RETURN_TO_SALE)
    } else {
      pubWithReturn(Event.ReturnAuth.BACK_TO_RETURN)
    }

    const backLocation = (() => {
      if (this.saleNumber) {
        return `${this.rootPath}/sales/${this.saleNumber}`
      }
      if (this.returnNumber) {
        return `${this.rootPath}/returns/${this.returnNumber}/items`
      }
      return `${this.rootPath}`
    })()

    this.props.history.push(backLocation)
  }

  render() {
    return (
      <EmailAvailabilityForm
        handleBackClick={this.handleBackArrow}
        isFromSale={!!getQueryParam('saleNumber')}
        onCustomerExists={this.handleCustomerExists}
        onCustomerDoesNotExist={this.handleCustomerDoesNotExist}
      />
    )
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addCustomerToSale: (customerEmail, saleNumber) =>
      dispatch(addCustomerToSale(customerEmail, saleNumber)),
    addCustomerToReturnReceipt: (customerEmail, returnReceiptId) =>
      dispatch(addCustomerToReturnReceipt(customerEmail, returnReceiptId)),
    showErrorNotification: message => dispatch(showNotification(NotificationTypes.ERROR, message)),
  }
}

export default connect(null, mapDispatchToProps)(EmailAvailability)

import React, { Component } from 'react'
import { func, bool } from 'prop-types'
import classNames from 'classnames'

const LENGTH_OF_PINCODE = 6

function Pip({ filled }) {
  return (
    <div
      className={classNames('br-100 h2 w2 mh1 ba b--gray-2', {
        'bg-gray-2': filled,
      })}
    />
  )
}

function NumberButton({ number, className, onPress }) {
  return (
    <button
      className={classNames(
        'bg-white bn f2 mh2 pv3 ph4 sans-serif unselectable outline-0 disable-double-tap-zoom',
        className,
      )}
      onClick={() => onPress(number)}
    >
      {number}
    </button>
  )
}

export default class PinPad extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    isLoading: bool,
  }

  state = {
    code: [], // array of numbers
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate(prevProps) {
    // if the parent is done processing the pincode (and we are still mounted),
    // reset our code state so that the user can try again with a different code,
    if (!this.props.isLoading && this.props.isLoading !== prevProps.isLoading) {
      this.setState({ code: [] })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = event => {
    event.preventDefault()

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
      this.handlePressNumber(Number(event.key))
    }

    if (event.key === 'Backspace') {
      this.handleDelete()
    }
  }

  handlePressNumber = number => {
    if (this.state.isLoading) {
      return
    }

    if (this.state.code.length === 0 && this.props.onStartInput) {
      this.props.onStartInput()
    }

    if (this.state.code.length + 1 === LENGTH_OF_PINCODE) {
      this.setState({ code: [...this.state.code, number] }, () => {
        this.props.onSubmit(this.state.code.join(''))
      })
    } else {
      this.setState({ code: [...this.state.code, number] })
    }
  }

  handleDelete = () => {
    this.setState({ code: this.state.code.slice(0, -1) })
  }

  render() {
    const numberOfFilledPips = this.state.code.length
    const numberOfEmptyPips = LENGTH_OF_PINCODE - numberOfFilledPips

    return (
      <div className="col6">
        <div className="flex justify-between col3 center">
          {Array.from({ length: numberOfFilledPips }, (_, index) => (
            <Pip filled key={index} />
          ))}
          {Array.from({ length: numberOfEmptyPips }, (_, index) => (
            <Pip key={index} />
          ))}
        </div>
        <div className="flex justify-between mt7">
          <NumberButton number={1} onPress={this.handlePressNumber} />
          <NumberButton number={2} onPress={this.handlePressNumber} />
          <NumberButton number={3} onPress={this.handlePressNumber} />
        </div>
        <div className="flex justify-between mt3">
          <NumberButton number={4} onPress={this.handlePressNumber} />
          <NumberButton number={5} onPress={this.handlePressNumber} />
          <NumberButton number={6} onPress={this.handlePressNumber} />
        </div>
        <div className="flex justify-between mt3">
          <NumberButton number={7} onPress={this.handlePressNumber} />
          <NumberButton number={8} onPress={this.handlePressNumber} />
          <NumberButton number={9} onPress={this.handlePressNumber} />
        </div>
        <div className="flex mt3 justify-center relative">
          <NumberButton className="w-third" number={0} onPress={this.handlePressNumber} />
          {this.state.code.length > 0 && (
            <button
              className="absolute right-2 top-2 bg-white bn sans-serif"
              onClick={this.handleDelete}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { get } from 'lib/network'
import { apiUrl } from 'lib/url'
import { getSaleByNumber } from 'reducers/sales'

import { createRetailDiscountAdjustment, fetchOrderPreview } from 'actions/asynchronous'
import { hideDialog } from 'actions/synchronous'

import Button, { ButtonStyles } from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'
import SaleItem from 'components/sales/SaleItem'
import Select from 'components/shared/Select'
import ToggleSwitch from 'components/shared/ToggleSwitch'

export const PERCENTAGE = 'percentage'
export const FIXED_PRICE = 'fixed'

const fetchCreditReasons = async () => {
  const { responseJson } = await get(apiUrl('credit_reasons'), {
    for: 'retail',
  })

  return responseJson.available_reasons
}

function ReasonSelector({ creditReasons, selectedReason, setSelectedReason, isMobile }) {
  return (
    <Select
      className={classNames('center mb2', { 'w-30': !isMobile, 'w-80': isMobile })}
      arrowStyle={{ top: '0.55rem', fontSize: '1.5rem', right: '0.5rem' }}
      value={selectedReason}
      onChange={event => setSelectedReason(event.target.value)}
      options={[
        { title: 'Select a reason…', value: '' },
        ...creditReasons.map(reason => ({
          title: reason === 'CWYP' ? 'Promo' : reason,
          value: reason,
        })),
      ]}
    />
  )
}

function DialogHeader({ setPercentageOrFixedPrice, percentageOrFixedPrice }) {
  return (
    <div className="flex flex-column center pb4">
      <h4 className="mt0 center">Type of Discount:</h4>
      <ToggleSwitch
        showBothLabels
        initialActiveState={percentageOrFixedPrice}
        activeLabel="% Discount"
        inActiveLabel="$ Discount"
        loadingLabel="..."
        onClick={isActive => {
          setPercentageOrFixedPrice(isActive ? PERCENTAGE : FIXED_PRICE)
          return { didUpdate: true }
        }}
      />
    </div>
  )
}

function ProductDiscounts({
  sale,
  percentageOrFixedPrice,
  isMobile,
  newAdjustments,
  handleProductValueChange,
}) {
  return (
    <div className="ba overflow-auto vh-50">
      {sale.sale_items.map((saleItem, index) => {
        const saleItemPriceInt = parseFloat(saleItem.price.replace('$', ''))
        const saleItemOriginalPriceInt = parseFloat(saleItem.original_price.replace('$', ''))
        const saleItemAdjustmentIndex = newAdjustments.findIndex(
          adj => adj.saleItemId === saleItem.id,
        )
        let adjustedPricePreview = saleItem.price
        if (saleItemAdjustmentIndex > -1) {
          const saleItemAdjustmentAmount = newAdjustments[saleItemAdjustmentIndex].value
          if (percentageOrFixedPrice === PERCENTAGE) {
            adjustedPricePreview =
              saleItemOriginalPriceInt -
              (saleItemOriginalPriceInt * parseFloat(saleItemAdjustmentAmount, 10)) / 100
          } else {
            adjustedPricePreview = saleItemPriceInt - saleItemAdjustmentAmount
          }

          // Discount override can never be more than current sale price
          if (adjustedPricePreview > saleItemPriceInt) {
            adjustedPricePreview = saleItemPriceInt
          }
        }
        return (
          <div
            key={`${saleItem.id}-key`}
            className={classNames({
              bb: index !== sale.sale_items.length - 1,
              flex: !isMobile,
              'justify-around': !isMobile,
              block: isMobile,
              pa2: isMobile,
            })}
          >
            <SaleItem
              key={saleItem.id}
              id={saleItem.id}
              title={saleItem.title}
              price={saleItem.price}
              imageUrl={saleItem.image_url}
              size={saleItem.size}
              source={saleItem.source}
              upc={saleItem.upc}
              warehouseId={saleItem.warehouse_id}
              restockDate={saleItem.variant_restock_date}
              orderableState={saleItem.variant_orderable_state}
              availableSources={saleItem.available_sources}
              originalPrice={saleItem.original_price}
              displayPrice
              allowEditing={false}
              isLastItem={index === sale.sale_items.length - 1}
              isFinalSale={saleItem.final_sale}
              onChangeSource={() => {}} /* noop as allowEdit=false */
              onDelete={() => {}} /* noop as allowEdit=false */
            />
            <div
              className={classNames({
                pb2: !isMobile,
              })}
            >
              <form
                className={classNames('black-80 inline', {
                  pt3: !isMobile,
                  pt0: isMobile,
                })}
              >
                <div className="measure">
                  <label htmlFor="item-discount" className="f6 b db mb1">
                    {percentageOrFixedPrice === PERCENTAGE ? '% Discount' : '$ Discount'}
                  </label>
                  <input
                    id="item-discount"
                    max={`${saleItemPriceInt}`}
                    min="0"
                    type="text"
                    className="input-reset ba b--black-20 pa2 mb2 db w-40"
                    defaultValue="0"
                    aria-describedby="item-discount-desc"
                    onChange={e => {
                      const inputValue = e.target.value
                      handleProductValueChange(inputValue, saleItem)
                    }}
                  />
                </div>
              </form>
            </div>
            <div
              className={classNames({
                pt7: !isMobile,
                pt2: isMobile,
                tc: isMobile,
              })}
            >
              New Total Preview:
              {!isNaN(adjustedPricePreview) && adjustedPricePreview !== saleItem.price
                ? ` $${adjustedPricePreview.toFixed(2)}`
                : ` ${saleItem.price}`}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default function AddAdjustmentOverrideDialog({ onRequestClose, saleNumber, isMobile }) {
  const dispatch = useDispatch()
  const sale = useSelector(state => getSaleByNumber(state.sales, saleNumber))

  const [percentageOrFixedPrice, setPercentageOrFixedPrice] = useState(PERCENTAGE)
  const [newAdjustments, setNewAdjustments] = useState([])
  const [creditReasons, setCreditReasons] = useState([])
  const [selectedReason, setSelectedReason] = useState()

  useEffect(() => {
    const fetch = async () => {
      const reasons = await fetchCreditReasons()
      setCreditReasons(reasons)
    }

    fetch()
  }, [])

  const handleProductValueChange = (value, saleItem) => {
    const modifiedAdjustments = [...newAdjustments]
    const modifiedAdjustmentIndex = modifiedAdjustments.findIndex(
      adj => adj.saleItemId === saleItem.id,
    )
    const valueInt = parseInt(value)

    // Set some basic guardrails
    let valueToSet = valueInt
    if (percentageOrFixedPrice === PERCENTAGE) {
      if (valueInt > 100) valueToSet = 100
      else if (valueInt < 0 || !valueInt) valueToSet = 0
    } else if (percentageOrFixedPrice === FIXED_PRICE) {
      const saleItemPriceFloat = parseFloat(saleItem.price.replace('$', ''))
      if (valueInt > saleItemPriceFloat) valueToSet = saleItemPriceFloat
      else if (valueInt < 0 || !valueInt) valueToSet = 0
    }

    if (modifiedAdjustmentIndex > -1) {
      modifiedAdjustments[modifiedAdjustmentIndex] = { saleItemId: saleItem.id, value: valueToSet }
    } else {
      modifiedAdjustments.push({ saleItemId: saleItem.id, value: valueToSet })
    }
    setNewAdjustments(modifiedAdjustments)
  }

  const handleSubmit = () => {
    newAdjustments.forEach(newAdjustment => {
      if (newAdjustment.value <= 0) return

      dispatch(
        createRetailDiscountAdjustment({
          saleItemId: newAdjustment.saleItemId,
          value: newAdjustment.value,
          promoType: percentageOrFixedPrice,
          reason: selectedReason,
        }),
      )
        .then(() => dispatch(hideDialog()))
        .then(() => dispatch(fetchOrderPreview(saleNumber)))
    })
  }

  return (
    <Dialog
      height="100vh"
      width="75vw"
      simple
      isOverflowHidden={false}
      onRequestClose={onRequestClose}
      isFullScreen={isMobile}
    >
      <div
        className={classNames('flex flex-column overflow-auto', {
          mt2: isMobile,
        })}
      >
        <DialogHeader
          creditReasons={creditReasons}
          percentageOrFixedPrice={percentageOrFixedPrice}
          setPercentageOrFixedPrice={setPercentageOrFixedPrice}
        />
        <ProductDiscounts
          sale={sale}
          percentageOrFixedPrice={percentageOrFixedPrice}
          isMobile={isMobile}
          newAdjustments={newAdjustments}
          handleProductValueChange={handleProductValueChange}
        />
      </div>
      <div className="flex pt2 justify-around flex-column">
        <ReasonSelector
          creditReasons={creditReasons}
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
          isMobile={isMobile}
        />
        <Button
          disabled={!selectedReason || newAdjustments.length < 1}
          className={classNames('center', { 'w-30': !isMobile, 'w-80': isMobile })}
          buttonStyle={ButtonStyles.SECONDARY}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}

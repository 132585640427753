import React from 'react'
import classNames from 'classnames'
import InventoryCountBubble from 'components/sales/InventoryCountBubble'

function StorefrontPip({ color, isActive }) {
  return (isActive && <span className={color}>•</span>) || null
}

export default function VariantSelectionBox({
  index,
  variant,
  isActive,
  storefront,
  onClick,
  isMobile,
}) {
  const inventory = variant.storefront_counts.find(
    storefrontCount => storefrontCount.storefront_id === storefront.id,
  )

  const nearbyInventory = storefront.nearby_storefront
    ? variant.storefront_counts.find(
        storefrontCount => storefrontCount.storefront_id === storefront.nearby_storefront.id,
      )
    : null

  const pips = [
    <StorefrontPip
      key="0"
      color={inventory.available_quantity > 0 ? 'violet-7' : 'gray-9'}
      isActive={inventory}
    />,
    <StorefrontPip
      key="1"
      color={nearbyInventory && nearbyInventory.available_quantity > 0 ? 'indigo-7' : 'gray-9'}
      isActive={nearbyInventory}
    />,
    <StorefrontPip
      key="2"
      color={variant.shippable_quantity > 0 ? 'blue-5' : 'gray-9'}
      isActive={inventory}
    />,
  ]

  return (
    <div className="relative">
      <div
        className={classNames(
          'lh-solid pointer b-white h5 tc ma0',
          {
            'ba b--medium-gray border-box': isActive,
          },
          variant.size.length > 4 ? 'w8' : 'w5',
        )}
        onClick={() => onClick(variant)}
        style={{
          padding: '1px',
        }}
      >
        <div className="lh-copy tc f5">{variant.size === 'ONE SIZE' ? 'OS' : variant.size}</div>
        {pips}
      </div>
      {isActive && !isMobile && <InventoryCountBubble variant={variant} storefront={storefront} />}
    </div>
  )
}

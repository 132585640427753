import { isValidChineseResidentId } from 'lib/validation'

// source: es.wikipedia.org/wiki/Identificaci%C3%B3n_tributaria for id naming conventions
const UNIQUE_ID_COUNTRY_CONFIG = Object.freeze({
  BR: { label: 'CPF*', requiredLength: 11 },
  CL: { label: 'RUT*', requiredLength: 8 },
  CN: { label: 'Chinese ID Number*', requiredLength: 18 },
  CO: { label: 'NIT*', requiredLength: 8 },
  KR: { label: 'RRN*', requiredLength: 13 },
  PE: { label: 'RUC*', requiredLength: 8 },
  UY: { label: 'RUT*', requiredLength: 7 },
})

export function getIdError(value, country) {
  const lengthError = getIdLengthError(value, country)
  if (lengthError) {
    return lengthError
  }

  if (country === 'CN') {
    return getChineseResidentIdError(value)
  }
}

export function getUniqueIdText(country) {
  const countryConfig = UNIQUE_ID_COUNTRY_CONFIG[country]
  return countryConfig ? { label: countryConfig.label } : { label: 'Tax ID*' }
}

function getChineseResidentIdError(value) {
  if (!isValidChineseResidentId(value)) {
    return 'ID is invalid'
  }
}

function getIdLengthError(value, country) {
  if (
    UNIQUE_ID_COUNTRY_CONFIG[country] &&
    value.length < UNIQUE_ID_COUNTRY_CONFIG[country].requiredLength
  ) {
    return 'Too short'
  }
}

import React from 'react'
import classNames from 'classnames'
import { array } from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Scrollable from 'components/shared/Scrollable'

// Renders a full-width n-column table that supports scrolling of the tbody,
// while keeping the thead in place.
// Pass an array of columns and an array of corresponding rows, e.g.:
// columns = [
//   {heading: 'Name'},
//   {heading: 'Size'},
//   {heading: 'Price'},
// ];
//
// rows = [
//   ['Cappucino', '6oz', '$3.75'],
//   ['Latte', '8oz', '$4.50'],
//   ['Cold Brew', '12oz', '$3.00'],
// ]
//
// Whose output would like like:
// +-----------+------+-------+
// |   Name    | Size | Price |
// +-----------+------+-------+
// | Cappucino | 6oz  | $3.75 |
// | Latte     | 8oz  | $4.50 |
// | Cold Brew | 12oz | $3.00 |
// +-----------+------+-------+
//
function ScrollableTable({ columns, rows = [], keys = null, style, rowStyle, className, height }) {
  let tableRows
  const rowIndex = -1

  if (!keys) {
    console.warn('no `keys` supplied for `ScrollableTable`')
    keys = rows.map(row => btoa(JSON.stringify(row)))
  }

  tableRows = (
    <TransitionGroup component={null}>
      {rows.map((row, i) => {
        return (
          <CSSTransition key={`css-${keys[i]}`} timeout={420} classNames="scrollable-table__row">
            <tr
              key={`tr${keys[i]}`}
              className={classNames(
                'w-100 border-box ph3 flex ba b--gray-6 bn overflow-hidden hardware-accelerated',
                {
                  'bg-white z-2 relative': rowIndex === 0,
                },
              )}
            >
              {row?.map((cell, cellIndex) => {
                const columnWidth = columns[cellIndex].width
                return (
                  <td
                    key={`row-${rowIndex}-cell-${cellIndex}`}
                    className={classNames('bb b--gray-9 pv2', columns[cellIndex].className)}
                    style={{ width: columnWidth, wordWrap: 'break-word' }}
                  >
                    {cell}
                  </td>
                )
              })}
            </tr>
          </CSSTransition>
        )
      })}
    </TransitionGroup>
  )

  return (
    <table
      className={classNames(className, 'h-100 tl overflow-hidden bl b--gray-9')}
      cellPadding="0"
      cellSpacing="0"
      style={style}
    >
      <thead
        className="w-100 relative z-1 dt dt--fixed bb b--gray-9 border-box ph3 h6"
        style={{ ...rowStyle, flex: '0 0 auto', boxShadow: '0 3px 13px -8px' }}
      >
        <tr>
          {columns.map((column, index) => {
            const columnWidth = column.width
            return (
              <th
                key={column.heading + index}
                className={classNames('fw5 f4 lh-title', column.classname)}
                style={columnWidth ? { width: columnWidth } : {}}
              >
                {column.heading}
              </th>
            )
          })}
        </tr>
      </thead>
      <Scrollable Component="tbody" className="flex-auto db" height={height}>
        {tableRows}
      </Scrollable>
    </table>
  )
}

ScrollableTable.propTypes = {
  columns: array.isRequired,
  rows: array.isRequired,
}

export default ScrollableTable

import { Cloudinary } from '@cloudinary/url-gen'
import { fill } from '@cloudinary/url-gen/actions/resize'
import { Delivery } from '@cloudinary/url-gen/actions/delivery'

const cl = new Cloudinary({
  cloud: { cloudName: 'everlane' },
  url: { secure: true },
})

export default function cloudinaryUrl(urlOrPath = '', options = {}) {
  let filename

  if (urlOrPath.startsWith('http')) {
    const parts = urlOrPath.split(/(\/i\/|\/static\/.*)/)
    filename = parts[1] + parts[2]
  } else {
    filename = urlOrPath
  }

  const width = Math.round(options.width || options.size || 500)
  const height = Math.round(options.height || options.size || 500)

  const image = cl
    .image(filename)
    .resize(fill().width(width).height(height))
    .format('auto')
    .quality('auto')
    .delivery(Delivery.dpr('2'))

  return image.toURL()
}

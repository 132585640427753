import React from 'react'
import { withRouter } from 'react-router-dom'
import Button, { ButtonStyles } from 'components/shared/Button'
import { connect } from 'react-redux'
import { getStorefrontById } from 'reducers/storefronts'

function NotAllowed({ currentStorefront, primaryStorefront }) {
  return (
    <div className="w-50 center mt6">
      <p className="sans-serif f3 tc">
        You do not have permission to use this tool at {currentStorefront.name}.
      </p>
      <p className="sans-serif f3 tc">
        <Button
          buttonStyle={ButtonStyles.SECONDARY}
          to={`/storefronts/${primaryStorefront.id}/sales`}
        >
          Tap here to go to {primaryStorefront.name}
        </Button>
      </p>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentStorefront: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId),
    primaryStorefront: getStorefrontById(state.storefronts, state.associate.primary_storefront_id),
  }
}

export default withRouter(connect(mapStateToProps)(NotAllowed))

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Button, { ButtonStyles } from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'

export default function InventoryTransferDialog({ onRequestClose, onFormSubmit }) {
  const defaultEmail = useSelector(state => state.associate.everlane_email)
  const [email, setEmail] = useState(defaultEmail)
  const [isLoading, setLoading] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    setLoading(true)
    onFormSubmit(email)
  }

  const onInputChange = event => {
    setEmail(event.target.value)
  }

  return (
    <Dialog simple height="auto" onRequestClose={onRequestClose}>
      <form className="bg-white ph4" onSubmit={onSubmit}>
        <p className="tc f4 w-70 center mb4">
          Please enter an email where you want to send the inventory transfer CSV.
        </p>

        <label className="db mb2">
          <input
            autoCapitalize="off"
            autoCorrect="off"
            onChange={onInputChange}
            className="w-100 h6 pl1 ba b--gray-9 input-reset br0 sans-serif"
            placeholder="person@everlane.com"
            type="email"
            value={email}
            pattern=".+@everlane\.com"
            title={`Please provide an Everlane.com email address, like ${
              defaultEmail || 'person@everlane.com'
            }`}
            required
          />
        </label>
        <Button
          buttonStyle={ButtonStyles.SECONDARY}
          size="full-width"
          className="f4"
          disabled={isLoading}
        >
          {isLoading ? 'Sending…' : 'Complete and Send'}
        </Button>
      </form>
    </Dialog>
  )
}

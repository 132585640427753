import React from 'react'
import classNames from 'classnames'

export default function Address({
  fullName,
  streetAddress,
  extendedAddress,
  phoneNumber,
  city,
  region,
  country,
  postalCode,
  onClick,
  isActive,
  className,
  ignoreSpacing = false,
  showBorder = true,
}) {
  return (
    <address
      className={classNames(className, 'fs-normal col3 f5', {
        ba: showBorder,
        'b--gray-2 bg-gold-9 bw1': isActive && showBorder,
        'pa2 mb2 mr2 mt2': !ignoreSpacing,
      })}
      style={{ height: '10rem' }}
      onClick={onClick}
    >
      {fullName}
      <br />
      {streetAddress}
      <br />
      {extendedAddress && (
        <span>
          {extendedAddress}
          <br />
        </span>
      )}
      {city}, {region} {postalCode}
      <br />
      {country}
      <br />
      {phoneNumber && (
        <span>
          {phoneNumber}
          <br />
        </span>
      )}
    </address>
  )
}

import React from 'react'
import Button, { ButtonStyles } from 'components/shared/Button'
import { staticImageUrl } from 'lib/url'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import TinyPill from 'components/shared/TinyPill'

export default function ReturnUnit({ item, disabled, onAddToReturnReceipt }) {
  return (
    <div className="mt2 ml3 pb2 pr2 flex justify-between bb b--gray-9">
      <div className="flex" style={{ flex: '1' }}>
        <img
          src={staticImageUrl(item.image_url, { size: 168 })}
          className="mr2"
          style={{ height: '168px' }}
          alt={item.title}
        />
        <div className="pr2 flex flex-column justify-center">
          <span className="f5 fw5">{item.title}</span>
          <dl className="f5 mv0">
            <dt className="gray-6 di">Size:</dt>{' '}
            <dd className="di mh0">{item.size === 'ONE SIZE' ? 'OS' : item.size}</dd>
            <br />
            <dt className="gray-6 di">UPC:</dt> <dd className="di mh0">{item.variant_upc}</dd>
            <br />
            <dt className="gray-6 di">Source:</dt> <dd className="di mh0">{item.source}</dd>
            <br />
            <dt className="gray-6 di">Returnable Until:</dt>{' '}
            <dd className="di mh0">
              <FormattedDateTime format="medium" highlightIfPassed>
                {item.returnable_deadline}
              </FormattedDateTime>
            </dd>
            <br />
          </dl>
          {item.used_first_try && <TinyPill color="gray-3">First Try Redeemed</TinyPill>}
          {!item.used_first_try && !item.first_try_allowed && (
            <TinyPill color="red-4">First Try Used</TinyPill>
          )}
        </div>
      </div>
      <div className="flex flex-column justify-center">
        <Button
          disabled={disabled}
          buttonStyle={ButtonStyles.SECONDARY}
          size="medium"
          onClick={() => onAddToReturnReceipt(item)}
        >
          {disabled ? 'Returned' : 'Add To Return'}
        </Button>
        {item.final_sale && <div className="red tc pt1">FINAL SALE</div>}
      </div>
    </div>
  )
}

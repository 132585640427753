import React from 'react'
import { arrayOf, shape, string, bool, func } from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  tabs: arrayOf(
    shape({
      title: string,
      value: string,
      isActive: bool,
    }),
  ).isRequired,
  onClickTab: func.isRequired,
  isMobile: bool,
}

function Tab({ title, value, isActive, onClickTab }) {
  const className = classNames('f5 flex items-center tc h4 border-box nowrap mh4 bb', {
    'b--transparent gray-6': !isActive,
    'b--gray-2 gray-2 fw5': isActive,
  })

  return (
    <div className={className} onClick={() => onClickTab(value)} style={{ marginBottom: '-1px' }}>
      <div>{title}</div>
    </div>
  )
}

export default function Tabs({ tabs, onClickTab, isMobile }) {
  return (
    <nav
      className={classNames('flex items-center bb b--gray-9 pl1', {
        'overflow-x-scroll touch-momentum-scroll': isMobile,
      })}
    >
      {tabs.map((props, index) => (
        <Tab key={index} {...props} onClickTab={onClickTab} />
      ))}
      {isMobile && <div style={{ width: '10px' }}>&nbsp;</div>}
    </nav>
  )
}

Tabs.propTypes = propTypes

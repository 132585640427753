import React, { Component } from 'react'
import { func, string, object, bool } from 'prop-types'
import NameParser from 'name-parser'
import getConstants from 'lib/constants'
import Button, { ButtonStyles } from 'components/shared/Button'
import Select from 'components/shared/Select'
import Input from 'components/shared/Input'
import Checkbox from 'components/shared/Checkbox'
import ChineseProvinceSelector from 'lib/ChineseProvinceSelector.jsx'
import UniqueIdInput from 'lib/UniqueIdInput.jsx'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { connect } from 'react-redux'
import { isUniqueIdRequired } from 'lib/UniqueIdHelper.jsx'

class AddressForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    error: string,
    needsUniqueId: bool,
    existingAddress: object,
  }

  defaultAddress = {
    fullName: '',
    firstName: '',
    lastName: '',
    country: 'US',
    streetAddress: '',
    extendedAddress: '',
    city: '',
    region: '',
    zipCode: '',
    phoneNumber: '',
    saveForFutureUse: false,
    language: '',
    uniqueId: '',
  }

  existingAddress = this.props.existingAddress
    ? {
        fullName: this.props.existingAddress.full_name,
        country: this.props.existingAddress.country,
        streetAddress: this.props.existingAddress.street_address,
        extendedAddress: this.props.existingAddress.extended_address,
        city: this.props.existingAddress.city,
        region: this.props.existingAddress.region_code,
        zipCode: this.props.existingAddress.postal_code,
        phoneNumber: this.props.existingAddress.formatted_phone,
        saveForFutureUse: false,
        language: this.props.existingAddress.country === 'CN' ? 'zh-CN' : 'en-US',
        uniqueId: this.props.existingAddress.unique_id,
        ...this.parseName(this.props.existingAddress.full_name),
      }
    : {}

  state =
    this.existingAddress && this.props.needsUniqueId ? this.existingAddress : this.defaultAddress

  isReadyForSubmission() {
    return (
      (this.state.fullName || (this.state.firstName && this.state.lastName)) &&
      this.state.country &&
      this.state.streetAddress &&
      this.state.city &&
      this.state.region &&
      this.isPhoneNumberValid() &&
      this.isZipCodeValid() &&
      ((isUniqueIdRequired(this.state.country) && !!this.state.uniqueId) ||
        !isUniqueIdRequired(this.state.country))
    )
  }

  parseName(name) {
    const [firstName, lastName] = name.trim().split(/\s(.+)/)

    return { firstName, lastName }
  }

  isZipCodeValid() {
    const isZipCodeRequired = ['US', 'CA'].includes(this.state.country)

    return !!this.state.zipCode || !isZipCodeRequired
  }

  isPhoneNumberValid() {
    return !!this.state.phoneNumber
  }

  handleSubmit = event => {
    event.preventDefault()
    const { firstName, lastName } = NameParser.parse(this.state.fullName)

    this.props.onSubmit(
      {
        first_name: this.state.firstName || firstName,
        last_name: this.state.lastName || lastName,
        country: this.state.country,
        street_address: this.state.streetAddress,
        extended_address: this.state.extendedAddress,
        city: this.state.city,
        region: this.state.region,
        postal_code: this.state.zipCode,
        phone: this.state.phoneNumber.replace(/\D/g, ''),
        language: this.state.country === 'CN' ? 'zh-CN' : 'en-US',
        unique_id: this.state.uniqueId,
      },
      this.state.saveForFutureUse,
    )
  }

  renderFamilyAndGivenNameFields() {
    return (
      <div style={{ display: 'flex' }}>
        <Input
          className="w-100 mr2 pb2"
          type="text"
          label="Family Name"
          placeholder="李"
          value={this.state.lastName}
          onChange={event => this.setState({ lastName: event.target.value })}
          disabled={this.props.needsUniqueId}
        />

        <Input
          className="w-100 mr2 pb2"
          type="text"
          label="Given Name"
          placeholder="婷婷"
          value={this.state.firstName}
          onChange={event => this.setState({ firstName: event.target.value })}
          disabled={this.props.needsUniqueId}
        />
      </div>
    )
  }

  renderNameFields() {
    if (this.state.country === 'CN') {
      return this.renderFamilyAndGivenNameFields()
    }
    return this.renderFullNameInput()
  }

  renderFullNameInput() {
    return (
      <Input
        className="w-100 mr2 pb2"
        value={this.state.fullName}
        placeholder="Enter Full Name"
        onChange={event => this.setState({ fullName: event.target.value })}
        id="fullName"
        label="Full Name"
        disabled={this.props.needsUniqueId}
      />
    )
  }

  renderCountryInput() {
    const countriesToShipTo = {
      ...getConstants().international_shipping_countries,
    }

    const options = Object.entries(countriesToShipTo).map(([countryCode, countryName]) => ({
      title: countryName,
      value: countryCode,
    }))

    return (
      <div style={{ width: '70%' }}>
        <Select
          className="w-100 pb2"
          value={this.state.country}
          onChange={event => this.setState({ country: event.target.value })}
          id="country"
          label="Country"
          options={options}
          disabled={this.props.needsUniqueId}
        />
      </div>
    )
  }

  renderUniqueIdInput() {
    return (
      <UniqueIdInput
        className="w-100 mr2"
        onChange={id => this.setState({ uniqueId: id })}
        country={this.state.country}
        onError={error => this.setState({ error })}
      />
    )
  }

  renderChinaStreetAddressField() {
    return (
      <Input
        className="w-100 mr2 pb2"
        value={this.state.streetAddress}
        placeholder="朝阳区团结湖中路北一条三号楼三单元201"
        maxLength={50}
        onChange={event => this.setState({ streetAddress: event.target.value })}
        id="streetAddress"
        disabled={this.props.needsUniqueId}
        label="Address"
      />
    )
  }

  renderStreetAddressInput() {
    if (this.state.country === 'CN') {
      return this.renderChinaStreetAddressField()
    }

    return (
      <Input
        className="w-100 mr2 pb2"
        value={this.state.streetAddress}
        placeholder="123 Main St."
        onChange={event => this.setState({ streetAddress: event.target.value })}
        id="streetAddress"
        label="Address"
        maxLength={this.state.country === 'HK' ? '50' : 60}
        disabled={this.props.needsUniqueId}
      />
    )
  }

  renderExtendedAddressInput() {
    return (
      <Input
        required={false}
        className="w-100 pb2"
        value={this.state.extendedAddress}
        placeholder={this.state.country === 'CN' ? '' : 'Apt. 01'}
        maxLength={this.state.country === 'HK' ? 50 : 60}
        onChange={event => this.setState({ extendedAddress: event.target.value })}
        id="extendedAddress"
        disabled={this.props.needsUniqueId}
        label="Address continued (Optional)"
      />
    )
  }

  renderCityInput() {
    return (
      <Input
        className="w-100 mr2 pb2"
        value={this.state.city}
        placeholder="San Francisco"
        onChange={event => this.setState({ city: event.target.value })}
        id="city"
        label="City"
        disabled={this.props.needsUniqueId}
      />
    )
  }

  renderRegionField() {
    const label = {
      CA: 'Province*',
      US: 'State*',
    }[this.state.country]

    const states = this.getStates()

    if (states) {
      return (
        <div style={{ width: '70%' }}>
          <Select
            type="select"
            label={label || 'State/Province/Region*'}
            value={this.state.region}
            onChange={event => this.setState({ region: event.target.value })}
            options={Object.values(states)}
            showBlank={!this.props.needsUniqueId}
            disabled={this.props.needsUniqueId}
          />
        </div>
      )
    }
    return (
      <Input
        className="w-100 pb2"
        value={this.state.region}
        placeholder={label || 'State/Province/Region*'}
        onChange={event => this.setState({ region: event.target.value })}
        id="region"
        label="Region"
        disabled={this.props.needsUniqueId}
      />
    )
  }

  getStates() {
    const states = { ...getConstants().international_shipping_configuration }
    const usStates = { ...getConstants().us_states }

    if (this.state.country !== 'US') {
      return states[this.state.country].subdivisions
    }
    return usStates
  }

  renderZipCodeInput() {
    const placeholder = this.state.country === 'CA' ? 'A0A 0A0' : '00000'

    return (
      <Input
        className="w-100 pb2"
        value={this.state.zipCode}
        placeholder={placeholder}
        onChange={event => this.setState({ zipCode: event.target.value })}
        id="zipCode"
        label="Zip Code"
        disabled={this.props.needsUniqueId}
      />
    )
  }

  renderPhoneNumberInput() {
    return (
      <label className="db f5">
        Phone Number*
        <PhoneInput
          defaultCountry="US"
          className="w-100"
          style={{ padding: '5px' }}
          value={this.state.phoneNumber}
          placeholder="00 (504) 123 4567"
          onChange={value => this.setState({ phoneNumber: value })}
          id="phoneNumber"
          label="Phone Number"
          disabled={this.props.needsUniqueId}
        />
      </label>
    )
  }

  renderSaveAddressCheckbox() {
    return (
      <Checkbox
        checked={this.state.saveForFutureUse}
        onChange={event => this.setState({ saveForFutureUse: event.target.checked })}
        label="Save address for future online and in-store use"
      />
    )
  }

  renderProvinceSelector() {
    return (
      <ChineseProvinceSelector
        onChange={({ province, city, district }) => {
          this.setState({
            region: province,
            city: district && city ? `${district}, ${city}` : null,
          })
        }}
        needsUniqueId={this.props.needsUniqueId}
        existingProvince={this.state.region}
        existingCity={this.state.city && this.state.city.split(', ')[1]}
        existingDistrict={this.state.city && this.state.city.split(', ')[0]}
      />
    )
  }

  renderSubmitButton() {
    const isReady = this.isReadyForSubmission()

    return (
      <Button
        className="h6 mt1 w-100"
        buttonStyle={ButtonStyles.TERTIARY}
        disabled={!isReady}
        onClick={this.handleSubmit}
      >
        Save Shipping Address
      </Button>
    )
  }

  renderForUS() {
    return (
      <form className="w-100">
        {this.props.error && <p className="red">{this.props.error}</p>}
        {this.renderFullNameInput()}
        {this.renderCountryInput()}
        {this.renderStreetAddressInput()}
        {this.renderExtendedAddressInput()}
        <div className="flex justify-between mb1">
          {this.renderCityInput()}
          {this.renderRegionField()}
        </div>
        <div className="flex justify-between mb1">{this.renderZipCodeInput()}</div>
        <div>{this.renderPhoneNumberInput()}</div>
        {this.renderSaveAddressCheckbox()}
        {this.renderSubmitButton()}
      </form>
    )
  }

  renderForChina() {
    return (
      <form className="w-100">
        {this.props.error && <p className="red">{this.props.error}</p>}
        {this.state.error && <p className="red">{this.state.error}</p>}
        {this.renderNameFields()}
        {this.renderCountryInput()}
        {this.renderStreetAddressInput()}
        {this.renderExtendedAddressInput()}

        <div className="pb2">{this.renderProvinceSelector()}</div>
        {this.renderPhoneNumberInput()}

        <div className="pb2">
          {isUniqueIdRequired(this.state.country) && this.renderUniqueIdInput()}
        </div>
        {this.renderSaveAddressCheckbox()}
        {this.renderSubmitButton()}
      </form>
    )
  }

  renderForOtherCountries() {
    return (
      <form className="w-100">
        {this.props.error && <p className="red">{this.props.error}</p>}
        {this.state.error && <p className="red">{this.state.error}</p>}
        {this.renderNameFields()}
        {this.renderCountryInput()}
        {this.renderStreetAddressInput()}
        {this.renderExtendedAddressInput()}
        <div className="flex justify-between mb1">
          {this.renderCityInput()}
          {this.renderRegionField()}
        </div>
        {this.state.country === 'CA' && (
          <div className="flex justify-between mb1">{this.renderZipCodeInput()}</div>
        )}
        <div>
          {this.renderPhoneNumberInput()}
          {isUniqueIdRequired(this.state.country) && this.renderUniqueIdInput()}
        </div>
        {this.renderSaveAddressCheckbox()}
        {this.renderSubmitButton()}
      </form>
    )
  }

  render() {
    if (this.state.country === 'CN') {
      return this.renderForChina()
    }
    if (this.state.country === 'US') {
      return this.renderForUS()
    }
    return this.renderForOtherCountries()
  }
}

function mapStateToProps(state, ownProps) {
  return {
    existingAddress: ownProps.existingAddress,
  }
}

export default connect(mapStateToProps)(AddressForm)

import React from 'react'
import { post } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import Scrollable from 'components/shared/Scrollable'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

function InventoryTransfers({
  children,
  onCTAClick = () => {},
  onClick = () => {},
  onAuditClick = () => {},
  onDeleteClick,
  processedInventoryId,
  history,
  storefrontId,
}) {
  return (
    <Scrollable>
      <ul className="list pa3 mt0">
        {children.map(item => (
          <li
            className="bb b--gray-9 dib pb4 mb4 w-100"
            key={item.number}
            onClick={() => onClick(item.id)}
          >
            <div className="flex mt0 mb2 lh-solid">
              <h2 className="f3 fw5 ma0">{item.number}</h2>

              {item.is_cancelable && (
                <div className="fr w-20 tr ml-auto">
                  <Icon
                    glyph="trash-can"
                    color="gray-6"
                    className="f4"
                    onClick={event => {
                      event.stopPropagation()
                      if (
                        window.confirm('Are you sure you want to delete this Inventory Transfer?')
                      ) {
                        onDeleteClick(item.id)
                      }
                    }}
                  />
                </div>
              )}
            </div>

            <div className="flex items-end justify-between mb1">
              <div>
                {item.published_at ? (
                  <p className="f6 mt0 mb1 lh-solid">
                    <FormattedDateTime>{item.published_at}</FormattedDateTime>
                  </p>
                ) : (
                  <p className="f6 mt0 mb1 lh-solid">Not published</p>
                )}
                <p className="f6 mt0 mb1 lh-solid">
                  {item.units} Units, {item.skus} SKUs
                </p>
              </div>

              <ul className="list pa0">
                <li className="flex items-start justify-between items-center dib mb1 w-100">
                  <h4 className="f6 fw5 ma0 mr1 db lh-solid">Shipped:</h4>
                  <div>
                    {item.shipped_at ? (
                      <p className="f6 ma0 db lh-title">
                        <FormattedDateTime>{item.shipped_at}</FormattedDateTime>
                      </p>
                    ) : (
                      <p className="f6 ma0 db lh-title">Not yet shipped</p>
                    )}
                  </div>
                </li>
                <li className="flex items-start justify-between items-center dib mb1 w-100">
                  <h4 className="f6 fw5 ma0 mr1 db lh-solid">Delivered:</h4>
                  <div>
                    {item.delivers_by ? (
                      <p className="f6 ma0 db lh-title">
                        <FormattedDateTime>{item.delivers_by}</FormattedDateTime>
                      </p>
                    ) : (
                      <p className="f6 ma0 db lh-title">Unknown</p>
                    )}
                  </div>
                </li>
                {item.tracking_number && (
                  <li className="flex items-start justify-between dib mb1 w-100">
                    <h4 className="f6 fw5 ma0 mr1 db lh-solid">Tracking:</h4>
                    <p className="f6 ma0 db lh-title">{item.tracking_number}</p>
                  </li>
                )}
                {item.reason && (
                  <li className="flex items-start justify-between dib mb1 w-100">
                    <h4 className="f6 fw5 ma0 mr1 db lh-solid">Reason:</h4>
                    <p className="f6 ma0 db lh-title">{item.reason}</p>
                  </li>
                )}
                {item.state && (
                  <li className="flex items-start justify-between dib mb1 w-100">
                    <h4 className="f6 fw5 ma0 mr1 db lh-solid">State:</h4>
                    <p className="f6 ma0 db lh-title">{item.state}</p>
                  </li>
                )}
                {item.employee && (
                  <li className="flex items-start justify-between dib mb1 w-100">
                    <h4 className="f6 fw5 ma0 mr1 db lh-solid">Created by:</h4>
                    <p className="f6 ma0 db lh-title">
                      {item.employee.name} ({item.employee.email})
                    </p>
                  </li>
                )}
              </ul>
            </div>

            {processedInventoryId === item.id && item.processable && (
              <Button className="fr f5 ml1" size="full-width" color="green-5">
                Processed
              </Button>
            )}

            {processedInventoryId !== item.id && item.processable && (
              <>
                <Button
                  className="fr f5 ml1 mb1"
                  size="full-width"
                  outlined={!item.processable}
                  color="black"
                  onClick={() => onCTAClick(item)}
                >
                  Process
                </Button>

                {item.last_open_receipt_id ? (
                  <Button
                    outlined
                    className="fr f5 ml1 b--black"
                    size="full-width"
                    color="black"
                    onClick={() =>
                      history.push(
                        `/storefronts/${storefrontId}/inventory-transfers/transfer/${item.id}/audit/${item.last_open_receipt_id}`,
                      )
                    }
                  >
                    Continue Audit
                  </Button>
                ) : (
                  <Button
                    outlined
                    className="fr f5 ml1 b--black"
                    size="full-width"
                    color="black"
                    onClick={async () => {
                      const { response, responseJson } = await post(
                        retailApiUrl(`storefronts/${storefrontId}/inventory_transfer_receipts/`),
                        { inventory_transfer_id: item.id },
                      )

                      if (response.ok) {
                        const { id: newReceiptId } = responseJson

                        history.push(
                          `/storefronts/${storefrontId}/inventory-transfers/transfer/${item.id}/audit/${newReceiptId}`,
                        )
                      }
                    }}
                  >
                    Start New Audit
                  </Button>
                )}
              </>
            )}

            <div className="flex">
              <div className="w-100">
                {!item.processable && item.state !== 'in_progress' && (
                  <Button
                    className="fr f5 ml1"
                    color="black"
                    size="full-width"
                    outlined
                    onClick={() => onCTAClick(item)}
                  >
                    View Transfer
                  </Button>
                )}

                {item.state === 'in_progress' && (
                  <Button className="fr f5 ml1" color="green-5" size="full-width">
                    View In Progress Transfer
                  </Button>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Scrollable>
  )
}

export default InventoryTransfers

import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Icon from 'components/shared/Icon'

function MenuItem({ match, menuItem }) {
  return (
    <li>
      <NavLink
        to={`${match.url}/${menuItem.partialPath}`}
        className="db pl3 flex items-center h8 f4 pointer no-underline"
        activeClassName={classNames('sidebar-link--active bg-gray-9')}
      >
        <Icon glyph={menuItem.glyph} color="gray-2" className="mr2" />
        {menuItem.title}
      </NavLink>
    </li>
  )
}

export default withRouter(MenuItem)

import React from 'react'
import classNames from 'classnames'
import Icon from 'components/shared/Icon'

// Renders an icon side-by-side with a text label, with props for shimming either
// item vertically to get the positioning just right. Useful in cases like the
// card back arrow and the menu items in the sidebar, among many others.
export default function IconWithLabel({
  className,
  color,
  onClick,
  icon,
  iconClassName,
  iconTopOffset,
  label,
  labelClassName,
  labelTopOffset,
  iconPosition = 'left',
  disabled = false,
}) {
  const containerClassName = classNames(
    'flex items-center h-100 pointer',
    {
      'flex-row-reverse': iconPosition === 'right',
      'disabled-action': disabled,
    },
    className,
  )
  const fullIconClassName = classNames(iconClassName)
  const fullLabelClassName = classNames(labelClassName, color)

  function getStyle(top) {
    return top
      ? {
          top,
          position: 'relative',
        }
      : {}
  }

  return (
    <div className={containerClassName} onClick={onClick}>
      <Icon
        glyph={icon}
        color={color}
        className={fullIconClassName}
        style={getStyle(iconTopOffset)}
      />
      <span className={fullLabelClassName} style={getStyle(labelTopOffset)}>
        {label}
      </span>
    </div>
  )
}

import React from 'react'

const getColoredSvg = color => {
  return (
    <svg viewBox="0 0 189 149" version="1.1">
      <g stroke={color} strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-418.000000, -248.000000)">
          <g transform="translate(418.000000, 249.000000)">
            <path d="M176,115 L23,115 C16.4,115 11,109.6 11,103 L11,12 C11,5.4 16.4,0 23,0 L176,0 C182.6,0 188,5.4 188,12 L188,103 C188,109.6 182.6,115 176,115 Z" />
            <path d="M160.6945,109.5 L33.6945,109.5 C30.3945,109.5 27.6945,106.8 27.6945,103.5 L27.6945,10.5 C27.6945,7.2 30.3945,4.5 33.6945,4.5 L160.6945,4.5 C163.9945,4.5 166.6945,7.2 166.6945,10.5 L166.6945,103.5 C166.6945,106.8 163.9945,109.5 160.6945,109.5 Z" />
            <path d="M183.6945,57 C183.6945,60.037 181.2325,62.5 178.1945,62.5 C175.1565,62.5 172.6945,60.037 172.6945,57 C172.6945,53.963 175.1565,51.5 178.1945,51.5 C181.2325,51.5 183.6945,53.963 183.6945,57 Z" />
            <path d="M20.4445,57 C20.4445,57.828 19.7735,58.5 18.9445,58.5 C18.1155,58.5 17.4445,57.828 17.4445,57 C17.4445,56.172 18.1155,55.5 18.9445,55.5 C19.7735,55.5 20.4445,56.172 20.4445,57" />
            <polygon points="81.789 115 71 147.5 128 147.5 117 115" />
            <path d="M101.1945,69.5 C-44.3055,69.5 10.6945,45.5 10.6945,45.5" />
            <polyline strokeLinecap="round" points="85.6945 58 101.1945 69.434 85.6945 82.5" />
          </g>
        </g>
      </g>
    </svg>
  )
}

function RotateTablet({ color = '#000000', size = '100px', className = '' }) {
  const svg = getColoredSvg(color)
  return (
    <div className={className} style={{ width: size }}>
      {svg}
    </div>
  )
}

export default RotateTablet

import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import Button from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'

export default class UpdateUpcDialog extends Component {
  state = {
    newUpc: '',
  }

  render() {
    return (
      <Dialog
        color="green-2"
        textColor="white"
        height="auto"
        title="Update Item UPC"
        onRequestClose={this.props.onRequestClose}
      >
        <div className="bg-white ph4">
          <p className="tc f4 gray-6 w-70 center mb4">
            This item was returned because the
            <br />
            customer received the incorrect product.
            <br />
            Enter the correct UPC below.
          </p>

          <span className="db fw5 mb1">Current UPC:</span>
          <span className="db mb3">{this.props.currentUpc}</span>

          <label className="db mb2">
            <span className="db mb1 fw5">Correct UPC:</span>
            <NumberFormat
              prefix="0-0000"
              mask="_"
              value={this.state.newUpc}
              format="0-0000#-#####-#"
              className="w-100 h6 ph2 ba b--gray-9 input-reset br0"
              onValueChange={values =>
                this.setState({
                  newUpc: values.formattedValue,
                })
              }
            />
          </label>
          <Button
            color="green-2"
            size="full-width"
            className="f4"
            onClick={() => {
              this.props.onFormSubmit(this.state.newUpc)
            }}
          >
            Update
          </Button>
        </div>
      </Dialog>
    )
  }
}

import React, { Component } from 'react'
import { func, string } from 'prop-types'
import { isEmail } from 'lib/validation'
import Dialog from 'components/shared/Dialog'
import Button, { ButtonStyles } from 'components/shared/Button'

export default class SendReceiptDialog extends Component {
  static propTypes = {
    onRequestClose: func.isRequired,
    onSendReceipt: func.isRequired,
    type: string,
    initialEmail: string,
  }

  state = {
    emailToSendReceiptTo: this.props.initialEmail || '',
    showInvalidEmailMessage: false,
  }

  handleSubmit = () => {
    if (isEmail(this.state.emailToSendReceiptTo)) {
      this.props.onSendReceipt(this.state.emailToSendReceiptTo, this.props.type)
    } else {
      this.setState({ showInvalidEmailMessage: true })
    }
  }

  render() {
    const header = this.props.type === 'gift' ? 'Send Email Gift Receipt' : 'Send Email Receipt'

    const copy =
      this.props.type === 'gift'
        ? `Please enter an email address to send the gift receipt to.`
        : 'Please enter an email address to send a digital receipt to.'

    return (
      <Dialog
        height="auto"
        title={<span className="f4">{header}</span>}
        onRequestClose={this.props.onRequestClose}
      >
        <div className="w-60 center">
          <p className="ma0 mb3">{copy}</p>
          {this.state.showInvalidEmailMessage && (
            <p className="mv3 red">Please enter a valid email address.</p>
          )}
          <label className="db mb2">
            <span className="db mb1 fw5">Email Address</span>
            <input
              autoCapitalize="off"
              autoCorrect="off"
              onChange={event =>
                this.setState({
                  emailToSendReceiptTo: event.target.value,
                })
              }
              className="w-100 h6 ph2 ba b--gray-9 input-reset br0 sans-serif"
              placeholder="taylor@everlane.com"
              type="email"
              value={this.state.emailToSendReceiptTo}
            />
          </label>
          <Button
            buttonStyle={ButtonStyles.TERTIARY}
            size="full-width"
            className="f4"
            onClick={this.handleSubmit}
          >
            Send
          </Button>
        </div>
      </Dialog>
    )
  }
}

import React from 'react'
import Dialog from 'components/shared/Dialog'
import Button from 'components/shared/Button'

export default function ConfirmationDialog({
  onYes,
  onNo,
  title,
  question,
  color,
  onRequestClose = onNo,
  simple = false,
  width = '35rem',
  height = 'auto',
  buttonStyle,
}) {
  return (
    <Dialog
      simple={simple}
      onRequestClose={onRequestClose}
      color={color}
      title={title}
      height={height}
      width={width}
    >
      <div className="ph4 bg-white">
        <div>
          <p className="tc mt0 mb3 pv4">{question}</p>
          <div className="flex items-between">
            <Button className="mr2 flex-auto" onClick={onNo} buttonStyle={buttonStyle}>
              No
            </Button>
            <Button className="flex-auto" onClick={onYes} buttonStyle={buttonStyle}>
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { get, patch } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import { getAmountOfHoursStoreHasBeenOpen } from 'lib/time'
import { getStorefrontById } from 'reducers/storefronts'
import { showNotification, startRequest, finishRequest } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import Header from 'containers/Header'
import TabBar from 'components/shared/TabBar'
import PendingRestocksQueue from 'containers/restock-queue/PendingRestocksQueue'
import IncompleteRestocksQueue from 'containers/restock-queue/IncompleteRestocksQueue'

export class RestockQueueInterface extends Component {
  state = {
    numberOfPendingRestocks: '…',
    numberOfIncompleteRestocks: '…',
  }

  componentDidMount() {
    this.fetchRestockAmounts()
  }

  fetchRestockAmounts = async () => {
    const [{ responseJson: pending = {} }, { responseJson: incomplete = {} }] = await Promise.all([
      get(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/storefront_restock_requests`,
        ),
        {
          restocked: false,
          cutoff_period: getAmountOfHoursStoreHasBeenOpen(this.props.storefront),
        },
      ),
      get(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/storefront_restock_requests`,
        ),
        {
          incomplete: true,
          cutoff_period: getAmountOfHoursStoreHasBeenOpen(this.props.storefront),
        },
      ),
    ])

    this.setState({
      numberOfIncompleteRestocks: incomplete.metadata?.count ?? 0,
      numberOfPendingRestocks: pending.metadata?.count ?? 0,
    })
  }

  async clearQueue() {
    try {
      await patch(
        retailApiUrl(`storefronts/${this.props.storefront.id}/storefront_restock_requests/clear`),
      )
    } catch (err) {
      this.props.showError(err.message)
    }
  }

  render() {
    return (
      <div>
        <Header
          textColor="white"
          ctaText={this.state.numberOfPendingRestocks > 0 ? 'Mark All Pending As Complete' : null}
          onCtaClick={async () => {
            if (window.confirm('Are you sure you want to mark all restock requests as complete?')) {
              this.props.startRequest()
              await this.clearQueue()
              window.location.reload()
              this.props.finishRequest()
            }
          }}
        />
        <TabBar
          borderColor="gold-6"
          tabs={[
            {
              title: 'Pending Restocks',
              to: `${this.props.match.url}/pending`,
              notificationCount: this.state.numberOfPendingRestocks,
              showNotificationCount: true,
            },
            {
              title: 'Incomplete Restocks',
              to: `${this.props.match.url}/incomplete`,
              notificationCount: this.state.numberOfIncompleteRestocks,
              showNotificationCount: true,
            },
          ]}
        />
        <Switch>
          <Route
            path={`${this.props.match.path}/incomplete`}
            render={routeProps => (
              <IncompleteRestocksQueue
                onMoveToPending={this.fetchRestockAmounts}
                onCountChange={count => this.setState({ numberOfIncompleteRestocks: count })}
                {...routeProps}
              />
            )}
          />
          <Route
            path={`${this.props.match.path}/pending`}
            render={routeProps => (
              <PendingRestocksQueue
                onRestock={this.fetchRestockAmounts}
                onCountChange={count => this.setState({ numberOfPendingRestocks: count })}
                {...routeProps}
              />
            )}
          />
          <Redirect to={`${this.props.match.url}/pending`} />
        </Switch>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showError: errorMessage => dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestockQueueInterface)

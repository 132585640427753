import React, { Component, Fragment } from 'react'
import { shape, string } from 'prop-types'
import { connect } from 'react-redux'
import {
  clearOrderSearchResults,
  showDialog,
  hideDialog,
  showNotification,
} from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import {
  createReturnReceipt,
  createNewStorefrontTicket,
  fetchOrderSearchResults,
  addItemToReturn,
  createReturnReceiptFromOrder,
} from 'actions/asynchronous'
import { pub, pubWithReturn, Event } from 'lib/events'
import { getStorefrontById } from 'reducers/storefronts'
import BigSearchField from 'components/shared/BigSearchField'
import Header from 'containers/Header'
import ScrollableTable from 'components/shared/ScrollableTable'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import Button, { ButtonStyles } from 'components/shared/Button'
import LineItem from 'components/shared/LineItem'
import NoItemsMessage from 'components/shared/NoItemsMessage'
import TinyPill from 'components/shared/TinyPill'
import StorefrontTicketCTA from 'components/shared/StorefrontTicketCTA'
import StorefrontTicketDialog from 'components/shared/StorefrontTicketDialog'
import { UPCScanner } from 'lib/keyboard'
import { handleSearchOrders } from 'lib/searchOrders'
import CustomerEmailScreen from 'components/shared/CustomerEmailScreen'

class SearchForOrders extends Component {
  constructor(props) {
    super(props)

    this.state = { isAddingCustomer: false }

    this.scanner = new UPCScanner({
      callback: scanResult => this.handleScan(scanResult),
    })
  }

  static propTypes = {
    match: shape({ params: shape({ storefrontId: string.isRequired }) }),
    storefrontName: string.isRequired,
  }

  componentDidMount() {
    this.scanner.listen()
    pub(Event.ReturnsHome.PAGE_VIEW)
  }

  componentWillUnmount() {
    this.scanner.stopListening()
  }

  async componentDidUpdate(prevProps, prevState) {
    const isTypeRma = this.state.queryType === 'rma'
    const rmaMatch = this.props.orders[0]?.return_authorizations.find(
      rma => rma.number === this.state.query,
    )
    const queryChanged = this.state.query !== prevState.query

    if (isTypeRma && rmaMatch && queryChanged) {
      const { return_receipt } = await this.props.createReturnReceiptFromOrder(
        this.props.orders[0],
        this.state.query,
      )

      if (return_receipt) {
        this.props.history.push(`${this.props.match.url}/${return_receipt.id}`)
      }
    }
  }

  handleToggleAddCustomer = () => {
    this.setState(prevState => {
      return { isAddingCustomer: !prevState.isAddingCustomer }
    })
  }

  handleScan = async upc => {
    if (!upc || this.props.orders.length === 0) {
      return
    }

    let orderNumber
    let matchingItem
    this.props.orders.forEach(order => {
      order.items.forEach(item => {
        if (item.upc === upc) {
          matchingItem = item
          orderNumber = order.order_number
        }
      })
    })

    if (!matchingItem) {
      this.props.showError('The scanned item was not found in this list of orders.')
      return
    }

    const { return_receipt } = await this.props.createReturnReceipt(orderNumber, {
      isGiftReturn: false,
    })

    if (!return_receipt) {
      return
    }

    pub(Event.ReturnsHome.START_STANDARD_RETURN)
    this.props.history.push(`${this.props.match.url}/${return_receipt.id}`)

    const returnItem = return_receipt.items.find(item => item.variant_upc === upc)

    if (!returnItem) {
      // item is in the returned items array
      this.props.showError('This item is not returnable.')
      return
    }

    if (returnItem.single_return_variant && !returnItem.first_try_allowed) {
      this.props.showError(
        'This customer has already used their First Try. Please add the item manually.',
      )
      return
    }

    this.props.addItemToReturn(returnItem, return_receipt.id).then(() =>
      pubWithReturn(Event.Return.ADD_ITEM_TO_RETURN, {
        product_id: returnItem.product_id,
      }),
    )
  }

  handleSearchForOrders = async (label, query, queryType) => {
    pub(Event.ReturnsHome.ENTER_SEARCH, { text: query })
    await this.props.fetchOrderSearchResults(query, queryType)
    await this.setState({ query, queryType, isAddingCustomer: false })
  }

  handleCreateReturnReceipt = async (orderNumber, isGiftReturn) => {
    if (isGiftReturn) {
      pub(Event.ReturnsHome.START_GIFT_RETURN)
    } else {
      pub(Event.ReturnsHome.START_STANDARD_RETURN)
    }

    const { return_receipt } = await this.props.createReturnReceipt(orderNumber, { isGiftReturn })

    if (return_receipt) {
      this.props.history.push(`${this.props.match.url}/${return_receipt.id}`)
    }
  }

  handleClearResults = () => {
    pub(Event.ReturnsHome.CLEAR_SEARCH)
    this.props.clearOrderSearchResults()
  }

  renderOrdersTable() {
    const rows = this.props.orders.map(order => {
      return [
        <div>
          {order.associate_full_name}
          <br />
          <FormattedDateTime>{order.completed_at}</FormattedDateTime>
          <br />
          {/* Valencia Street, San Francisco --> Valencia Street */}
          <div>{order.location_name?.split(',')[0]}</div>
        </div>,
        <>
          {order.order_number}
          {order.used_first_try && (
            <>
              <br />
              <TinyPill color="gray-3">First Try Redeemed</TinyPill>
            </>
          )}
        </>,
        <span>
          {order.customer_full_name || 'N/A'}
          <br />
          {order.customer_email}
        </span>,
        <div className="flex flex-column">
          {order.items.map((item, index) => (
            <LineItem
              key={item.id}
              imageUrl={item.image_url}
              title={item.title}
              size={item.size}
              upc={item.upc}
              color={item.color}
              quantity={item.quantity}
              isFirstItem={index === 0}
              source={item.source}
              isLastItem={index === order.items.length - 1}
              usedFirstTry={item.used_first_try}
              firstTryAllowed={item.first_try_allowed}
              isReturn
            />
          ))}
        </div>,
        <div className="flex flex-column justify-end items-center">
          <Button
            onClick={() => this.handleCreateReturnReceipt(order.order_number, false)}
            size="full-width"
            buttonStyle={ButtonStyles.SECONDARY}
            disabled={this.props.isLoading}
            style={{ fontSize: '1rem' }}
          >
            Standard Return
          </Button>

          {!order.customer_banned && (
            <Button
              outlined
              onClick={() => this.handleCreateReturnReceipt(order.order_number, true)}
              className="mt2"
              size="full-width"
              buttonStyle={ButtonStyles.QUATERNARY}
              disabled={this.props.isLoading}
              style={{ fontSize: '1rem' }}
            >
              Gift Return
            </Button>
          )}
        </div>,
      ]
    })

    const columns = [
      { heading: 'Order Info', width: '18%' },
      { heading: 'Order No.', width: '12%' },
      { heading: 'Customer', width: '20%' },
      { heading: 'Items Purchased', width: '33%' },
      { heading: '', width: '17%' },
    ]

    return (
      <ScrollableTable
        columns={columns}
        rows={rows}
        keys={this.props.orders.map(o => o.order_number)}
      />
    )
  }

  renderStorefrontTicketCTA() {
    return (
      <StorefrontTicketCTA
        onClick={() => {
          this.props.showDialog(
            <StorefrontTicketDialog
              storefrontName={this.props.storefrontName}
              onRequestClose={this.props.hideDialog}
              onFormSubmit={formData => this.props.createNewStorefrontTicket(formData)}
            />,
          )
        }}
      />
    )
  }

  render() {
    if (this.state.isAddingCustomer) {
      return (
        <CustomerEmailScreen
          handleBackClick={this.handleToggleAddCustomer}
          headerText="Let's get your return started."
          subheaderText="Please enter your email."
          backgroundColor="red-5"
          handleSubmit={query =>
            handleSearchOrders(query, this.props.showError, this.handleSearchForOrders)
          }
          handleClear={this.handleClearResults}
        />
      )
    }

    return (
      <div>
        <Header
          textColor="white"
          ctaText="Search By Email"
          onCtaClick={this.handleToggleAddCustomer}
        />
        <div className="flex-date-picker w-100 h-100">
          <BigSearchField
            className="b--none mr3"
            buttonColor="red-5"
            initialInput={this.props.query}
            placeholderText="Search for a sale or order by order number or full name"
            onSubmit={query =>
              handleSearchOrders(
                query,

                this.props.showError,
                this.handleSearchForOrders,
              )
            }
            onClearClick={this.handleClearResults}
          />
        </div>

        {this.renderStorefrontTicketCTA()}

        {this.props.orders.length > 0
          ? this.renderOrdersTable()
          : this.props.query && (
              <NoItemsMessage>
                No orders were found. Please enter a customer's name, email, or order number (e.g.
                r12312312)
              </NoItemsMessage>
            )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    orders: state.orderSearch.results,
    query: state.orderSearch.query,
    storefrontName: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId).name,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createReturnReceipt: (orderNumber, { isGiftReturn }) =>
      dispatch(
        createReturnReceipt(orderNumber, { isGiftReturn }, ownProps.match.params.storefrontId),
      ),
    createNewStorefrontTicket: data =>
      dispatch(createNewStorefrontTicket(ownProps.match.params.storefrontId, data)),
    fetchOrderSearchResults: (query, queryType) =>
      dispatch(fetchOrderSearchResults(query, queryType)),
    clearOrderSearchResults: () => dispatch(clearOrderSearchResults()),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    showError: errorMessage => dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
    addItemToReturn: (item, returnReceiptId) =>
      dispatch(addItemToReturn(ownProps.match.params.storefrontId, returnReceiptId, item)),
    createReturnReceiptFromOrder: (order, rmaNumber) =>
      dispatch(createReturnReceiptFromOrder(order, rmaNumber, ownProps.match.params.storefrontId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForOrders)

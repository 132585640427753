import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Breakpoint } from 'react-socks'
import {
  markTryonRequestAsDelivered,
  markTryonRequestAsSoldOut,
  markTryonRequestAsReady,
  markTryonRequestAsActive,
  markAllTryonRequestsAsDelivered,
} from 'actions/asynchronous'
import { showNotification, startRequest, finishRequest } from 'actions/synchronous'
import { areWebSocketsEnabled } from 'lib/env'
import { get } from 'lib/network'
import { retailApiUrl, staticImageUrl } from 'lib/url'
import { getIsLoading } from 'reducers/activeRequests'
import { NotificationTypes } from 'reducers/notifications'
import { getStorefrontById } from 'reducers/storefronts'
import Header from 'containers/Header'
import NoItemsMessage from 'components/shared/NoItemsMessage'
import TransferQueueButton from 'containers/returns-queue/TransferQueueButton'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import Button, { ButtonStyles } from 'components/shared/Button'
import CategoryNav from 'components/request-to-try/CategoryNav'
import TinyPill from 'components/shared/TinyPill'
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider'
import Scrollable from 'components/shared/Scrollable'

class RequestToTry extends Component {
  state = {
    tryonRequests: [],
    currentSubcategory: null,
    currentCategory: null,
  }

  notificationSound = new Audio(
    'http://res.cloudinary.com/everlane/video/upload/v1521762445/Notification_2_qzpmhj.m4a',
  )

  componentDidMount() {
    if (!this.props.requestToTryEnabled) {
      return this.showDisabledMessage()
    }

    if (!areWebSocketsEnabled()) {
      this.pollForUpdates()
    }

    this.fetchTryonRequests()
  }

  pollForUpdates() {
    this.pollingInterval = setInterval(() => {
      if (this.props.asyncLoading) {
        return
      }

      this.fetchTryonRequests()
    }, 10000)
  }

  componentWillUnmount() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tryonRequests.length < this.state.tryonRequests.length &&
      this.notificationSound
    ) {
      try {
        const playPromise = this.notificationSound.play()
        if (typeof playPromise !== 'undefined') {
          playPromise.catch(() => {})
        }
      } catch (err) {
        return null
      }
    }
  }

  fetchTryonRequests = async () => {
    this.props.startRequest()

    try {
      const { response, responseJson } = await get(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/storefront_tryon_requests`,
        ),
        {},
      )

      if (response.ok) {
        this.setState({
          tryonRequests: responseJson.storefront_tryon_requests,
        })
      } else {
        this.props.showError(responseJson.message)
      }
    } catch (e) {
      this.props.showError(
        <span>
          An error occured while fetching try on requests.
          <br />
          Share this message with an engineer:
          <br />
          <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
        </span>,
      )
    } finally {
      this.props.finishRequest()
    }
  }

  showDisabledMessage() {
    this.props.showError(
      <>
        Request to Try is currently disabled for this Storefront.
        <br />
        Go to{' '}
        <a href={`/storefronts/${this.props.match.params.storefrontId}/feature-control`}>
          Feature Control
        </a>{' '}
        to re-enable it.
      </>,
    )
  }

  toggleReady = async (id, state) => {
    if (state === 'active') {
      await this.props.markTryonRequestAsReady(id)
      this.props.showMessage('Marked as incoming')
    } else if (state === 'ready') {
      await this.props.markTryonRequestAsActive(id)
      this.props.showMessage('Not coming')
    }
    this.fetchTryonRequests()
  }

  handleSubcategoryChange = (category, subcategory) => {
    this.setState({
      currentSubcategory: subcategory,
      currentCategory: category,
    })
  }

  getCategoryCounts = () => {
    const counts = {
      total: this.state.tryonRequests.length,
      female: { total: 0 },
      male: { total: 0 },
      unisex: { total: 0 },
    }

    this.state.tryonRequests.forEach(item => {
      counts[item.gender].total += 1
      const subCat = counts[item.gender][item.subcategory]
      counts[item.gender][item.subcategory] = subCat ? [item, ...subCat] : [item]
    })

    return counts
  }

  getFilteredRequests = categoryCounts => {
    if (!this.state.currentSubcategory || !this.state.currentCategory) {
      return this.state.tryonRequests
    }

    if (this.state.currentSubcategory === 'All') {
      return Object.values(categoryCounts[this.state.currentCategory])
        .filter(cat => isNaN(cat))
        .flat()
    }

    return categoryCounts[this.state.currentCategory][this.state.currentSubcategory]
  }

  renderItemDetails(tryonRequest) {
    const { item } = tryonRequest

    return (
      <div key={item.id}>
        <div className="flex mb2">
          <img
            alt={item.title}
            src={staticImageUrl(item.image_url, { size: 200 })}
            className="w-50 w-30-ns h-100"
          />
          <div className="ml2 lh-title">
            <div className="pb1">{item.title}</div>
            <div className="f5 gray-6 lh-copy">
              {item.size}
              <br />
              UPC: {item.upc}
              <br />
              Requested By: {tryonRequest.associate_full_name}
              <br />
              <FormattedDateTime>{tryonRequest.created_at}</FormattedDateTime>
              <div className="mt1">
                <TinyPill textColor="white" color="gray-2" className="h3">
                  Quantity: {tryonRequest.sku_qoh}
                </TinyPill>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTable(items) {
    if (!items.length) {
      return (
        <div className="fl">
          <NoItemsMessage>No items requested to try.</NoItemsMessage>
        </div>
      )
    }

    const tryonRequests = items.map(tryonRequest => {
      const readyLabel = tryonRequest.state === 'ready' ? 'On its Way' : 'Ready'

      return (
        <CSSTransition
          key={`tryon-${tryonRequest.id}`}
          timeout={420}
          classNames="scrollable-table__row"
        >
          <div className="flex-ns pa3">
            {this.renderItemDetails(tryonRequest)}
            <div className="flex flex-wrap db-ns">
              <Button
                className="fr mb2 w-100 ph2 mr1 w-100-ns mr0-ns"
                size="medium"
                buttonStyle={ButtonStyles.SECONDARY}
                disabled={this.props.asyncLoading}
                onClick={() => this.toggleReady(tryonRequest.id, tryonRequest.state)}
                style={{ flex: 1 }}
              >
                {readyLabel}
              </Button>

              <TransferQueueButton
                color="blue-3"
                className="ph2 f3 ml1 w-100-ns ml0-ns mb2-ns"
                size="medium"
                onComplete={this.fetchTryonRequests}
                onClick={() => this.props.markTryonRequestAsDelivered(tryonRequest.id)}
                style={{ flex: 1 }}
              >
                Delivered
              </TransferQueueButton>
              <TransferQueueButton
                color="blue-3"
                outlined
                onComplete={this.fetchTryonRequests}
                onClick={() => this.props.markTryonRequestAsSoldOut(tryonRequest.id)}
              >
                Sold Out
              </TransferQueueButton>
            </div>
          </div>
        </CSSTransition>
      )
    })

    return (
      <Scrollable>
        <TransitionGroup component={null}>{tryonRequests}</TransitionGroup>
      </Scrollable>
    )
  }

  renderContent() {
    const categoryCounts = this.getCategoryCounts()
    const items = this.getFilteredRequests(categoryCounts) || []

    return (
      <>
        <Header
          textColor="white"
          ctaText={categoryCounts.total > 0 ? 'Delete All' : null}
          onCtaClick={async () => {
            if (window.confirm('Are you sure you want to delete all items in this queue?')) {
              this.props.startRequest()
              await this.props.markAllTryonRequestsAsDelivered()
              this.props.finishRequest()
              this.fetchTryonRequests()
            }
          }}
        />
        <div className="fl w-100 h-100">
          <Breakpoint tablet up>
            <CategoryNav
              categoryCounts={categoryCounts}
              onSubcategoryClick={this.handleSubcategoryChange}
            />
          </Breakpoint>

          {this.renderTable(items)}
        </div>
      </>
    )
  }

  render() {
    if (areWebSocketsEnabled()) {
      return (
        <ActionCableConsumer
          channel={{
            channel: 'StorefrontTryonRequestChannel',
            storefront_id: this.props.match.params.storefrontId,
          }}
          onReceived={res =>
            this.setState({
              tryonRequests: JSON.parse(res).storefront_tryon_requests,
            })
          }
        >
          {this.renderContent()}
        </ActionCableConsumer>
      )
    }

    return this.renderContent()
  }
}

function mapStateToProps(state, ownProps) {
  return {
    requestToTryEnabled: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId)
      .request_to_try_enabled,
    asyncLoading: getIsLoading(state.activeRequests),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    markTryonRequestAsDelivered: id =>
      dispatch(markTryonRequestAsDelivered(ownProps.match.params.storefrontId, id)),
    markTryonRequestAsSoldOut: id =>
      dispatch(markTryonRequestAsSoldOut(ownProps.match.params.storefrontId, id)),
    markTryonRequestAsReady: id =>
      dispatch(markTryonRequestAsReady(ownProps.match.params.storefrontId, id)),
    markTryonRequestAsActive: id =>
      dispatch(markTryonRequestAsActive(ownProps.match.params.storefrontId, id)),
    markAllTryonRequestsAsDelivered: () =>
      dispatch(markAllTryonRequestsAsDelivered(ownProps.match.params.storefrontId)),
    showError: errorMessage => dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
    showMessage: message => dispatch(showNotification(NotificationTypes.INFO, message)),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestToTry))

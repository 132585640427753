import React, { Component } from 'react'
import { func, arrayOf, string, obj } from 'prop-types'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'
import Button, { ButtonStyles } from 'components/shared/Button'
import Select from 'components/shared/Select'
import Input from 'components/shared/Input'

export default class CreditForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    possibleReasons: arrayOf(string).isRequired,
    style: obj,
  }

  state = {
    amountOfCredit: '',
    reason: '',
  }

  isReadyForSubmission() {
    return !!this.state.amountOfCredit && !!this.state.reason
  }

  handleApply = event => {
    event.preventDefault()

    const numberValue = parseFloat(
      // take out any commas and dollar signs
      this.state.amountOfCredit.replace(/,/g, '').replace(/\$/g, ''),
      10,
    )

    this.props
      .onSubmit({
        amount: numberValue,
        reason: this.state.reason,
      })
      .then(() => {
        this.setState({ amountOfCredit: '', reason: '' })
      })
  }

  renderAmountInput() {
    return (
      <Input
        className="w-100 mr2"
        TextField={
          <NumberFormat
            value={this.state.amountOfCredit}
            thousandSeparator
            prefix="$"
            placeholder="$50.00"
            className="ba b--gray-6 f4 h6 ph1 w-100 sans-serif"
            onChange={event => this.setState({ amountOfCredit: event.target.value })}
          />
        }
      />
    )
  }

  renderReasonInput() {
    return (
      <Select
        className="w-100"
        arrowStyle={{ top: '0.55rem', fontSize: '1.5rem', right: '0.5rem' }}
        value={this.state.reason}
        onChange={event => this.setState({ reason: event.target.value })}
        options={[
          { title: 'Select a reason…', value: '' },
          ...this.props.possibleReasons.map(reason => ({
            title: reason === 'CWYP' ? 'Promo' : reason,
            value: reason,
          })),
        ]}
      />
    )
  }

  renderSubmitButton() {
    const isReady = this.isReadyForSubmission()

    return (
      <Button
        className={classNames('h6 f4 ph3', { white: !isReady })}
        buttonStyle={ButtonStyles.SECONDARY}
        disabled={!isReady}
        onClick={this.handleApply}
      >
        Apply
      </Button>
    )
  }

  render() {
    return (
      <form className="ml3 mv2 w-60 flex" style={this.props.style}>
        <div className="flex justify-between col5 mr2">
          {this.renderAmountInput()}
          {this.props.possibleReasons.length > 0 && this.renderReasonInput()}
        </div>
        {this.renderSubmitButton()}
      </form>
    )
  }
}

import React, { Component, Fragment } from 'react'
import Button, { ButtonStyles } from 'components/shared/Button'
import { connect } from 'react-redux'
import moment from 'moment'
import { get, httpDelete } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import {
  showDialog,
  hideDialog,
  startRequest,
  finishRequest,
  showNotification,
} from 'actions/synchronous'
import { createTransfer } from 'actions/asynchronous'
import StartInventoryTransferDialog from 'components/returns-queue/StartInventoryTransferDialog'
import DeleteInventoryTransferDialog from 'components/returns-queue/DeleteInventoryTransferDialog'
import { NotificationTypes } from 'reducers/notifications'
import InventoryTransfers from 'components/inventory-transfers/mobile/InventoryTransfers'

const DATE_FORMAT = 'Y-MM-DD'

export class OutboundInventoryTransfers extends Component {
  state = {
    inventoryTransfers: [],
    dateFilter: moment().format(DATE_FORMAT),
  }

  componentDidMount() {
    this.fetchInventoryTransfers()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateFilter !== this.state.dateFilter) {
      this.fetchInventoryTransfers()
    }
  }

  fetchInventoryTransfers = async () => {
    this.props.startRequest()

    const data = {
      // `page` isn't being used now, since only one day is viewable at a time.
      page: 1,
      limit: 40,
      start_date: this.state.dateFilter || '',
      end_date: this.state.dateFilter || '',
      outbound: true,
    }

    try {
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${this.props.match.params.storefrontId}/inventory_transfers`),
        data,
      )

      if (response.ok) {
        this.setState({ inventoryTransfers: responseJson.inventory_transfers })
      } else {
        this.props.showError(responseJson.message)
      }
    } catch (e) {
      this.props.showError(e.message)
    } finally {
      this.props.finishRequest()
    }
  }

  handleStartTransfer = () => {
    this.props.showDialog(
      <StartInventoryTransferDialog
        onRequestClose={this.props.hideDialog}
        onFormSubmit={async ({ reason, warehouseId }) => {
          const transfer = await this.props.createTransfer({ reason, warehouseId })
          this.props.hideDialog()
          this.props.history.push(
            `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/transfer/${transfer.id}`,
          )
        }}
      />,
    )
  }

  handleDeleteClick = inventoryTransferId => {
    this.props.showDialog(
      <DeleteInventoryTransferDialog
        inventoryTransferId={inventoryTransferId}
        onRequestClose={this.props.hideDialog}
        onFormSubmit={this.deleteInventoryTransfer}
      />,
    )
  }

  deleteInventoryTransfer = async inventoryTransferId => {
    this.props.startRequest()
    try {
      const { response, responseJson } = await httpDelete(
        retailApiUrl(
          `storefronts/${this.props.match.params.storefrontId}/inventory_transfers/${inventoryTransferId}`,
        ),
      )

      if (response.ok) {
        this.fetchInventoryTransfers()
      } else {
        this.props.showError(NotificationTypes.ERROR, responseJson.message)
      }
    } catch (err) {
      this.props.showError(NotificationTypes.ERROR, err.message)
    } finally {
      this.props.finishRequest()
    }
  }

  renderEmptyMessage() {
    return (
      <div className="tc gray-6" style={{ paddingTop: '40%' }} onClick={this.handleStartTransfer}>
        There are no outbound transfers for this date.
        <br />
        Start a new one.
      </div>
    )
  }

  render() {
    const canStartNewTransfer = this.state.dateFilter === moment().format(DATE_FORMAT)

    return (
      <>
        <div className="bb b--gray-8">
          <input
            type="date"
            value={this.state.dateFilter}
            max={moment().format(DATE_FORMAT)}
            className="bn pa2"
            onChange={event => this.setState({ dateFilter: event.target.value })}
          />
        </div>

        {this.state.inventoryTransfers.length > 0 && (
          <InventoryTransfers
            onClick={transferId =>
              this.props.history.push(
                `/storefronts/${this.props.match.params.storefrontId}/inventory-transfers/transfer/${transferId}`,
              )
            }
            onDeleteClick={this.handleDeleteClick}
          >
            {this.state.inventoryTransfers}
          </InventoryTransfers>
        )}

        {this.state.inventoryTransfers.length === 0 && (
          <div className="flex flex-column bg-gray-9 flex-auto pb12">
            <div
              className="tc gray-6 flex flex-grow-1 flex-column justify-center"
              onClick={this.handleStartTransfer}
            >
              There are no outbound transfers for this date.
              <br />
              Start a new one.
            </div>
          </div>
        )}

        {canStartNewTransfer && (
          <div className="bt b--gray-9 bg-white w-100 pa3 shadow-2 absolute bottom-0">
            <Button
              onClick={this.handleStartTransfer}
              buttonStyle={ButtonStyles.SECONDARY}
              size="full-width"
              className="f5"
            >
              Start New Transfer
            </Button>
          </div>
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { storefrontId } = ownProps.match.params
  return {
    createTransfer: requestParams => dispatch(createTransfer(storefrontId, requestParams)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: message => dispatch(showNotification(NotificationTypes.ERROR, message)),
  }
}

export default connect(null, mapDispatchToProps)(OutboundInventoryTransfers)

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { get, post } from 'lib/network'
import { retailApiUrl, staticImageUrl } from 'lib/url'
import { isUpc } from 'lib/validation'
import { playErrorSound, playSuccessSound } from 'lib/sound'
import { startRequest, finishRequest, showNotification } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'

import Scrollable from 'components/shared/Scrollable'
import SearchField from 'components/inventory-transfers/SearchField'
import Button, { ButtonStyles } from 'components/shared/Button'

export default function InventoryChangesetShow({ history, match, reason = 'Cycle Count' }) {
  const dispatch = useDispatch()
  const [currentChangeset, setCurrentChangeset] = useState()
  const [showScanSuccess, setShowScanSuccess] = useState(false)
  const [showScanError, setShowScanError] = useState(false)
  const [shouldShowConfirmationSuccess, setShouldShowConfirmationSuccess] = useState(false)

  useEffect(() => {
    fetchCycleCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showScanFlash = (type = 'success') => {
    if (type === 'success') {
      setShowScanSuccess(true)
    } else if (type === 'error') {
      setShowScanError(true)
    }

    setTimeout(() => {
      setShowScanSuccess(false)
      setShowScanError(false)
    }, 700)
  }

  const fetchCycleCount = async () => {
    try {
      dispatch(startRequest())
      const { response, responseJson } = await get(
        retailApiUrl(
          `storefronts/${match.params.storefrontId}/inventory_changesets/${match.params.changesetId}`,
        ),
      )
      if (response.ok) {
        setCurrentChangeset(responseJson)
      } else {
        dispatch(showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (e) {
      dispatch(
        showNotification(
          NotificationTypes.ERROR,
          <span>
            An error occured while fetching try on requests.
            <br />
            Share this message with an engineer:
            <br />
            <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
          </span>,
        ),
      )
    } finally {
      dispatch(finishRequest())
    }
  }

  const incrementItem = async upc => {
    const action = reason == 'Cycle Count' ? 'increment' : 'decrement'

    if (isUpc(upc)) {
      try {
        const { response, responseJson } = await post(
          retailApiUrl(
            `storefronts/${match.params.storefrontId}/inventory_changesets/${match.params.changesetId}/inventory_changes/${action}`,
          ),
          {
            upc,
          },
        )
        if (response.ok) {
          playSuccessSound()
          showScanFlash('success')
          await fetchCycleCount()
        } else {
          const message = responseJson?.message || ''

          playErrorSound()
          showScanFlash('error')
          dispatch(
            showNotification(NotificationTypes.ERROR, `Unable to scan the variant: ${message}`),
          )
        }
      } catch (e) {
        showNotification(
          NotificationTypes.ERROR,
          <span>
            An error occured while fetching try on requests.
            <br />
            Share this message with an engineer:
            <br />
            <code className="mt2 dib bg-gray-9 pv1 ph2 f5">{e.message}</code>
          </span>,
        )
      }
    } else {
      playErrorSound()
      showScanFlash('error')
      dispatch(showNotification(NotificationTypes.ERROR, 'Not detecting a valid product UPC'))
    }
  }

  const decrementItem = async upc => {
    const action = reason == 'Cycle Count' ? 'decrement' : 'increment'

    dispatch(startRequest())

    await post(
      retailApiUrl(
        `storefronts/${match.params.storefrontId}/inventory_changesets/${match.params.changesetId}/inventory_changes/${action}`,
      ),
      { upc },
    )

    await fetchCycleCount()
    dispatch(finishRequest())
  }

  const processCycleCount = async () => {
    dispatch(startRequest())

    await post(
      retailApiUrl(
        `storefronts/${match.params.storefrontId}/inventory_changesets/${match.params.changesetId}/complete`,
      ),
      { id: match.params.changesetId },
    )

    dispatch(finishRequest())

    setShouldShowConfirmationSuccess(true)
  }

  const renderItems = currentChangeset => {
    const mismatch = currentChangeset?.inventory_changes.filter(
      item => Math.abs(item.amount) !== item.expected,
    )

    const complete = currentChangeset?.inventory_changes.filter(
      item => Math.abs(item.amount) === item.expected,
    )

    let items = []

    if (currentChangeset) {
      if (reason == 'Damaged Inventory') {
        const total =
          currentChangeset.inventory_changes?.reduce((acc, val) => {
            return acc + Math.abs(val.amount)
          }, 0) || ''

        items = (
          <div className="bg-gray-9">
            <h3 className="pa1 f6 b ma0 gray-3 bg-gray-9 bb b--gray-8">
              <strong>{total}</strong> Damaged Items
            </h3>
            {currentChangeset.inventory_changes
              .slice()
              .reverse()
              .map(item => renderItem(item))}
          </div>
        )
      } else {
        items = (
          <div className="bg-gray-9">
            <h3 className="pa1 f6 b ma0 gray-3 bg-gray-9 bb b--gray-8">Mismatched Quantities</h3>
            {mismatch.map(item => renderItem(item))}

            <h3 className="pa1 f6 b ma0 gray-3 bg-gray-9 bb b--gray-8">Complete Quantities</h3>
            {complete.reverse().map(item => renderItem(item))}
          </div>
        )
      }
    }

    return (
      <>
        <div className="bb w-100">
          <Scrollable>
            {currentChangeset ? items : <div className="bg-gray-9">Scan an item to begin.</div>}
          </Scrollable>
        </div>
        <div className="absolute bottom-0 w-100 tc p-1 white">
          <Button
            className="center w-100"
            buttonStyle={ButtonStyles.TERTIARY}
            rounded={false}
            onClick={() => {
              const mismatchedSizes = mismatch.map(item => item.size)

              if (reason !== 'Damaged Inventory' && mismatch?.length > 0) {
                if (
                  window.confirm(
                    `Are you sure you want to update these quantities and process this Cycle Count? The following ${
                      mismatchedSizes.length > 1 ? 'sizes have' : 'size has'
                    } mismatched quantities: ${mismatchedSizes.join(', ')}`,
                  )
                ) {
                  processCycleCount()
                }
              } else if (window.confirm(`Are you sure you would like to confirm this ${reason}?`)) {
                processCycleCount()
              }
            }}
          >
            Submit
          </Button>
        </div>
      </>
    )
  }

  const renderItem = item => {
    const itemAmount = reason == 'Damaged Inventory' ? Math.abs(item.amount) : item.amount
    const text =
      reason == 'Damaged Inventory'
        ? `${itemAmount || 0} damaged out of ${item.expected} available`
        : `${itemAmount || 0} of ${item.expected} expected`

    return (
      <div className="flex pa1 bb b--gray-8 bg-white" key={item.id}>
        <img
          className="w15 h15 mr1"
          src={staticImageUrl(item.image_url, {
            size: 100,
          })}
          alt={item.display_name}
        />
        <div className="w-100">
          <h2 className="mv0 f6 lh-copy">{item.display_name}</h2>
          <div className="grey">
            <dl className="f6 mv0 lh-copy">
              <dt className="gray-7 di">Size:</dt> <dd className="di mh0">{item.size}</dd>
              <br />
              <dt className="gray-7 di">UPC:</dt> <dd className="di mh0">{item.upc}</dd>
              <dd className="db mh0 mt1 ba br3 b--gray-8 flex justify-between items-center bg-white">
                <Button
                  className="w-20 bg-transparent"
                  buttonStyle={ButtonStyles.PRIMARY}
                  onClick={() => {
                    // don't allow decrementing to values below zero
                    if (itemAmount > 0) {
                      decrementItem(item.upc)
                    } else {
                      playErrorSound()
                      showScanFlash('error')
                    }
                  }}
                >
                  <span className="pl2 pr2 pt0 br-pill ba bw" style={{ paddingBottom: '3px' }}>
                    -
                  </span>
                </Button>
                <span
                  className={classNames('w-60 fw6 f6 h-100 tc br-pill pv1', {
                    'bg-red-9 red-3': itemAmount > item.expected,
                    'bg-gray-9 gray-3': itemAmount < item.expected || !item.amount,
                    'bg-green-4 white': itemAmount === item.expected,
                  })}
                >
                  {text}
                </span>
                <div className="w-10" />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    )
  }

  const action = reason == 'Cycle Count' ? 'cycle-counts' : 'damages'

  return (
    <div>
      {showScanSuccess && (
        <div className="flash absolute f1 w-100 z-1 vh-100 bg-green-7 o-90 top-0 left-0 flex items-center justify-center" />
      )}
      {showScanError && (
        <div className="flash absolute f1 w-100 z-1 vh-100 bg-red-4 o-90 top-0 left-0 flex items-center justify-center" />
      )}

      <div className="bb b--gray-9 w-100">
        <SearchField
          autoFocus
          clearOnSubmit
          className="b--none"
          placeholderText="Scan item UPC..."
          isLoading={false}
          onSubmit={async upc => {
            const trimmedUpc = upc.trim()
            incrementItem(trimmedUpc)
          }}
        />
      </div>

      {shouldShowConfirmationSuccess && (
        <div className="absolute w-100 vh-100 bg-white-80 green-3 justify-center items-center flex z-1 fw5 f3">
          <div className="bg-white ph3 pv2 br3 ba tc b--gray-7">
            <span className="db mb2">Successfully Processed Inventory</span>

            <Button
              color="gray-2"
              onClick={() => {
                window.location = `/storefronts/${match.params.storefrontId}/${action}`
              }}
            >
              {reason == 'Cycle Count'
                ? 'View Existing Cycle Counts'
                : 'View Existing Damaged Inventory Counts'}
            </Button>
          </div>
        </div>
      )}

      {renderItems(currentChangeset)}
    </div>
  )
}

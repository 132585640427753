import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { getQueryParam } from 'lib/url'
import { pubWithSale, pubWithReturn, Event } from 'lib/events'
import IconWithLabel from 'components/shared/IconWithLabel'
import RotateTablet from 'components/customers/RotateTablet'

class SignUpSuccess extends Component {
  saleNumber = getQueryParam('saleNumber')

  returnNumber = getQueryParam('returnNumber')

  componentDidMount() {
    if (this.saleNumber) {
      pubWithSale(Event.SaleAuthSuccess.PAGE_VIEW, {
        user_type: getQueryParam('customerExisted') ? 'Existing' : 'New',
      })
    } else if (this.returnNumber) {
      pubWithReturn(Event.ReturnAuthSuccess.PAGE_VIEW, {
        user_type: getQueryParam('customerExisted') ? 'Existing' : 'New',
      })
    }
  }

  handleContinue = () => {
    if (this.saleNumber) {
      pubWithSale(Event.SaleAuthSuccess.RETURN_TO_SALE)
    } else if (this.returnNumber) {
      pubWithReturn(Event.ReturnAuthSuccess.RETURN_TO_RETURN)
    }

    const destination = (() => {
      if (this.saleNumber) {
        return `/storefronts/${this.props.match.params.storefrontId}/sales/${this.saleNumber}/product`
      }
      if (this.returnNumber) {
        return `/storefronts/${this.props.match.params.storefrontId}/returns/${this.returnNumber}/items`
      }
      return `/storefronts/${this.props.match.params.storefrontId}/customers`
    })()

    this.props.history.push(destination)
  }

  render() {
    const userGetsFreeShipping = this.props.isNewCustomer
    return (
      <div className="tc center-vh flex flex-column items-center justify-center white w-100 h-100 bg-gray-2">
        <div className="absolute" style={{ right: '30px', top: '25px' }}>
          <IconWithLabel
            color="white"
            onClick={this.handleContinue}
            icon="arrow-right"
            iconClassName="f5"
            labelClassName="pr1"
            label="Continue"
            className="flex-row-reverse"
          />
        </div>
        <div
          className="serif"
          style={this.props.isMobile ? { fontSize: '20px' } : { fontSize: '40px' }}
        >
          {userGetsFreeShipping ? (
            <>
              <div
                className={classNames({
                  pb3: !this.props.isMobile,
                  pb1: this.props.isMobile,
                })}
              >
                Welcome to Everlane —
              </div>
              <div
                className={classNames({
                  pb3: !this.props.isMobile,
                  pb1: this.props.isMobile,
                })}
              >
                you've received
                <span style={{ color: '#B0CCCC' }}> free 2-day shipping*</span>
              </div>
              <div className="pb7">on your next online purchase.</div>
            </>
          ) : (
            <div className="pb7">Welcome to Everlane!</div>
          )}
        </div>
        {!this.props.isMobile && (
          <RotateTablet
            size={userGetsFreeShipping ? '120px' : '180px'}
            color="#B0CCCC"
            className="pb5"
          />
        )}
        <div>{`Please return the ${this.props.isMobile ? 'phone' : 'screen'} to`}</div>
        <div>the associate to complete the sale.</div>
        {userGetsFreeShipping && (
          <div className="absolute f6" style={{ bottom: '20px' }}>
            *Offer valid for 30 days from today.
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isMobile: state.ui.isMobile,
  }
}

export default connect(mapStateToProps)(SignUpSuccess)

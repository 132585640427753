import React from 'react'
import classNames from 'classnames'
import Icon from 'components/shared/Icon'

export default function StorefrontTicketCTA({ onClick, isMobile }) {
  return (
    <Icon
      onClick={onClick}
      glyph="envelope-mail-closed"
      color="gray-2"
      className={classNames('absolute z-3 bg-gray-9 br-100 gray-2', {
        'w8 h8 f3 left-1 bottom-1': !isMobile,
        'w5 h5 f5 top-1 right-1': isMobile,
      })}
    />
  )
}

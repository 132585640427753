import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/shared/Select'
import getConstants from 'lib/constants'

class ChineseProvinceSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    error: PropTypes.object,
    needsUniqueId: PropTypes.bool,
    existingProvince: PropTypes.string,
    existingCity: PropTypes.string,
    existingDistrict: PropTypes.string,
  }

  state = {
    province: this.props.existingProvince || null,
    city: this.props.existingCity || null,
    district: this.props.existingDistrict || null,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.onChange && this.state !== prevState) {
      this.props.onChange(this.state)
    }
  }

  getProvinces() {
    const chineseProvinces = { ...getConstants().chinese_provinces }

    return Object.keys(chineseProvinces)
  }

  getCities() {
    const { province } = this.state
    const chineseProvinces = { ...getConstants().chinese_provinces }
    const cities = chineseProvinces[province]

    if (cities) {
      return Object.keys(cities)
    }
    return []
  }

  getDistricts() {
    const { province, city } = this.state
    const chineseProvinces = { ...getConstants().chinese_provinces }
    const cities = chineseProvinces[province]

    if (!cities) {
      return []
    }

    const districts = cities[city]

    if (districts) {
      return districts
    }
    return []
  }

  render() {
    return (
      <div className="flex">
        <div className="w-33 pr1">
          <Select
            error={this.props.error && !this.state.province ? 'Required' : null}
            type="select"
            label="Province"
            value={this.state.province || ''}
            onChange={event => this.setState({ province: event.target.value })}
            options={this.getProvinces()}
            showBlank
            disabled={this.props.needsUniqueId}
            defaultValue="Select a province"
          />
        </div>

        <div className="w-33 pr1">
          <Select
            error={this.props.error && !this.state.city ? 'Required' : null}
            type="select"
            label="City"
            value={this.state.city || ''}
            onChange={event => this.setState({ city: event.target.value })}
            options={this.getCities()}
            disabled={this.props.needsUniqueId}
            showBlank
            inputClassName={!this.state.province && 'form__input--disabled'}
          />
        </div>

        <div className="w-33">
          <Select
            error={this.props.error && !this.state.district ? 'Required' : null}
            type="select"
            label="District"
            value={this.state.district || ''}
            onChange={event => this.setState({ district: event.target.value })}
            options={this.getDistricts()}
            showBlank
            disabled={this.props.needsUniqueId}
            inputClassName={!this.state.city && 'form__input--disabled'}
          />
        </div>
      </div>
    )
  }
}

export default ChineseProvinceSelector

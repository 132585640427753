import React from 'react'
import Icon from 'components/shared/Icon'

export default function BagFeeControls({ onIncrementBags, onDecrementBags, numberOfBags }) {
  return (
    <div className="flex items-center absolute center-vh">
      <Icon
        glyph="circle-minus"
        color="gray-6"
        className="mr1 pointer f3"
        onClick={onDecrementBags}
      />
      {numberOfBags}
      <Icon
        glyph="circle-plus"
        color="gray-6"
        className="ml1 pointer f3"
        onClick={onIncrementBags}
      />
    </div>
  )
}

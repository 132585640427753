const initial_state = {
  total: '',
  subtotal: '',
  adjustments: [],
}

export default function orderPreview(state = initial_state, action) {
  switch (action.type) {
    case 'RECEIVE_ORDER_PREVIEW': {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

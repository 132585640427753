import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { pubWithSale, Event } from 'lib/events'
import { addAddressToSale } from 'actions/asynchronous'
import { getSaleByNumber, getSaleItemsBeingShipped } from 'reducers/sales'
import TopBar from 'components/shared/TopBar'
import IconWithLabel from 'components/shared/IconWithLabel'
import Address from 'components/sales/Address'
import Button, { ButtonStyles } from 'components/shared/Button'
import Scrollable from 'components/shared/Scrollable'
import { isUniqueIdRequired } from 'lib/UniqueIdHelper.jsx'
import logo from 'svg/logo.svg'

class AddAddressToSale extends Component {
  static propTypes = {
    addAddressToSale: func.isRequired,
  }

  componentDidMount() {
    pubWithSale(Event.SaleExistingAddress.PAGE_VIEW)
  }

  handleAddAddressToSale = async address => {
    const { storefrontId, saleNumber } = this.props.match.params
    await this.props.addAddressToSale(address)

    if (
      isUniqueIdRequired(address.country) &&
      (this.props.sale.shipping_address.unique_id === '' ||
        !this.props.sale.shipping_address.unique_id)
    ) {
      this.props.history.push(`/storefronts/${storefrontId}/sales/${saleNumber}/address/edit`)
    } else {
      pubWithSale(Event.SaleExistingAddress.SELECT_EXISTING_ADDRESS)
      this.props.history.push(`/storefronts/${storefrontId}/sales/${saleNumber}/shipping`)
    }
  }

  render() {
    const { params } = this.props.match

    if (!this.props.sale.customer) {
      return <Redirect to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}`} />
    }

    return (
      <div className="relative">
        <TopBar
          left={
            <Link
              to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}/product`}
              onClick={() => pubWithSale(Event.SaleExistingAddress.RETURN_TO_SALE)}
              className="no-underline"
            >
              <IconWithLabel color="black" icon="arrow-left" iconClassName="f5 pr1" label="Sale" />
            </Link>
          }
          center={<img src={logo} alt="Welcome to Everlane" className="w16" />}
        />
        <div className="ma2">
          <div>Please select or add a shipping address for these items:</div>
          <Scrollable style={{ paddingBottom: '100px' }}>
            {this.props.sale.customer.addresses.map(address => (
              <Address
                key={address.id}
                fullName={address.full_name}
                streetAddress={address.street_address}
                extendedAddress={address.extended_address}
                city={address.city}
                region={address.region_code}
                postalCode={address.postal_code}
                country={address.country}
                phoneNumber={address.formatted_phone}
                onClick={() => this.handleAddAddressToSale(address)}
                isActive={
                  this.props.sale.shipping_address &&
                  this.props.sale.shipping_address.id === address.id
                }
              />
            ))}
          </Scrollable>
        </div>
        <Button
          className="absolute center w-70 left-0 right-0"
          style={{ bottom: '20px' }}
          buttonStyle={ButtonStyles.TERTIARY}
          to={`/storefronts/${params.storefrontId}/sales/${params.saleNumber}/address/new`}
          onClick={() => pubWithSale(Event.SaleExistingAddress.ADD_NEW_ADDRESS)}
        >
          Add New Address
        </Button>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    sale: getSaleByNumber(state.sales, saleNumber),
    saleItemsBeingShipped: getSaleItemsBeingShipped(state.sales, saleNumber),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { saleNumber } = ownProps.match.params

  return {
    addAddressToSale: address => dispatch(addAddressToSale(saleNumber, address)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAddressToSale)

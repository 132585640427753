import Braintree from 'braintree-web/client'
import NameParser from 'name-parser'
import { post } from 'lib/network'
import { apiUrl } from 'lib/url'

// HOW THIS ALL WORKS
// ==================
// The process of creating a braintree credit card involves several steps that must be executed
// in sequence:
// 1. Fetch the braintree client token from our API (`fetchBraintreeToken`)
// 2. Use the Braintree library to create a new client instance (`createBrainteeClient`)
// 3. Use the client instance to create a new braintree credit card object (`createBraintreeCreditCard`)

// At this point you will have a nonce representing a braintree credit card object.
// From here, you will likely use this to perform some other steps:

// 4. Use this nonce to create a "PaymentMethod" and associate it with the customer and sale
// 5. Assign this newly created PaymentMethod as the chosen payment method for the sale
// 6. Refetch the sale, updating the payment information on it.

// That's at least 6 network requests to add a payment method. TODO: can we do this better?

function formatCreditCardAsBraintreeData(creditCard) {
  const { firstName, lastName } = NameParser.parse(creditCard.fullName)

  if (creditCard.country === 'US') {
    return {
      creditCard: {
        number: creditCard.number,
        cvv: creditCard.securityCode,
        expirationDate: creditCard.expirationDate,
        billingAddress: {
          firstName,
          lastName,
          postalCode: creditCard.postalCode,
          countryCodeAlpha2: creditCard.country,
        },
        options: {
          validate: true,
        },
      },
    }
  }
  return {
    creditCard: {
      number: creditCard.number,
      cvv: creditCard.securityCode,
      expirationDate: creditCard.expirationDate,
      billingAddress: {
        firstName,
        lastName,
        postalCode: creditCard.postalCode,
        countryCodeAlpha2: creditCard.country,
      },
      options: {
        validate: true,
      },
    },
  }
}

async function fetchBraintreeToken(customerEmail) {
  const { response, responseJson } = await post(apiUrl('braintree_token'), {
    customer_email: customerEmail,
  })

  if (response.ok) {
    return responseJson.braintree_token
  }
}

function createBrainteeClient(token) {
  return Braintree.create({ authorization: token })
}

function createBraintreeCreditCard(client, creditCard) {
  return client
    .request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: formatCreditCardAsBraintreeData(creditCard),
    })
    .then(({ creditCards }) => {
      return creditCards[0].nonce
    })
}

export function tokenizeCardForCustomer(customer, creditCard) {
  return fetchBraintreeToken(customer.email)
    .then(createBrainteeClient)
    .then(client => createBraintreeCreditCard(client, creditCard))
}

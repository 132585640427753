import React from 'react'
import Button from 'components/shared/Button'
import classNames from 'classnames'

export default function SourceButton({ onClick, status, children, pipColor, className }) {
  const text = {
    success: 'Added!',
    loading: 'Loading…',
    disabled: children,
    enabled: children,
  }[status]

  const isDisabled = ['loading', 'disabled'].includes(status)
  const effectivePipColor = isDisabled ? 'gray-9' : pipColor

  return (
    <Button
      className={classNames('mr2', className)}
      outlined
      disabled={isDisabled}
      color={isDisabled ? 'gray-9' : 'gray-2'}
      style={{
        width: '12rem',
        height: '4rem',
        transition: 'all 250ms',
      }}
      size="medium"
      onClick={onClick}
    >
      <span className={classNames('mr1 f2', effectivePipColor)}>•</span>
      <span>{text}</span>
    </Button>
  )
}

export default function notifications(state = {}, action) {
  switch (action.type) {
    case 'SHOW_NOTIFICATION': {
      let { key, message, cta } = action.payload

      cta = cta || CTAType.DISMISS

      return {
        ...state,
        [key]: { message, cta },
      }
    }

    case 'DISMISS_NOTIFICATION': {
      // Tricky, but it works!
      const { [action.payload.key]: _, ...stateWithoutNotification } = state
      return stateWithoutNotification
    }

    default:
      return state
  }
}

export const NotificationTypes = {
  ERROR: 'ERROR',
  INFO: 'INFO',
}

export const CTAType = {
  DISMISS: 'DISMISS',
  GO_BACK: 'GO_BACK',
}

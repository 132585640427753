import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connectStateResults } from 'react-instantsearch-dom'
import classNames from 'classnames'

import ProductSearchHits from 'components/product-search/ProductSearchHits'
import Checkbox from 'components/shared/Checkbox'

function ProductSearchResults({
  searchResults,
  isMobile,
  error,
  storefront,
  sale,
  setShowInactiveProducts,
  shouldShowInactiveProducts,
}) {
  if (error) {
    return (
      <p className="pv1 pl3">
        We are sorry but something went wrong on our end. Please try again later.
      </p>
    )
  }

  // if there are no results
  if (!searchResults || (searchResults && !searchResults.query)) {
    return null
  }

  const message =
    searchResults.nbHits === 0
      ? 'Sorry, we couldn’t find any matching results for this search.'
      : `${searchResults.nbHits} search results`

  const handleCheckboxClick = () => setShowInactiveProducts(!shouldShowInactiveProducts)

  return (
    <>
      <div
        className={classNames('pv1 bb b--gray-9 flex justify-between', {
          'ph2 f6': isMobile,
          'ph4 f5': !isMobile,
        })}
      >
        {message}
        <Checkbox
          label="Include inactive products"
          fontSize={isMobile ? 'f6' : 'f5'}
          checked={shouldShowInactiveProducts}
          onChange={handleCheckboxClick}
        />
      </div>
      <div className={classNames('pv1 pl1', { pr1: isMobile, pr3: !isMobile })}>
        <ProductSearchHits storefront={storefront} sale={sale} />
      </div>
    </>
  )
}

ProductSearchResults.propTypes = {
  searchResults: PropTypes.shape({
    hits: PropTypes.array,
    nbHits: PropTypes.number,
    page: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  }),
  searchState: PropTypes.shape({
    page: PropTypes.number,
    query: PropTypes.string,
  }),
}

export default connectStateResults(ProductSearchResults)

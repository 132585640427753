import { getDataFromBrowser, saveDataToBrowser, removeDataFromBrowser } from 'lib/storage'

const ASSOCIATE_AUTH_TOKEN_KEY = 'pos__s_auth_token'

export function getAssociateAuthToken() {
  return getDataFromBrowser(ASSOCIATE_AUTH_TOKEN_KEY)
}

export function setAssociateAuthToken(authToken) {
  return saveDataToBrowser(ASSOCIATE_AUTH_TOKEN_KEY, authToken)
}

export function removeAssociateAuthToken() {
  return removeDataFromBrowser(ASSOCIATE_AUTH_TOKEN_KEY)
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getStorefrontById } from 'reducers/storefronts'
import { getCanAssociateAccessAllStorefronts } from 'reducers/associate'
import { showDialog, hideDialog } from 'actions/synchronous'
import MenuItems from 'components/navigation/MenuItems'
import SidebarDropdown from 'components/navigation/SidebarDropdown'
import SidebarMainMenu from 'components/navigation/SidebarMainMenu'
import SidebarStorefrontsMenu from 'components/navigation/SidebarStorefrontsMenu'
import ConfirmationDialog from 'components/shared/ConfirmationDialog'
import logOut, { logOutAssociate } from 'lib/session'
import { ButtonStyles } from 'components/shared/Button'
import { bool } from 'prop-types'

class Sidebar extends Component {
  static propTypes = {
    isMobile: bool,
  }

  state = {
    isExpanded: false,
    showStorefronts: false,
  }

  render() {
    return (
      <div className="sidebar fixed z-3 h-100 bg-white col4 br b--gray-6">
        <SidebarDropdown
          isExpanded={this.state.isExpanded}
          associateName={this.props.associate.full_name}
          activeStorefrontName={
            this.props.isMobile ? this.props.storefront.display_name : this.props.storefront.name
          }
          onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
        >
          {this.state.showStorefronts ? (
            <SidebarStorefrontsMenu
              storefronts={this.props.storefronts}
              activeStorefront={this.props.storefront}
              onBack={() => this.setState({ showStorefronts: false })}
              onSelectStorefront={() =>
                this.setState({
                  showStorefronts: false,
                  isExpanded: false,
                })
              }
              isMobile={this.props.isMobile}
            />
          ) : (
            <SidebarMainMenu
              showSwitchStorefront={this.props.canAssociateAccessAllStorefronts}
              onSwitchStorefront={() => this.setState({ showStorefronts: true })}
              onLogOut={() =>
                this.props.showDialog(
                  <ConfirmationDialog
                    onNo={this.props.hideDialog}
                    onYes={logOut}
                    title="Log Out?"
                    question="If you log out, you won't be able to use Point Of Sale until the authorized person logs in again."
                    buttonStyle={ButtonStyles.TERTIARY}
                  />,
                )
              }
              onSwitchEmployee={logOutAssociate}
              onReload={() => window.location.reload()}
            />
          )}
        </SidebarDropdown>

        {!this.state.isExpanded && <MenuItems />}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    associate: state.associate,
    canAssociateAccessAllStorefronts: getCanAssociateAccessAllStorefronts(state.associate),
    storefront: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId),
    storefronts: state.storefronts,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showDialog(dialog) {
      dispatch(showDialog(dialog))
    },
    hideDialog() {
      dispatch(hideDialog())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bool, func, array } from 'prop-types'

import { get } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import Header from 'containers/Header'
import { getIsLoading } from 'reducers/activeRequests'
import { createCashFloat, fetchCashFloats, editCashFloat } from 'actions/asynchronous'
import { startRequest, finishRequest, showDialog, hideDialog } from 'actions/synchronous'
import CashFloatTable from 'components/cash-float/CashFloatTable'
import CashFloatDialog from 'components/cash-float/CashFloatDialog'

class CashFloat extends React.Component {
  state = { cashFloatId: null, netCash: 0 }

  componentDidMount() {
    this.props.fetchCashFloats()
    this.getNetCash()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.storefrontId !== this.props.match.params.storefrontId) {
      this.props.fetchCashFloats()
    }
  }

  getNetCash = async () => {
    this.props.startRequest()
    const { response, responseJson } = await get(
      retailApiUrl(`storefronts/${this.props.match.params.storefrontId}/cash_payments`),
    )

    if (response.ok) {
      const { amount } = responseJson
      amount && this.setState({ netCash: parseFloat(amount) })
    }

    this.props.finishRequest()
  }

  handleStartNewDay = () => {
    this.handleOpenModal()
  }

  handleOpenModal = () => {
    this.props.showDialog(
      <CashFloatDialog
        onRequestClose={this.handleCloseModal}
        onEnterCashValue={this.handleEnterCashValue}
      />,
    )
  }

  handleCloseModal = () => {
    this.props.hideDialog()
    this.setState({ cashFloatId: null })
  }

  handleEnterCashValue = cashValue => {
    this.state.cashFloatId
      ? this.props.editCashFloat(this.state.cashFloatId, {
          ending_amount: parseFloat(cashValue).toFixed(2),
        })
      : this.props.createCashFloat(parseFloat(cashValue).toFixed(2))
  }

  handleClickEndDay = cashFloatId => {
    this.setState({ cashFloatId }, () => this.handleOpenModal())
  }

  render() {
    return (
      <>
        <Header
          ctaText={this.props.isLoading ? 'Loading...' : 'Start New Day'}
          onCtaClick={this.handleStartNewDay}
          ctaDisabled={this.props.isLoading}
          textColor="white"
        />
        <CashFloatTable
          netCash={this.state.netCash}
          cashFloats={this.props.cashFloats?.slice(0, 31)}
          onClickEndDay={this.handleClickEndDay}
        />
      </>
    )
  }
}

CashFloat.propTypes = {
  isLoading: bool,
  cashFloats: array,
  fetchCashFloats: func,
  createCashFloat: func,
}

function mapStateToProps(state) {
  return {
    isLoading: getIsLoading(state.activeRequests),
    cashFloats: state.cashFloats,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { storefrontId } = ownProps.match.params
  return {
    fetchCashFloats: () => dispatch(fetchCashFloats(storefrontId)),
    createCashFloat: amount => dispatch(createCashFloat(storefrontId, amount)),
    editCashFloat: (cashFloatId, attrs) =>
      dispatch(editCashFloat(cashFloatId, storefrontId, attrs)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFloat)

import React from 'react'
import { oneOf } from 'prop-types'
import classNames from 'classnames'
import Button from 'components/shared/Button'

// Renders an icon based on a specified glyph. If an `onClick` handler is passed,
// the rendered element will be a `button`, 'otherwise an `i`.
function Icon({
  color = 'white',
  className = '',
  glyph,
  onClick,
  style = {},
  size = null,
  innerRef,
}) {
  const iconClassName = classNames('icon', `icon--${glyph}`, className, color, {
    'bg-transparent': !className.includes('bg-'),
  })

  return onClick ? (
    <Button
      innerRef={innerRef}
      type="button"
      className={iconClassName}
      onClick={onClick}
      style={style}
      size={size}
      fontFamilyClassName="icon"
    />
  ) : (
    <i ref={innerRef} className={iconClassName} style={style} />
  )
}

const glyphs = [
  'airplane',
  'arrow-left',
  'bell',
  'bicycle',
  'bicycle-fast',
  'box-face',
  'box-top',
  'briefcase',
  'bullet-list',
  'check',
  'checkmark',
  'circle',
  'circle-arrow--up',
  'circle-arrow-down',
  'circle-checkmark',
  'circle-ruler',
  'clock',
  'credit-card',
  'document-long',
  'envelope-document',
  'envelope-mail-closed',
  'envelope-mail-open',
  'exit',
  'expand',
  'gift',
  'globe',
  'hamburger',
  'hanger',
  'hangtag',
  'house',
  'identification-card',
  'iphone',
  'lock-locked',
  'magnify',
  'magnify-minus',
  'magnify-plus',
  'pencil',
  'people',
  'person',
  'placemarker',
  'receipt',
  'ribbon',
  'ruler',
  'scissors',
  'sewing-machine',
  'square-stack',
  'stack',
  'stacks',
  'star',
  'three-circles',
  'trash-can',
  'up',
  'weekender',
  'x',
  'plus',
  'circle-plus',
  'circle-minus',
  'arrow-right',
]

Icon.propTypes = {
  glyph: oneOf(glyphs),
}

export default Icon

import getEnv, { isProduction } from 'lib/env'
import { getTimezoneOffset } from 'lib/time'
import { getQueryParam } from 'lib/url'
import eventDefinitions from 'config/customEvents'
import { Firehose } from '@aws-sdk/client-firehose'
import { getSaleByNumber } from 'reducers/sales'
import { getReturnReceiptById } from 'reducers/returnReceipts'
import store from 'store'

const firehose = new Firehose({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
})

export const Event = namespace(eventDefinitions)

export function pub(eventName, eventPayload = {}) {
  if (eventName == null) {
    return console.warn('`pub` was called with an undefined eventName. Did you misspell it?')
  }

  const { associate, ui } = store.getState()

  const { pathname } = window.location
  const matchData = pathname.match(/storefronts\/(\d+)\//)
  const storefrontId = matchData ? matchData[1] : null

  const logEvent = () => {
    console.groupCollapsed(`%cEvent published:%c ${eventName}`, `color: #3CB371`, `color: #FF7F50`)
    console.dir(eventPayload)
    console.groupEnd()
  }

  const eventContext = {
    pathname,
    authStatus: associate ? 'signed_in' : 'signed_out',
    timezoneOffset: getTimezoneOffset(),
    userAgent: navigator.userAgent,
    isMobile: ui.isMobile,
  }

  if (!isProduction()) {
    logEvent()
  } else {
    firehose.putRecord(
      {
        DeliveryStreamName: 'raw-events',
        Record: {
          Data: JSON.stringify({
            event: eventName,
            receivedAt: new Date().toISOString(),
            originalTimestamp: new Date().toISOString(),
            properties: {
              ...eventPayload,
              storefrontId,
              sales_associate_id: associate ? associate.id : undefined,
              platform: 'POS',
              isMobile: ui.isMobile,
            },
            context: eventContext,
          }),
        },
      },
      err => {
        if (err) {
          console.error(err)
        } else {
          logEvent()
        }
      },
    )
  }
}

// Transforms an object shaped like `EventDefinitions` below into an idential object,
// except that `null` gets replaced with the full name of the event (`MENU_ITEM_CLICK` -> `Sidebar.MENU_ITEM_CLICK`)
function namespace(node, keyPrefix = '') {
  if (!node || !Object.values(node).every(value => value == null || typeof value === 'object')) {
    return keyPrefix.slice(0, -1)
  }
  const ret = []

  Object.keys(node).forEach(key => {
    ret[key] = namespace(node[key], `${keyPrefix}${key}.`)
  })

  return ret
}

export function pubWithSale(eventName, eventPayload = {}) {
  const { pathname } = window.location
  const matchData = pathname.match(/sales\/(p\d+)\//)
  const saleNumber = (matchData && matchData[1]) || getQueryParam('saleNumber')

  if (!saleNumber) {
    console.warn('pubWithSale was called in a non-sale context. Did you mean to use pub?')
  }

  const sale = getSaleByNumber(store.getState().sales, saleNumber)

  if (sale && sale.customer) {
    return pub(eventName, {
      ...eventPayload,
      sale_number: saleNumber,
      customer_id: sale.customer.id,
    })
  }
  return pub(eventName, { ...eventPayload, sale_number: saleNumber })
}

export function pubWithReturn(eventName, eventPayload = {}) {
  const { pathname } = window.location
  const matchData = pathname.match(/returns\/(\d+)\//)
  const returnNumber = (matchData && matchData[1]) || getQueryParam('returnNumber')

  if (!returnNumber) {
    console.warn('pubWithReturn was called in a non-returns context. Did you mean to use pub?')
  }

  const returnReceipt = getReturnReceiptById(store.getState().returnReceipts, returnNumber)

  if (returnReceipt) {
    return pub(eventName, {
      ...eventPayload,
      return_receipt_id: returnNumber,
      return_type: returnReceipt.is_gift_return ? 'gift' : 'standard',
    })
  }
  return pub(eventName, { ...eventPayload, return_receipt_id: returnNumber })
}

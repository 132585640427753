import React from 'react'
import classNames from 'classnames'

import { staticImageUrl } from 'lib/url'
import Button, { ButtonStyles } from 'components/shared/Button'
import Icon from 'components/shared/Icon'

const renderItem = item => {
  return (
    <img
      key={item.id}
      src={staticImageUrl(item.image_url, { size: 80 })}
      alt={item.name}
      style={{ height: '80px', width: '80px' }}
      className="pa1"
    />
  )
}

function ItemSummary({ sale, onContinue, toggleMobileSummary, isExpanded }) {
  const items = sale ? sale.sale_items : []
  if (items.length === 0) {
    return null
  }
  const transform = isExpanded ? 'rotate(90deg)' : 'rotate(270deg)'

  return (
    <div className="fixed bottom-0 bt b--gray-9 bg-white w-100">
      <div className="pa1" onClick={toggleMobileSummary}>
        <div className="pl1 f5 relative">
          {`Shopping Bag (${items.length})`}{' '}
          <Icon
            glyph="arrow-right"
            className={classNames('absolute top-0 bottom-0', {
              'right-1': isExpanded,
              'right-0': !isExpanded,
            })}
            color="black"
            style={{ transform }}
          />
        </div>
        {isExpanded && (
          <div className="flex overflow-x-scroll touch-momentum-scroll">
            {items.map(item => renderItem(item))}
          </div>
        )}
      </div>
      <Button
        size="full-width"
        className="h6 f4"
        buttonStyle={ButtonStyles.SECONDARY}
        onClick={onContinue}
      >
        Continue
      </Button>
    </div>
  )
}

export default ItemSummary

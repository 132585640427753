import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import { showSidebar, hideSidebar } from 'actions/synchronous'
import { getIsLoading } from 'reducers/activeRequests'
import { getDoesAssociateHaveAccessToStorefront } from 'reducers/associate'
import Sidebar from 'containers/Sidebar'
import PleaseSignIn from 'containers/auth/PleaseSignIn'
import SearchForOrders from 'containers/returns/SearchForOrders'
import SearchForOrdersMobile from 'containers/returns/mobile/SearchForOrders'
import IncompleteSales from 'containers/sales/IncompleteSales'
import StockCheck from 'containers/StockCheck'
import CycleCounts from 'containers/CycleCounts'
import Damages from 'containers/Damages'
import IncompleteSalesMobile from 'containers/sales/mobile/IncompleteSales'
import FeatureControl from 'containers/FeatureControl'
import CashFloat from 'containers/cash-float/CashFloat'
import SaleInterface from 'containers/sales/SaleInterface'
import SignatureSuccess from 'containers/signature/Success'
import ReturnInterface from 'containers/returns/ReturnInterface'
import Reports from 'containers/Reports'
import Stripe from 'containers/Stripe'
import CustomersInterface from 'containers/customers/CustomersInterface'
import ReturnsQueueInterface from 'containers/returns-queue/ReturnsQueueInterface'
import InventoryTransfers from 'containers/inventory-transfers/InventoryTransfers'
import LoadingOverlay from 'components/shared/LoadingOverlay'
import Notifications from 'components/shared/Notifications'
import RestockQueueInterface from 'containers/restock-queue/RestockQueueInterface'
import RequestToTry from 'containers/RequestToTry'
import NotAllowed from 'components/auth/NotAllowed'
import { getAllowedPathsForRoles, getAllowedPathsForGroup } from 'lib/url'
import FadeIn from 'components/shared/transitions/FadeIn'
import { isProduction } from 'lib/env'
import { logOutAfterNoActivity } from 'lib/session'
import { getStorefrontById } from 'reducers/storefronts'
import Dialog from 'components/shared/Dialog'

class StorefrontInterface extends Component {
  componentDidMount() {
    if (isProduction()) {
      logOutAfterNoActivity(this.props.history)
    }
  }

  componentDidUpdate(prevProps) {
    const weJustNavigated = this.props.location !== prevProps.location

    if (weJustNavigated && this.props.isSidebarVisible) {
      this.props.hideSidebar()
    }
  }

  // If they go back to the storefront selection screen, set the UI state accordingly
  componentWillUnmount() {
    this.props.hideSidebar()
  }

  renderRoutes() {
    const routes = [
      <Route
        key={`${this.props.match.path}/sales`}
        exact
        path={`${this.props.match.path}/sales`}
        component={this.props.isMobile ? IncompleteSalesMobile : IncompleteSales}
      />,
      <Route
        key={`${this.props.match.path}/sales/:saleNumber`}
        path={`${this.props.match.path}/sales/:saleNumber`}
        component={SaleInterface}
      />,
      <Route
        key={`${this.props.match.path}/returns`}
        exact
        path={`${this.props.match.path}/returns`}
        component={this.props.isMobile ? SearchForOrdersMobile : SearchForOrders}
      />,
      <Route
        key={`${this.props.match.path}/returns/:returnReceiptId`}
        path={`${this.props.match.path}/returns/:returnReceiptId`}
        component={ReturnInterface}
      />,
      <Route
        key={`${this.props.match.path}/customers`}
        path={`${this.props.match.path}/customers`}
        component={CustomersInterface}
      />,
      <Route
        key={`${this.props.match.path}/history`}
        path={`${this.props.match.path}/history`}
        component={Reports}
      />,
      <Route
        key={`${this.props.match.path}/stock-check`}
        path={`${this.props.match.path}/stock-check`}
        component={StockCheck}
      />,
      <Route
        key={`${this.props.match.path}/cycle-counts`}
        path={`${this.props.match.path}/cycle-counts`}
        component={CycleCounts}
      />,
      <Route
        key={`${this.props.match.path}/damages`}
        path={`${this.props.match.path}/damages`}
        component={Damages}
      />,
      <Route
        key={`${this.props.match.path}/signature/success`}
        path={`${this.props.match.path}/signature/success`}
        component={SignatureSuccess}
      />,
      <Route
        key={`${this.props.match.path}/request-to-try`}
        path={`${this.props.match.path}/request-to-try`}
        component={RequestToTry}
      />,
      <Route
        key={`${this.props.match.path}/restocks`}
        path={`${this.props.match.path}/restocks`}
        component={RestockQueueInterface}
      />,
      <Route
        key={`${this.props.match.path}/back-of-house`}
        path={`${this.props.match.path}/back-of-house`}
        component={ReturnsQueueInterface}
      />,
      <Route
        key={`${this.props.match.path}/feature-control`}
        path={`${this.props.match.path}/feature-control`}
        component={FeatureControl}
      />,
      <Route
        key={`${this.props.match.path}/float`}
        path={`${this.props.match.path}/float`}
        component={CashFloat}
      />,
      <Route
        key={`${this.props.match.path}/inventory-transfers`}
        path={`${this.props.match.path}/inventory-transfers`}
        component={InventoryTransfers}
      />,
      <Route
        exact
        key={`${this.props.match.path}/stripe`}
        path={`${this.props.match.path}/stripe`}
        component={Stripe}
      />,
    ]

    const allowedPathsForRoles = getAllowedPathsForRoles(this.props.associateRoles)
    const allowedPathsForGroup = getAllowedPathsForGroup(this.props.associateGroupName, {
      acceptsCash: this.props.acceptsCash,
    })
    const paths = [...allowedPathsForRoles, ...allowedPathsForGroup]

    return routes.filter(route => paths.includes(route.props.path))
  }

  render() {
    const chromeClassName = classNames('chrome sans-serif', {
      'chrome--pushed': this.props.isSidebarVisible,
    })

    if (!this.props.doesAssociateHaveAccess) {
      return <NotAllowed />
    }

    return (
      <div className={chromeClassName}>
        {/* Renders the overlay mask when the sidebar is visible  */}
        {this.props.isSidebarVisible && (
          <div className="fixed z-2 w-100 h-100 bg-black-30" onClick={this.props.hideSidebar} />
        )}

        <FadeIn
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 6,
          }}
        >
          {this.props.currentDialog}
        </FadeIn>

        <Sidebar isMobile={this.props.isMobile} />

        {this.props.isLoading && <LoadingOverlay />}

        <Notifications />

        {this.props.isAssociateExpirerd && (
          <Dialog simple exitable={false} height="auto" width="80vw">
            <div className="flex justify-center">
              <PleaseSignIn showPinPad shouldRefreshOnSuccess={false} />
            </div>
          </Dialog>
        )}

        <div className="content vh-100" style={{ overflow: 'auto' }}>
          <Switch>
            {this.renderRoutes()}
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const storefront = getStorefrontById(state.storefronts, ownProps.match.params.storefrontId)

  return {
    isLoading: getIsLoading(state.activeRequests),
    isSidebarVisible: state.ui.isSidebarVisible,
    currentDialog: state.ui.currentDialog,
    doesAssociateHaveAccess: getDoesAssociateHaveAccessToStorefront(
      state.associate,
      ownProps.match.params.storefrontId,
    ),
    associateRoles: state.associate.roles,
    associateGroupName: state.associate.role_group_name,
    acceptsCash: storefront && storefront.accepts_cash,
    isMobile: state.ui.isMobile,
    storefrontId: storefront?.id,
    isAssociateExpirerd: state.associate.expired,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showSidebar() {
      dispatch(showSidebar())
    },
    hideSidebar() {
      dispatch(hideSidebar())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorefrontInterface)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { dismissNotification } from 'actions/synchronous'
import Icon from 'components/shared/Icon'
import Dialog from 'components/shared/Dialog'
import Button, { ButtonStyles } from 'components/shared/Button'
import FadeIn from 'components/shared/transitions/FadeIn'
import SlideRightLeft from 'components/shared/transitions/SlideRightLeft'
import { NotificationTypes, CTAType } from 'reducers/notifications'

class Notifications extends Component {
  colorsForNotifications = {
    [NotificationTypes.ERROR]: 'b--red',
    [NotificationTypes.INFO]: 'b--blue',
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.props.dismissNotification(NotificationTypes.INFO)
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  renderErrorMessage() {
    return (
      <Dialog
        color="red-4"
        title="An Error Occured"
        textColor="white"
        height="auto"
        onRequestClose={() => this.props.dismissNotification(NotificationTypes.ERROR)}
      >
        <div className="tc">
          <p className="mb5">{this.props.notifications[NotificationTypes.ERROR].message}</p>

          {this.props.notifications[NotificationTypes.ERROR].cta === CTAType.GO_BACK ? (
            <Button
              buttonStyle={ButtonStyles.TERTIARY}
              size="medium"
              onClick={() => {
                this.props.history.goBack()
                this.props.dismissNotification(NotificationTypes.ERROR)
              }}
            >
              Go Back
            </Button>
          ) : (
            <Button
              buttonStyle={ButtonStyles.TERTIARY}
              size="medium"
              onClick={() => this.props.dismissNotification(NotificationTypes.ERROR)}
            >
              Dismiss
            </Button>
          )}
        </div>
      </Dialog>
    )
  }

  renderSuccessMessage() {
    return (
      <div
        onClick={() => this.props.dismissNotification(NotificationTypes.INFO)}
        style={{ minHeight: '64px' }}
        className="b--gray-2 col5 pr4 pl2 pv2 z-4 gray-6 bg-gray-9 bl bw2 flex items-center f4 fixed left-1 bottom-1"
      >
        {this.props.notifications[NotificationTypes.INFO].message}
        <Icon
          glyph="x"
          color="gray-6"
          style={{ right: '0.5rem' }}
          className="absolute f6 center-v"
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        <FadeIn>
          {this.props.notifications[NotificationTypes.ERROR] && this.renderErrorMessage()}
        </FadeIn>

        <SlideRightLeft className="left-1 bottom-1 fixed z-max">
          {this.props.notifications[NotificationTypes.INFO] && this.renderSuccessMessage()}
        </SlideRightLeft>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    notifications: state.notifications,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dismissNotification(notificationKey) {
      dispatch(dismissNotification(notificationKey))
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))

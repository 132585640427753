import React from 'react'
import Button from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'

export default function PackageOpenedDialog({ onRequestClose, onSelectChoice }) {
  const bottomCopy = (
    <p className="tc f5 mt1 lh-copy" style={{ color: '#616161' }}>
      Because this customer already used their First Try, we will only be able to accept the return
      if this item is unopened, damaged or defective, or the incorrect item. If this is not the
      case, please politely decline to refund the customer.
    </p>
  )

  return (
    <Dialog simple height="auto" onRequestClose={onRequestClose}>
      <div className="bg-white ph4">
        <div>
          <p className="fw5 tc">Is the packaging still intact and sealed?</p>
          {bottomCopy}
          <p className="tc f5 gray-6 mb3" />
          <div className="flex items-between">
            <Button
              outlined
              color="gray-2"
              className="mr2 flex-auto"
              onClick={() => onSelectChoice({ isOpened: true })}
            >
              No
            </Button>
            <Button
              color="gray-2"
              className="flex-auto"
              onClick={() => onSelectChoice({ isOpened: false })}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

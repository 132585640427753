import React, { Component } from 'react'
import { array } from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchReceivedReturnUnits } from 'actions/asynchronous'
import {
  startRequest,
  finishRequest,
  showDialog,
  hideDialog,
  showNotification,
  setReturnReceivedUnitsUpcQuery,
} from 'actions/synchronous'
import { getReceivedReturnUnitsForState } from 'reducers/receivedReturnUnits'
import Header from 'containers/Header'
import SpecialCaseQueue from 'containers/returns-queue/SpecialCaseQueue'
import IconWithLabel from 'components/shared/IconWithLabel'
import ProductSearchField from 'components/sales/ProductSearchField'
import TabBar from 'components/shared/TabBar'

export class ReturnsQueueInterface extends Component {
  static propTypes = {
    specialCaseUnits: array,
  }

  pollTimeout = null

  async componentDidMount() {
    this.props.startRequest()
    await this.props.fetchReceivedReturnUnits()
    this.props.finishRequest()
  }

  // We want to automatically refresh this page to keep the data and the session fresh,
  // as this page usually sits idle for awhile. Here we are setting a timeout for
  // 4 minutes after the most recent update, so if a person is actively using this
  // page, we won't needlessly poll–only if it has been idle.
  componentDidUpdate() {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout)
    }

    this.pollTimeout = setTimeout(this.props.fetchReceivedReturnUnits, 240000)
  }

  componentWillUnmount() {
    clearTimeout(this.pollTimeout)
  }

  renderQueue() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/special`}
          render={() => <SpecialCaseQueue receivedReturnUnits={this.props.specialCaseUnits} />}
        />

        <Redirect to={`${this.props.match.url}/special`} />
      </Switch>
    )
  }

  render() {
    return (
      <div>
        <Header textColor="white" />

        <ProductSearchField
          submitOnChange
          onScan={newSearchText => {
            this.props.setReturnReceivedUnitsUpcQuery(newSearchText.length ? newSearchText : null)
          }}
          onSubmit={newSearchText => {
            this.props.setReturnReceivedUnitsUpcQuery(newSearchText.length ? newSearchText : null)
          }}
          onClearClick={() => this.props.setReturnReceivedUnitsUpcQuery(null)}
          placeholderText="Filter return queue by UPC"
        />

        <TabBar
          borderColor="red-6"
          tabs={[
            {
              title: (
                <IconWithLabel
                  icon="ribbon"
                  label="Special Case"
                  color="gray-2"
                  iconClassName="mr1"
                />
              ),
              to: `${this.props.match.url}/special`,
              notificationCount: this.props.specialCaseUnits.length,
              showNotificationCount: true,
            },
          ]}
        />
        {this.renderQueue()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    specialCaseUnits: getReceivedReturnUnitsForState(state.receivedReturnUnits, 'special'),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  const { storefrontId } = ownProps.match.params
  return {
    fetchReceivedReturnUnits: () => dispatch(fetchReceivedReturnUnits(storefrontId)),
    setReturnReceivedUnitsUpcQuery: upc => dispatch(setReturnReceivedUnitsUpcQuery(upc)),
    showNotification: (key, message) => dispatch(showNotification(key, message)),
    showDialog: dialog => dispatch(showDialog(dialog)),
    hideDialog: () => dispatch(hideDialog()),
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsQueueInterface)

import React from 'react'
import classNames from 'classnames'
import BagFeeControls from 'components/sales/BagFeeControls'
import Icon from 'components/shared/Icon'
import ToggleSwitch from 'components/shared/ToggleSwitch'

export default function OrderSummary({
  total,
  subtotal,
  adjustments,
  ctaButton,
  numberOfBags,
  allowEditing = true,
  onAdjustNumberOfBags,
  onSetIsCurbsideOnSale,
  toggleOrderSummary,
  isCurbside,
  showCancelRetailDiscountsButton = false,
  cancelRetailDiscountAdjustments,
}) {
  const rowClassName =
    'relative w-100 h4 f5 lh-solid gray-5 b--gray-9 flex items-center justify-between bt ph2'

  return (
    <div
      className={classNames('flex flex-column absolute bottom-0 left-0 w-100 bg-white', classNames)}
    >
      {toggleOrderSummary && (
        <div onClick={toggleOrderSummary} className={rowClassName}>
          <span>Hide Order Summary</span>
          <Icon
            glyph="arrow-right"
            className="absolute top-0 bottom-0 right-0"
            color="gray-2"
            style={{ transform: 'rotate(90deg)', width: '60px' }}
          />
        </div>
      )}
      <div className={rowClassName}>
        <span>Subtotal:</span>
        <span>{subtotal}</span>
      </div>
      {adjustments.map(({ label, amount }, index) => {
        if (label === 'Bag Fee') {
          return (
            <div key={`bagfee-${index}`} className={rowClassName}>
              <span>{label}:</span>
              {allowEditing && (
                <BagFeeControls
                  numberOfBags={numberOfBags}
                  onIncrementBags={() => onAdjustNumberOfBags(numberOfBags + 1)}
                  onDecrementBags={() => onAdjustNumberOfBags(numberOfBags - 1)}
                />
              )}
              <span>{amount}</span>
            </div>
          )
        }
        if (label === 'Retail Discount') {
          return (
            <div
              key={`override-${index}`}
              className="w-100 h4 f5 lh-solid gray-5 b--gray-9 flex items-center justify-between bt ph2"
            >
              <span>{label}:</span>
              {showCancelRetailDiscountsButton && (
                <span>
                  <button
                    className="bg-white red-6 b--red-6"
                    onClick={cancelRetailDiscountAdjustments}
                  >
                    Remove Retail Discounts
                  </button>
                </span>
              )}
              <span>{amount}</span>
            </div>
          )
        }
        return (
          <div
            key={`adjustment-${index}`}
            className="w-100 h4 f5 lh-solid gray-5 b--gray-9 flex items-center justify-between bt ph2"
          >
            <span>{label}:</span>
            <span>{amount}</span>
          </div>
        )
      })}

      {allowEditing && !!onSetIsCurbsideOnSale && (
        <div className={rowClassName}>
          <span>Curbside:</span>
          <span>
            <ToggleSwitch
              initialActiveState={isCurbside}
              activeLabel="Yes"
              inActiveLabel="No"
              loadingLabel="..."
              onClick={async isActive => {
                try {
                  await onSetIsCurbsideOnSale(isActive)
                  return { didUpdate: true }
                } catch (e) {
                  return { didUpdate: false }
                }
              }}
            />
          </span>
        </div>
      )}

      <div className="w-100 h4 f4 fw5 lh-solid gray-5 b--gray-9 flex items-center justify-between bt ph2 bb">
        <span>Total:</span>
        <span>{total}</span>
      </div>
      {ctaButton}
    </div>
  )
}

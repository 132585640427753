// Return a list of variants
// The purpose of this function is to dedupe variants with the same sizes
// to make the size selection much simpler.
//
// For each size, we check if there's only one variant or more
// When there's only one variant, we return the variant for size selection.
// When there are multiple variants:
// 1. Return the one with the stock and hide one without stock
// 2. If none of the variants has stock, return one of them.
export function dedupeMultipleUpcVariants(variants = []) {
  const variantsBySize = getVariantsBySize(variants)

  const result = Object.entries(variantsBySize).reduce((acc, [size, variants]) => {
    if (variants.length === 1) return acc.concat(variants)

    let variantsWithStock = variants.filter(hasStock)

    if (!variantsWithStock.length) {
      variantsWithStock = variants[0]
    }

    return acc.concat(variantsWithStock)
  }, [])

  return result
}

export function getVariantsBySize(variants = []) {
  return variants.reduce((res, obj) => {
    res[obj.size] = (res[obj.size] || []).concat(obj)

    return res
  }, {})
}

function hasStock(variant) {
  return !emptyStock({ variant })
}

function emptyStock({ variant }) {
  if (!variant) return true

  return (
    variant.shippable_quantity <= 0 &&
    variant.preorderable_quantity <= 0 &&
    variant.storefront_counts.every(storefront => storefront.available_quantity <= 0)
  )
}

import { get } from 'lib/network'
import { retailApiUrl } from 'lib/url'

let constants = {}

export default function getConstants() {
  return constants
}

export async function fetchConstantsFromServer() {
  const { responseJson } = await get(retailApiUrl('constants'))
  constants = { ...constants, ...responseJson }
}

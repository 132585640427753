import React, { useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import getEnv from 'lib/env'
import { post } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import { notify } from 'lib/bugsnag'

function StripePaymentWrapper({ customerEmail, children, onError }) {
  const [clientSecret, setClientSecret] = useState()
  const [stripe, setStripe] = useState(false)
  const [stripeError, setStripeError] = useState(false)

  useEffect(async () => {
    if (!stripe) {
      try {
        const stripeResult = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)
        setStripe(stripeResult)
      } catch (e) {
        notify(e)

        onError()
        setStripeError(true)
      }
    }
  }, [stripe])

  useEffect(() => {
    const getIntent = async () => {
      const stripeIntent = await post(retailApiUrl('stripe/create_setup_intent'), {
        customer_email: customerEmail,
      })
      setClientSecret(stripeIntent?.responseJson?.client_secret)
    }

    getIntent()
  }, [customerEmail])

  if (stripeError) {
    return null
  }

  return (
    <>
      {clientSecret && stripe ? (
        <Elements stripe={stripe} options={{ clientSecret }}>
          {children}
        </Elements>
      ) : (
        <p>Loading…</p>
      )}
    </>
  )
}

export default StripePaymentWrapper

import React, { Children } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

function FadeAndHighlight({
  children,
  in: inProp,
  duration = 300,
  startColor = '#fff8e1',
  highlightColor = '#fff8e1',
  endColor = '#fff',
  className,
}) {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out, background-color ${duration * 4}ms ease-out ${
      duration * 2
    }ms`,
    opacity: 0,
    backgroundColor: startColor,
  }

  const transitionStyles = {
    entering: { opacity: 1, backgroundColor: highlightColor },
    entered: { opacity: 1, backgroundColor: endColor },
  }

  return (
    <Transition unmountOnExit in={inProp} timeout={inProp ? duration * 16 : duration}>
      {state => (
        <div
          className={className}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

export default function FadeAndHighlightGroup({
  children,
  speed = 'average',
  startColor,
  highlightColor,
  endColor,
  className,
}) {
  const duration = {
    slow: 375,
    average: 250,
    fast: 125,
  }[speed]

  return (
    <TransitionGroup>
      {Children.map(children, child => (
        <FadeAndHighlight
          duration={duration}
          startColor={startColor}
          highlightColor={highlightColor}
          endColor={endColor}
          className={className}
        >
          {child}
        </FadeAndHighlight>
      ))}
    </TransitionGroup>
  )
}

import React from 'react'
import classNames from 'classnames'

export default function Checkbox({
  checked,
  onChange,
  id,
  label,
  error,
  placeholder,
  className,
  fontSize = 'f6',
}) {
  return (
    <div className={className}>
      <label className={classNames('flex items-center', fontSize)} htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          className="w2 h2 ba b--gray-6 mr1"
          name={id}
          onChange={onChange}
          value={checked}
          checked={checked}
        />
        {label}
      </label>
    </div>
  )
}

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { retailApiUrl } from 'lib/url'
import { isEmail } from 'lib/validation'
import { get } from 'lib/network'
import { startRequest, finishRequest, showNotification } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import CustomerEmailScreen from 'components/shared/CustomerEmailScreen'

class EmailAvailabilityForm extends Component {
  static propTypes = {
    onCustomerExists: func.isRequired,
    onCustomerDoesNotExist: func.isRequired,
    isFromSale: bool.isRequired,
    showError: func.isRequired,
    startRequest: func,
    finishRequest: func,
    handleBackClick: func,
  }

  handleEmailFormSubmit = async query => {
    if (!isEmail(query)) {
      alert("Looks like you didn't enter an email address. Please try again.")
      return
    }
    if (query === 'manual_orders@everlane.com') {
      alert(
        'That email address is no longer valid. Please use YOUR_STORE_NAME@everlane.com (e.g. prince@everlane.com)',
      )
      return
    }

    this.props.startRequest()

    try {
      const { response, responseJson } = await get(retailApiUrl(`users/${btoa(query)}`), {
        storefront_id: this.props.storefrontId,
        show_cart: true,
      })

      const { user } = responseJson

      if (response.ok) {
        if (user) {
          this.props.onCustomerExists({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            is_banned: user.banned,
          })
        } else {
          this.props.onCustomerDoesNotExist(query)
        }
      } else {
        this.props.onCustomerDoesNotExist(query)
      }
    } catch (e) {
      this.props.onCustomerDoesNotExist(query)
    } finally {
      this.props.finishRequest()
    }
  }

  render() {
    return (
      <CustomerEmailScreen
        headerText="Please enter your email address"
        subheaderText={this.props.isFromSale && 'We will send your receipt here.'}
        backgroundColor="gray-2"
        handleSubmit={this.handleEmailFormSubmit}
        handleBackClick={this.props.handleBackClick}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    storefrontId: ownProps.match.params.storefrontId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: errorMessage => dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailAvailabilityForm))

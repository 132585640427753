import React, { useState } from 'react'
import classNames from 'classnames'

export default function ToggleSwitch({
  initialActiveState,
  onClick,
  activeLabel = 'On',
  inActiveLabel = 'Off',
  loadingLabel = 'Loading...',
  showBothLabels = false,
}) {
  const [isActive, setActive] = useState(initialActiveState)
  const [isRequesting, setRequesting] = useState(false)

  const handleToggleChange = async () => {
    setRequesting(true)
    const { didUpdate } = await onClick(!isActive)
    if (didUpdate) {
      setActive(!isActive)
    }

    setRequesting(false)
  }

  const rightSideLabel = (() => {
    if (isRequesting) {
      return loadingLabel
    }

    if (!showBothLabels && !isActive) {
      return inActiveLabel
    }

    if (!showBothLabels && isActive) {
      return activeLabel
    }

    if (showBothLabels) {
      return activeLabel
    }
  })()

  return (
    <div className="left-0 relative h3 flex items-center" onClick={handleToggleChange}>
      {showBothLabels && <span className="mr1">{inActiveLabel}</span>}
      <div
        className={classNames('db w6 h3 br-pill relative fl mr2', {
          'bg-gray-2': isActive,
          'bg-gray-9': !isActive,
        })}
        style={{ transition: 'background-color 175ms ease-in' }}
      >
        <span
          className="w3 h3 br-100 bg-white db ba b--gray-9 relative"
          style={{
            boxShadow: '0 0 5px 1px rgba(255, 255, 255, 0.3)',
            top: '-1px',
            transform: 'scale(0.95)',
            transition: 'left 175ms ease-out',
            left: isActive ? '24px' : '-1px',
          }}
        />
      </div>
      <span className="dib fl">{rightSideLabel}</span>
    </div>
  )
}

import React from 'react'
import Header from 'containers/Header'
import { Route, Switch, Redirect } from 'react-router-dom'
import InboundInventoryTransfers from 'containers/inventory-transfers/InboundInventoryTransfers'
import OutboundInventoryTransfers from 'containers/inventory-transfers/OutboundInventoryTransfers'
import ViewTransfer from 'containers/inventory-transfers/ViewTransfer'
import AuditTransfer from 'containers/inventory-transfers/AuditTransfer'
import TabBar from 'components/shared/TabBar'
import IconWithLabel from 'components/shared/IconWithLabel'

export default function InventoryTransfers({ match, location, history }) {
  const isViewingOutboundTransfer = location.pathname.includes('/transfer/')

  return (
    <div className="flex flex-column vh-100">
      <Header
        textColor="white"
        left={
          isViewingOutboundTransfer && (
            <IconWithLabel
              icon="arrow-left"
              iconClassName="f5 pr1"
              onClick={() => history.goBack()}
            />
          )
        }
      />
      {!isViewingOutboundTransfer && (
        <TabBar
          borderColor="blue-1"
          tabs={[
            {
              title: 'Inbound Transfers',
              to: `${match.url}/inbound_transfers`,
              notificationCount: 0,
              showNotificationCount: false,
            },
            {
              title: 'Outbound Transfers',
              to: `${match.url}/outbound_transfers`,
              showNotificationCount: false,
            },
          ]}
        />
      )}
      <Switch>
        <Route path={`${match.path}/inbound_transfers`} component={InboundInventoryTransfers} />
        <Route path={`${match.path}/outbound_transfers`} component={OutboundInventoryTransfers} />
        <Route path={`${match.path}/transfer/:id/audit/:receiptId`} component={AuditTransfer} />
        <Route path={`${match.path}/transfer/:id`} component={ViewTransfer} />
        <Redirect to={`${match.url}/inbound_transfers`} />
      </Switch>
    </div>
  )
}

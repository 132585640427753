import React, { Component } from 'react'
import { apiUrl } from 'lib/url'
import { post } from 'lib/network'
import { pub, Event } from 'lib/events'
import { isEmail } from 'lib/validation'
import PageWithHeader from 'components/shared/PageWithHeader'
import Button from 'components/shared/Button'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import Scrollable from 'components/shared/Scrollable'

export default class Waitlist extends Component {
  state = {
    email: '',
    isLoading: false,
    showEmailError: false,
  }

  componentDidMount() {
    if (this.props.location.state.saleNumber) {
      pub(Event.SaleWaitlist.PAGE_VIEW)
    } else {
      pub(Event.InventoryWaitlist.PAGE_VIEW)
    }
  }

  handleEmailFormSubmit = async event => {
    event.preventDefault()

    this.setState({ showEmailError: false, isLoading: true })

    if (!isEmail(this.state.email)) {
      return this.setState({ showEmailError: true })
    }

    const locationState = this.props.location.state

    await post(apiUrl('waitlist_reservations'), {
      waitlist_reservation: {
        variant_id: locationState.variant.variant_id,
        product_id: locationState.variant.product_id,
        email: this.state.email,
      },
      create_visitor_if_necessary: true,
    })

    this.setState({ isLoading: false })

    if (locationState.saleNumber) {
      pub(Event.SaleWaitlist.WAITLIST, {
        product_id: locationState.variant.product_id,
      })
    } else {
      pub(Event.InventoryWaitlist.WAITLIST, {
        product_id: locationState.variant.product_id,
      })
    }

    this.props.history.push(
      locationState.saleNumber
        ? `/storefronts/${this.props.match.params.storefrontId}/customers/waitlist-success?saleNumber=${locationState.saleNumber}`
        : `/storefronts/${this.props.match.params.storefrontId}/customers/waitlist-success`,
    )
  }

  handleBackArrow = () => {
    if (this.props.location.state && this.props.location.state.saleNumber) {
      pub(Event.SaleWaitlist.BACK_TO_SALE)
      this.props.history.push(
        `/storefronts/${this.props.match.params.storefrontId}/sales/${this.props.location.state.saleNumber}/product`,
      )
    } else {
      pub(Event.InventoryWaitlist.BACK_TO_INVENTORY)
      this.props.history.push(`/storefronts/${this.props.match.params.storefrontId}/stock-check`)
    }
  }

  render() {
    const locationState = this.props.location.state

    return (
      <PageWithHeader
        title="Waitlist"
        onBackArrowClick={this.handleBackArrow}
        backArrowLabel={locationState.saleNumber ? 'Sale' : 'Stock Check'}
        textColor="black"
      >
        <Scrollable className="col5 center">
          <p className="f4 tc center">
            Enter your email and we’ll let you know when this item is back in stock.
          </p>
          <div className="center mv2 col4">
            <img alt={locationState.product.name} src={locationState.product.primary_image_url} />
          </div>
          <p className="f4 ma0 mt2 tc">
            {locationState.product.name} Size {locationState.variant.size}
            <br />
            <span className="i">
              Restock on{' '}
              <FormattedDateTime format="short">
                {locationState.variant.restock_date}
              </FormattedDateTime>
            </span>
          </p>
          <div className="center">
            <form onSubmit={this.handleEmailFormSubmit}>
              {this.state.showEmailError && (
                <p className="red">Please enter a valid email address.</p>
              )}
              <h4 className="normal f4 tc mb4">
                {this.props.isFromSale && (
                  <span>
                    Please enter the email address
                    <br />
                    for this account.
                  </span>
                )}
              </h4>

              <label className="db mb3">
                <span className="db mb1">Email Address</span>
                <div className="relative">
                  <input
                    required
                    className="w-100 h6 pl1 ba b--gray-9 input-reset br0 sans-serif"
                    type="email"
                    value={this.state.email || ''}
                    placeholder="Enter your email address"
                    onChange={event => this.setState({ email: event.target.value })}
                  />
                </div>
              </label>

              <Button
                color="gray-2"
                size="full-width"
                className="h6 f4"
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? 'Loading' : 'Waitlist'}
              </Button>
            </form>
          </div>
        </Scrollable>
      </PageWithHeader>
    )
  }
}

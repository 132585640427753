import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Input from 'components/shared/Input'

import { getIdError, getUniqueIdText } from 'lib/UniqueId.jsx'

class UniqueIdInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    country: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: 'unique-id',
  }

  state = {
    value: '',
    error: null,
  }

  isChineseAddress() {
    return this.props.country === 'CN'
  }

  getError() {
    if (!this.state.value) {
      return null
    }

    return getIdError(this.state.value, this.props.country)
  }

  handleChange = event => {
    const input = event.target.value

    this.setState({ value: input }, () => {
      const error = this.getError()
      this.setState({ error })

      if (error) {
        this.props.onChange(null)
        this.props.onError(this.state.error)
      } else {
        this.props.onChange(this.state.value)
        this.props.onError(null)
      }
    })
  }

  render() {
    const uniqueIdText = getUniqueIdText(this.props.country)

    return (
      <Input
        labelClass={`${this.props.className}-label`}
        inputClass={`${this.props.className}-input`}
        type="text"
        label={uniqueIdText.label}
        value={this.state.value}
        onChange={this.handleChange}
        error={this.state.error}
      />
    )
  }
}

export default UniqueIdInput

import React, { Children } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

function Fade({ style, children, in: inProp, duration = 300 }) {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
  }

  return (
    <Transition unmountOnExit in={inProp} timeout={duration}>
      {state => (
        <div
          style={{
            ...style,
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

export default function FadeInGroup({ style, children, speed = 'average' }) {
  const duration = {
    slow: 375,
    average: 250,
    fast: 125,
  }[speed]

  return (
    <TransitionGroup>
      {Children.map(children, child => (
        <Fade style={style} duration={duration}>
          {child}
        </Fade>
      ))}
    </TransitionGroup>
  )
}

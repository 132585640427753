import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { get, patch } from 'lib/network'
import { retailApiUrl } from 'lib/url'
import { getAmountOfHoursStoreHasBeenOpen } from 'lib/time'
import { showNotification, startRequest, finishRequest } from 'actions/synchronous'
import { NotificationTypes } from 'reducers/notifications'
import { getStorefrontById } from 'reducers/storefronts'
import Pagination from 'components/shared/Pagination'
import ScrollableTable from 'components/shared/ScrollableTable'
import Button, { ButtonStyles } from 'components/shared/Button'
import FormattedDateTime from 'components/shared/FormattedDateTime'
import RestockRequestItem from 'components/restock-queue/RestockRequestItem'

class IncompleteRestocksQueue extends Component {
  limit = 40

  state = {
    incompleteRestockRequests: [],
    totalIncompleteRestockRequestCount: 0,
    page: 1,
  }

  async componentDidMount() {
    this.fetchIncompleteRestockRequests()

    this.pollingInterval = setInterval(() => {
      this.fetchIncompleteRestockRequests()
    }, 30000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.fetchIncompleteRestockRequests()
    }

    if (
      prevState.totalIncompleteRestockRequestCount !== this.state.totalIncompleteRestockRequestCount
    ) {
      this.props.onCountChange(this.state.totalIncompleteRestockRequestCount)
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval)
  }

  fetchIncompleteRestockRequests = async () => {
    this.props.startRequest()

    try {
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${this.props.storefront.id}/storefront_restock_requests`),
        {
          page: this.state.page,
          limit: this.limit,
          incomplete: true,
          cutoff_period: getAmountOfHoursStoreHasBeenOpen(this.props.storefront),
        },
      )

      this.props.finishRequest()

      if (response.ok) {
        this.setState({
          totalIncompleteRestockRequestCount: responseJson.metadata.count,
          incompleteRestockRequests: responseJson.storefront_restock_requests,
        })
      } else {
        this.props.showError(responseJson.message)
      }
    } catch (err) {
      this.props.finishRequest()
      this.props.showError(err.message)
    }
  }

  handleSendBackToPending = async restockRequest => {
    this.props.startRequest()

    try {
      const { response, responseJson } = await patch(
        retailApiUrl(
          `storefronts/${this.props.storefront.id}/storefront_restock_requests/${restockRequest.item_restock_id}`,
        ),
        { incomplete: false },
      )

      if (response.ok) {
        this.fetchIncompleteRestockRequests()
        this.props.onMoveToPending()
        this.props.finishRequest()
      } else {
        this.props.finishRequest()
        this.props.showError(responseJson.message)
      }
    } catch (err) {
      this.props.finishRequest()
      this.props.showError(err.message)
    }
  }

  handlePageChange = ({ page }) => {
    this.setState({ page })
  }

  render() {
    const restockRequestRows = this.state.incompleteRestockRequests.map(restockRequest => [
      <FormattedDateTime>{restockRequest.item_sold_at}</FormattedDateTime>,
      <RestockRequestItem
        title={restockRequest.item_title}
        color={restockRequest.item_color}
        imageUrl={restockRequest.item_image_url}
        size={restockRequest.item_size}
        sku={restockRequest.item_sku}
        quantityOnHand={restockRequest.item_quantity_on_hand}
      />,
      <Button
        buttonStyle={ButtonStyles.SECONDARY}
        className="w-100"
        onClick={() => this.handleSendBackToPending(restockRequest)}
      >
        Move to Pending Restocks
      </Button>,
    ])

    const columns = [
      { heading: 'Time', width: '20%' },
      { heading: 'Item', width: '60%' },
      { heading: '', width: '20%' },
    ]

    return (
      <>
        <ScrollableTable
          columns={columns}
          rows={restockRequestRows}
          key={this.state.tableView}
          keys={this.state.incompleteRestockRequests.map(
            rr => `${this.state.tableView}-${rr.item_restock_id}`,
          )}
        />
        {this.state.totalIncompleteRestockRequestCount > this.limit && (
          <Pagination
            width="full"
            totalCount={this.state.totalIncompleteRestockRequestCount}
            onPageChange={this.handlePageChange}
            limit={this.limit}
          />
        )}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    storefront: getStorefrontById(state.storefronts, ownProps.match.params.storefrontId),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startRequest: () => dispatch(startRequest()),
    finishRequest: () => dispatch(finishRequest()),
    showError: errorMessage => dispatch(showNotification(NotificationTypes.ERROR, errorMessage)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteRestocksQueue)

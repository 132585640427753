import React from 'react'
import { Link } from 'react-router-dom'
import Icon from 'components/shared/Icon'

function AddCustomerLink({ onClick, match, storefrontId, saleNumber, returnReceiptId }) {
  const destination = (() => {
    if (saleNumber) {
      return `/storefronts/${storefrontId}/customers/availability?saleNumber=${saleNumber}`
    }
    if (returnReceiptId) {
      return `/storefronts/${storefrontId}/customers/availability?returnNumber=${returnReceiptId}`
    }
  })()

  return (
    <div className="bb b--gray-9">
      <Link
        to={destination}
        className="border-box no-underline flex justify-between items-center pointer bn outline-0 pa2 w-100 db bg-white gray-6 fw4 h9"
        onClick={onClick}
      >
        <div className="flex items-center">
          <span className="flex items-center justify-center br-100 w5 h5 ba b--gray-6 mr2">
            <Icon color="gray-6" glyph="person" />
          </span>
          Add Customer
        </div>
        <div className="flex items-center justify-center br-100 w3 h3 ba b--gray-6">
          <Icon color="gray-6" glyph="plus" />
        </div>
      </Link>
    </div>
  )
}

export default AddCustomerLink

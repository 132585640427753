export function receiveAssociate(associate) {
  return {
    type: 'RECEIVE_ASSOCIATE',
    payload: associate,
  }
}

export function setAssociateExpired(expired) {
  return {
    type: 'SET_ASSOCIATE_EXPIRED',
    payload: expired,
  }
}

export function receiveStorefronts({ storefronts }) {
  return {
    type: 'RECEIVE_STOREFRONTS',
    payload: storefronts,
  }
}

export function receiveStorefront(storefront) {
  return {
    type: 'RECEIVE_STOREFRONT',
    payload: storefront,
  }
}

export function receiveCashFloat({ cash_float }) {
  return {
    type: 'RECEIVE_CASH_FLOAT',
    payload: cash_float,
  }
}

export function receiveCashFloats({ cash_floats }) {
  return {
    type: 'RECEIVE_CASH_FLOATS',
    payload: cash_floats,
  }
}

export function showNotification(key, message, cta) {
  return {
    type: 'SHOW_NOTIFICATION',
    payload: {
      key,
      message,
      cta,
    },
  }
}

export function dismissNotification(key) {
  return {
    type: 'DISMISS_NOTIFICATION',
    payload: {
      key,
    },
  }
}

export function startRequest() {
  return {
    type: 'START_REQUEST',
  }
}

export function finishRequest() {
  return {
    type: 'FINISH_REQUEST',
  }
}
export function showSidebar() {
  return {
    type: 'SHOW_SIDEBAR',
  }
}

export function hideSidebar() {
  return {
    type: 'HIDE_SIDEBAR',
  }
}

export function receiveSalesHistory({ orders }) {
  return {
    type: 'RECEIVE_SALES_HISTORY',
    payload: orders,
  }
}

export function receiveSales({ sales }) {
  return {
    type: 'RECEIVE_SALES',
    payload: sales,
  }
}

export function receiveSale({ sale }) {
  return {
    type: 'RECEIVE_SALE',
    payload: sale,
  }
}

export function removeSale(saleNumber) {
  return {
    type: 'REMOVE_SALE',
    payload: saleNumber,
  }
}

export function setCashAmount(saleNumber, amount) {
  return {
    type: 'SET_CASH_AMOUNT',
    payload: { saleNumber, amount },
  }
}

export function setChangeIssued(saleNumber) {
  return {
    type: 'SET_CHANGE_ISSUED',
    payload: saleNumber,
  }
}

export function receiveReturnReceipt({ return_receipt }) {
  return {
    type: 'RECEIVE_RETURN_RECEIPT',
    payload: return_receipt,
  }
}

export function removeReturnReceipt(returnReceiptId) {
  return {
    type: 'REMOVE_RETURN_RECEIPT',
    payload: returnReceiptId,
  }
}

export function setRefundMethodForReturnReceipt(returnReceiptId, refundMethod) {
  return {
    type: 'SET_REFUND_METHOD_FOR_RETURN_RECEIPT',
    payload: {
      returnReceiptId,
      refundMethod,
    },
  }
}

export function receiveOrderPreview(orderPreview) {
  return {
    type: 'RECEIVE_ORDER_PREVIEW',
    payload: orderPreview,
  }
}

export function showDialog(dialog) {
  return {
    type: 'SHOW_DIALOG',
    payload: dialog,
  }
}

export function hideDialog() {
  return {
    type: 'HIDE_DIALOG',
  }
}

export function receiveReceivedReturnUnits(receivedReturnUnits) {
  return {
    type: 'RECEIVE_RECEIVED_RETURN_UNITS',
    payload: receivedReturnUnits,
  }
}

export function setReturnReceivedUnitsUpcQuery(upc) {
  return {
    type: 'SET_RETURN_RECEIVED_UNITS_UPC_QUERY',
    payload: upc,
  }
}

export function grantFreeShipping(saleNumber) {
  return {
    type: 'GRANT_FREE_SHIPPING',
    payload: saleNumber,
  }
}

export function receiveInventoryTransfer(inventoryTransfer) {
  return {
    type: 'RECEIVE_INVENTORY_TRANSFER',
    payload: inventoryTransfer,
  }
}

export function receiveOrderSearchResults(data) {
  return {
    type: 'RECEIVE_ORDER_SEARCH_RESULTS',
    payload: data,
  }
}

export function clearOrderSearchResults(data) {
  return {
    type: 'CLEAR_ORDER_SEARCH_RESULTS',
  }
}

export function detectMobile(isMobile) {
  return {
    type: 'DETECT_MOBILE',
    payload: isMobile,
  }
}

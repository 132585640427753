import React from 'react'
import Button, { ButtonStyles } from 'components/shared/Button'
import { Status } from 'reducers/returnReceipts'
import classNames from 'classnames'

export default function CTA({
  location,
  returnReceipt,
  returnReceiptStatus,
  onClick,
  disabled,
  className,
  style,
}) {
  const { refundMethod } = returnReceipt

  const completionText = (() => {
    // If the only available refund method for a return is "store credit", then
    // we can safely bypass the "select refund method" screen and simply show the
    // amount of store credit we will refund.
    if (
      !refundMethod &&
      returnReceipt.refund_methods.length === 1 &&
      returnReceipt.refund_methods[0].type === 'store_credit' &&
      returnReceipt.is_gift_return
    ) {
      return `Refund ${returnReceipt.refund_methods[0].credit_payout} in Store Credit`
    }

    if (!refundMethod) {
      return null
    }

    const brand = refundMethod.brand
      ? refundMethod.brand.toUpperCase()
      : {
          alipay_account: 'Alipay',
          paypal_account: 'PayPal',
          afterpay_token: 'Afterpay',
          apple_pay_checkout: 'Apple Pay',
          catch_checkout: 'Catch',
        }[refundMethod.type]

    const refundMethodText =
      refundMethod.type === 'cash' ? 'in Cash' : `to ${brand} ${refundMethod.last_digits || ''}`

    if (refundMethod.type === 'store_credit') {
      return `Refund ${refundMethod.credit_payout} in Store Credit`
    }
    if (refundMethod.credit_payout !== '$0') {
      // prettier-ignore
      return `Refund ${refundMethod.cash_payout} ${refundMethodText} and ${refundMethod.credit_payout} in Store Credit`;
    }
    // prettier-ignore
    return `Refund ${refundMethod.cash_payout} ${refundMethodText}`;
  })()

  const text = (() => {
    switch (returnReceiptStatus) {
      case Status.NO_CUSTOMER:
        return 'Add Customer'
      case Status.NO_ITEMS:
        return 'Add Items'
      case Status.MISSING_REASONS:
        return 'Select Return Reason'
      case Status.NO_REFUND_METHOD:
        return 'Select Refund Method'
      case Status.READY_FOR_COMPLETION:
        return completionText || '…'
      default:
        return 'Loading…'
    }
  })()

  const isDisabled =
    disabled ||
    {
      [Status.NO_REFUND_METHOD]: location.pathname.includes('refund'),
      [Status.MISSING_REASONS]: location.pathname.includes('items'),
      [Status.NO_ITEMS]: location.pathname.includes('items'),
    }[returnReceiptStatus]

  return (
    <Button
      rounded={false}
      className={classNames('white h10', className)}
      style={style}
      size={!style && 'full-width'}
      buttonStyle={ButtonStyles.SECONDARY}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </Button>
  )
}

import React, { Component } from 'react'
import classNames from 'classnames'
import { bool, string, arrayOf, shape, number, object, oneOf } from 'prop-types'
import { pubWithSale, Event } from 'lib/events'
import { staticImageUrl } from 'lib/url'
import { dedupeMultipleUpcVariants } from 'lib/multiUpc'

import VariantSelectionBox from 'components/sales/VariantSelectionBox'
import ProductButtons from 'components/product-search/ProductButtons'
import ProductSearchHit from 'components/product-search/ProductSearchHit'

export default class Product extends Component {
  static propTypes = {
    storefront: object,
    id: number,
    saleNumber: string,
    name: string,
    category: string,
    imageUrl: string,
    price: string,
    variants: arrayOf(
      shape({
        size: string,
      }),
    ),
    initialSelectedVariantId: number,
    width: oneOf(['partial', 'full']),
    isMobile: bool,
  }

  state = {
    selectedVariant: this.props.variants.find(
      variant => variant.variant_id === this.props.initialSelectedVariantId,
    ),
    isMobileVariantExpanded: false,
  }

  imageClassName = {
    partial: 'w12 h12',
    full: 'col2',
  }[this.props.width]

  variantSelectionBoxClassName = {
    partial: 'mh1',
    full: 'mh2',
  }[this.props.width]

  variantSelectionBoxWidth = {
    partial: '21rem', // not really being used here
    full: '38rem',
  }[this.props.width]

  handleSelectVariant = variant => {
    pubWithSale(Event.Sale.SELECT_SIZE, { product_id: this.props.id })
    this.setState({ selectedVariant: variant })
  }

  render() {
    const variants = dedupeMultipleUpcVariants(this.props.variants)

    if (this.props.isMobile) {
      return (
        <ProductSearchHit
          product={this.props.product}
          matchingVariant={this.state.selectedVariant}
          index={0}
          storefront={this.props.storefront}
          sale={this.props.sale}
          isMobile={this.props.isMobile}
          setExpanded={() => {
            this.setState({
              isMobileVariantExpanded: !this.state.isMobileVariantExpanded,
            })
          }}
          isExpanded={this.state.isMobileVariantExpanded}
        />
      )
    }

    return (
      <div
        className={classNames(
          { 'justify-between': this.props.width === 'partial' },
          'flex bb b--gray-9',
        )}
        style={{ padding: '22px 24px' }}
      >
        <div className={classNames(this.imageClassName, 'ma0')}>
          <img
            src={staticImageUrl(this.props.imageUrl, { size: 124 })}
            alt={this.props.name}
            className={classNames(this.imageClassName, 'db')}
          />
        </div>
        <div
          className={classNames(this.variantSelectionBoxClassName, 'flex flex-column')}
          style={{ width: this.variantSelectionBoxWidth }}
        >
          <div>
            <h3 className="f4 normal ma0 lh-title">{this.props.name}</h3>
            <h3 className="f4 normal ma0 lh-title">{this.props.category}</h3>
          </div>
          <div className="mt2 flex flex-row flex-wrap justify-start items-start">
            {variants.map((variant, index) => (
              <VariantSelectionBox
                key={index}
                index={index}
                variant={variant}
                storefront={this.props.storefront}
                isActive={variant.variant_id === this.state.selectedVariant.variant_id}
                onClick={this.handleSelectVariant}
              />
            ))}
          </div>
        </div>
        <div className="h6 tc">
          <p className="f4 ma0">
            <span>{this.props.price}</span>
          </p>
        </div>

        <div className="flex flex-column" style={{ marginLeft: '52px' }}>
          <ProductButtons
            variant={this.state.selectedVariant}
            product={this.props.product}
            storefront={this.props.storefront}
            sale={this.props.sale}
            shouldAddToSale={this.props.sale && this.props.selectedVariant}
          />
        </div>
      </div>
    )
  }
}

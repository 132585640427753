import React from 'react'
import classNames from 'classnames'

export default function ShippingOption({
  name,
  isSelected,
  onClick,
  price,
  amountOfTimeToShip,
  shippingOptionKey,
}) {
  const displayPrice = (() => {
    if (parseFloat(price.slice(1)) <= 0) {
      return 'On Us!'
    }
    return price
  })()

  return (
    <div
      onClick={onClick}
      style={{ width: '15.5rem', padding: '20px' }}
      className={classNames('h-auto flex flex-column ba mv2 mr2', {
        'bw1 b--blue-3': isSelected,
        'b--gray-6': !isSelected,
      })}
    >
      <h4 className="f4 ma0 fw5">{name}</h4>
      <p className="f5 ma0">
        {amountOfTimeToShip}
        <br />
        {displayPrice}
      </p>
    </div>
  )
}

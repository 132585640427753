import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { showSidebar } from 'actions/synchronous'
import TopBar from 'components/shared/TopBar'
import Button, { ButtonStyles } from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import { menuItems } from 'components/navigation/MenuItems'

function Header({
  ctaText,
  ctaDisabled,
  ctaGlyph,
  currentMenuItem,
  onCtaClick,
  right,
  left,
  showSidebar,
  textColor,
}) {
  if (ctaGlyph) {
    right = <Icon color={textColor} glyph="plus" className="f5" onClick={onCtaClick} />
  }

  if (ctaText) {
    right = (
      <Button
        onClick={onCtaClick}
        size="medium"
        buttonStyle={ButtonStyles.PRIMARY}
        disabled={ctaDisabled}
      >
        {ctaText}
      </Button>
    )
  }

  return (
    <TopBar
      textColor={textColor}
      color={currentMenuItem.color}
      left={
        left || <Icon color={textColor} glyph="hamburger" className="f5" onClick={showSidebar} />
      }
      center={currentMenuItem.title}
      right={right}
    />
  )
}

function mapStateToProps(state, ownProps) {
  return {
    currentMenuItem: menuItems.find(menuItem =>
      ownProps.location.pathname.includes(menuItem.partialPath),
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showSidebar() {
      dispatch(showSidebar())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

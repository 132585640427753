import React, { useEffect, useState } from 'react'
import Button, { ButtonStyles } from 'components/shared/Button'
import Dialog from 'components/shared/Dialog'
import { retailApiUrl } from 'lib/url'
import { get } from 'lib/network'

import { useDispatch } from 'react-redux'
import { NotificationTypes } from 'reducers/notifications'
import { showNotification } from 'actions/synchronous'

import styles from './StartInventoryTransferDialog.module.css'

export default function StartInventoryTransferDialog({ onRequestClose, onFormSubmit }) {
  const REASONS = {
    EXIT_ASSORTMENT: 'Exit Assortment',
    STORE_TO_STORE: 'Store to Store',
    OTHER: 'Other',
  }
  const dispatch = useDispatch()
  const [reason, setReason] = useState(REASONS.EXIT_ASSORTMENT)
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)
  const warehouseListRef = React.useRef(null)

  const onChangeWarehouse = warehouse => {
    if (warehouseListRef && warehouseListRef.current) {
      const scrollPosition = warehouseListRef.current.scrollTop
      setSelectedWarehouse(warehouse)

      // Use setTimeout to wait for the state update and then reset the scroll position
      setTimeout(() => {
        if (warehouseListRef.current) {
          warehouseListRef.current.scrollTop = scrollPosition
        }
      }, 0)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    onFormSubmit({ reason, warehouseId: selectedWarehouse?.id })
  }

  const onInputChange = event => {
    setReason(event.target.value)
    setSelectedWarehouse(null)
  }

  const [warehouses, setWarehouses] = useState(null)

  useEffect(() => {
    const loadWarehouses = async function loadWarehouses() {
      const request = await get(retailApiUrl('warehouses'))

      if (request.response.ok) {
        setWarehouses(request.responseJson.warehouses)
      } else {
        dispatch(showNotification(NotificationTypes.ERROR, request.responseJson.message))
      }
    }

    loadWarehouses()
  }, [dispatch])

  function ReasonInput({ value }) {
    return (
      <label className="pr4">
        <input
          type="radio"
          value={REASONS[value]}
          checked={reason === REASONS[value]}
          onChange={onInputChange}
          className="form-check-input mr2"
        />
        {REASONS[value]}
      </label>
    )
  }

  function WarehousesList({ warehouses }) {
    return (
      <div ref={warehouseListRef} className={styles['warehouse-list']}>
        <p className={styles['warehouse-list__title']}>Destination Warehouse:</p>
        <div className={styles['warehouse-list__options']}>
          {warehouses.map(warehouse => (
            <label className={styles['warehouse-list__option']}>
              <input
                type="radio"
                value={warehouse.name}
                checked={selectedWarehouse?.name === warehouse.name}
                onChange={e => onChangeWarehouse(warehouse)}
                className={styles['warehouse-list__radiobox']}
              />
              {warehouse.name}
            </label>
          ))}
        </div>
      </div>
    )
  }

  if (!warehouses) return null

  return (
    <Dialog
      width="55rem"
      height="auto"
      title={<span className="f4">Start Transfer</span>}
      onRequestClose={onRequestClose}
      color="black-10"
    >
      <form className="bg-white ph3">
        <div className="form-check pb1">
          <p className="f4 b">Reason:</p>

          {Object.keys(REASONS).map(value => (
            <ReasonInput key={value} value={value} />
          ))}
        </div>

        <div className={styles.warehouse_list}>
          {reason === REASONS.STORE_TO_STORE && (
            <WarehousesList warehouses={warehouses.filter(warehouse => warehouse['storefront?'])} />
          )}

          {reason === REASONS.OTHER && (
            <WarehousesList
              warehouses={warehouses.filter(warehouse => !warehouse['storefront?'])}
            />
          )}
        </div>

        <div className="form-group mt1">
          <Button
            buttonStyle={ButtonStyles.SECONDARY}
            className="w-100"
            onClick={onSubmit}
            disabled={
              [REASONS.STORE_TO_STORE, REASONS.OTHER].includes(reason) && !selectedWarehouse
            }
          >
            Confirm
          </Button>
        </div>
      </form>
    </Dialog>
  )
}

import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'

function CountNotification({ count, children }) {
  const [showBounceAnimation, toggleShowBounceAnimation] = useState(false)
  useEffect(() => {
    if (count === 0) {
      return
    }

    toggleShowBounceAnimation(true)

    setTimeout(() => {
      toggleShowBounceAnimation(false)
    }, 500)
  }, [count])

  return (
    <>
      {children}
      <span
        className={classNames('bg-red-6 white w4 h4 dib f4 br-pill ml1 relative tc', {
          bounce: showBounceAnimation,
        })}
        style={{
          lineHeight: '26px',
          fontSize: '14px',
          height: '24px',
          minWidth: '24px',
          padding: '2px',
        }}
      >
        {count}
      </span>
    </>
  )
}

export default CountNotification

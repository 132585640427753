import React from 'react'
import { arrayOf, object, oneOfType, shape, string, number, bool } from 'prop-types'
import { NavLink } from 'react-router-dom'
import CountNotification from 'components/returns-queue/CountNotification'

const propTypes = {
  tabs: arrayOf(
    shape({
      title: oneOfType([string, object]),
      to: string.isRequired,
      notificationCount: oneOfType([string, number]),
      showNotificationCount: bool,
    }),
  ).isRequired,
}

function Tab({ to, title, notificationCount, showNotificationCount, borderColor = 'gold-7' }) {
  const tabClassName =
    'f4 no-underline flex items-center justify-center tc h-100 w-100 border-box gray-3 o-50 bb b--white bw2'
  const activeTabClassName = `bb b--${borderColor} bw2 gray-2 fw5`
  const activeTabStyle = { opacity: 1 }
  const tabStyle = { paddingTop: '0.4rem' }

  return (
    <NavLink
      to={to}
      className={tabClassName}
      activeClassName={activeTabClassName}
      activeStyle={activeTabStyle}
      style={tabStyle}
    >
      <div>{title}</div>
      {showNotificationCount && (
        <CountNotification count={typeof notificationCount === 'string' ? 0 : notificationCount} />
      )}
    </NavLink>
  )
}

export default function TabBar({ tabs, borderColor }) {
  return (
    <nav className="h7 flex items-center justify-center bb b--gray-9" style={{ flexShrink: 0 }}>
      {tabs.map((props, index) => (
        <Tab key={index} {...props} borderColor={borderColor} />
      ))}
    </nav>
  )
}

TabBar.propTypes = propTypes

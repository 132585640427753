import * as sync from 'actions/synchronous'
import { NotificationTypes, CTAType } from 'reducers/notifications'
import { get, post, patch, httpDelete } from 'lib/network'
import { apiUrl, retailApiUrl } from 'lib/url'
import { setAssociateAuthToken } from 'lib/tokens'
import { isLocked } from 'lib/session'
import { getCanAssociateUnlock } from 'reducers/associate'
import { getSaleByNumber, getSaleItemsBeingShipped } from 'reducers/sales'
import { getReturnReceiptById } from 'reducers/returnReceipts'
import { PERCENTAGE, FIXED_PRICE } from 'containers/sales/AddAdjustmentOverrideDialog'

export function fetchAssociate() {
  return async dispatch => {
    try {
      const { response, responseJson } = await get(retailApiUrl('retail_user'))

      if (response.ok) {
        if (responseJson.retail_user.roles.length === 0) {
          return dispatch(
            sync.showNotification(
              NotificationTypes.ERROR,
              'You were never assigned a role group. For now, you cannot sign in. Please contact your manager.',
            ),
          )
        }
        if (isLocked() && !getCanAssociateUnlock(responseJson.retail_user)) {
          return dispatch(
            sync.showNotification(
              NotificationTypes.ERROR,
              'The POS has been locked for security reasons.',
            ),
          )
        }

        dispatch(sync.receiveAssociate(responseJson.retail_user))
      } else if (response.status !== 401) {
        // If there is a 401 on this endpoint (`retail_user`), that means their
        // auth token expired, and they just need to PIN in again–no need to show
        // them this error message.
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function updateCustomer(customer) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(apiUrl(`users/${customer.id}`), {
        user: customer,
      })

      if (!response.ok) {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function signInAssociate(authToken) {
  return async dispatch => {
    setAssociateAuthToken(authToken)
    await dispatch(fetchAssociate())
  }
}

export function fetchStorefronts() {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(retailApiUrl('storefronts'))

      if (response.ok) {
        dispatch(sync.receiveStorefronts(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchSales(storefrontId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${storefrontId}/sales`),
      )

      if (response.ok) {
        dispatch(sync.receiveSales(responseJson))
      } else if (response.status !== 401) {
        // If there is a 401 while polling for sales, we just show the PIN pad,
        // there is no need to show them an error message.
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchSale(saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(retailApiUrl(`sales/${saleNumber}`))

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(
          sync.showNotification(NotificationTypes.ERROR, responseJson.message, CTAType.GO_BACK),
        )
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message, CTAType.GO_BACK))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function startNewSale(storefrontId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl(`sales`), {
        storefront_id: storefrontId,
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
        return responseJson
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createSaleWithItem(storefrontId, { variantId, productId, warehouseId, price }) {
  return async dispatch => {
    const { sale } = await dispatch(startNewSale(storefrontId))

    const jsonResponse = await dispatch(
      createSaleItem(sale.number, {
        variantId,
        productId,
        warehouseId,
        price,
      }),
    )

    return jsonResponse
  }
}

export function startNewSaleWithCustomer(storefrontId, customerEmail) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl(`sales`), {
        storefront_id: storefrontId,
        customer_email: customerEmail,
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
        return responseJson.sale
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function addCustomerToSale(customerEmail, saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        customer_email: customerEmail,
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }

      return { response, responseJson }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function removeCustomerFromSale(saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        customer_email: 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchCashFloats(storefrontId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${storefrontId}/cash_floats`),
      )

      if (response.ok) {
        dispatch(sync.receiveCashFloats(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createCashFloat(storefrontId, amount) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        retailApiUrl(`storefronts/${storefrontId}/cash_floats`),
        {
          starting_amount: amount,
        },
      )

      if (response.ok) {
        dispatch(sync.receiveCashFloat(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function editCashFloat(cashFloatId, storefrontId, attrs) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/cash_floats/${cashFloatId}`),
        {
          ...attrs,
        },
      )

      if (response.ok) {
        dispatch(sync.receiveCashFloat(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function deleteSale(saleNumber) {
  return async dispatch => {
    try {
      await httpDelete(retailApiUrl(`sales/${saleNumber}`))
      dispatch(sync.removeSale(saleNumber))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function getGiftCard(code) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(retailApiUrl(`giftcards/${code}`), {
        code,
      })

      if (!response.ok) {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      } else {
        return responseJson
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createSaleItem(
  saleNumber,
  { warehouseId, variantId, productId, price, unitId, unitType },
) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        retailApiUrl(`sales/${saleNumber}/sales_items`),
        {
          sale_item: {
            price,
            variant_id: variantId,
            product_id: productId,
            warehouse_id: warehouseId,
            unit_id: unitId,
            unit_type: unitType,
          },
        },
      )

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
        return responseJson
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function deleteSaleItem(saleNumber, saleItemId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await httpDelete(
        retailApiUrl(`sales/${saleNumber}/sales_items/${saleItemId}`),
      )

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function addCreditCardToSale(saleNumber, creditCard) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        payment_method_id: creditCard ? creditCard.id : 'null',
        payment_method_type: creditCard ? 'CreditCard' : 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function setIsCurbsideOnSale(saleNumber, isCurbside) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        is_curbside: isCurbside,
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function removePaymentFromSale(saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        payment_method_id: 'null',
        payment_method_type: 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createCreditCard({ nonce, saleNumber, paymentType, customerEmail }) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl(`payment_methods`), {
        payment_method_nonce: nonce,
        customer_email: customerEmail,
        payment_type: paymentType,
        disabled: true,
      })

      if (response.ok) {
        await dispatch(addCreditCardToSale(saleNumber, responseJson.credit_card))
      } else {
        let errorMessage

        if (responseJson.data) {
          const field = Object.keys(responseJson.data)[0]
          errorMessage = `${field} ${responseJson.data[field]}`
        } else {
          errorMessage =
            'This credit card could not be added. Please let the engineering team know this happened, as we are currently investigating this issue.'
        }

        return dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function disableCreditCard(card, saleNumber) {
  return async dispatch => {
    dispatch(sync.startRequest())

    try {
      const { response, responseJson } = await patch(retailApiUrl(`credit_cards/${card.id}`), {
        credit_card: { disabled: true },
      })

      if (response.ok) {
        dispatch(fetchSale(saleNumber))
      } else {
        return dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      return dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function applyDiscountCode({ discountCode, customerEmail, saleNumber }) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        retailApiUrl('redeem'),
        {
          token: discountCode,
          customer_email: customerEmail,
        },
        { parseJson: false },
      )

      if (response.ok) {
        return dispatch(fetchSale(saleNumber))
      }
      const message = responseJson?.message || 'The code entered is invalid or has expired.'

      return dispatch(sync.showNotification(NotificationTypes.ERROR, message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

// example value: 20
// When promoType is PERCENTAGE the backend will create an adjustment for 20% off the product price
// when promoType is FIXED_PRICE the backend will create an adjustment for $20 off the product price
export function createRetailDiscountAdjustment({ saleItemId, value, promoType, reason }) {
  return async dispatch => {
    if (![FIXED_PRICE, PERCENTAGE].includes(promoType)) {
      return console.warn('promo type is invalid')
    }

    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl('adjustments'), {
        sale_item_id: saleItemId,
        amount: value,
        description: reason,
        coupon_type: promoType,
      })
      return { response, responseJson }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function cancelRetailDiscountAdjustments(saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await httpDelete(retailApiUrl(`adjustments/${saleNumber}`))
      return { response, responseJson }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createOrder(saleNumber, { uniqueId, stripePaymentIntentId }) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl('orders'), {
        sale_number: saleNumber,
        unique_id: uniqueId,
        stripe_payment_intent_id: stripePaymentIntentId,
      })

      // Component will deal with outcome
      return { response, responseJson }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createReturnReceipt(orderNumber, { isGiftReturn }, storefrontId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(apiUrl('return_receipts'), {
        order_number: orderNumber,
        gift_return: isGiftReturn,
        storefront_id: storefrontId,
      })

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
        return responseJson
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))

      return {}
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function addCustomerToReturnReceipt(customerEmail, returnReceiptId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(apiUrl(`return_receipts/${returnReceiptId}`), {
        customer_email: customerEmail,
      })

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }

      return { response, responseJson }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
      return {}
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchReturnReceipt(returnReceiptId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(apiUrl(`return_receipts/${returnReceiptId}`))

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
        return responseJson
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function addItemToReturn(storefrontId, returnReceiptId, item) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        apiUrl(`return_receipts/${returnReceiptId}/received_return_units`),
        {
          variant_upc: item.variant_upc,
          storefront_id: storefrontId,
        },
      )

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
        return responseJson
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function deleteReturnReceipt(returnReceiptId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await httpDelete(
        apiUrl(`return_receipts/${returnReceiptId}`),
      )

      if (response.ok) {
        dispatch(sync.removeReturnReceipt(returnReceiptId))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function removeCustomerFromReturnReceipt(returnReceiptId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(apiUrl(`return_receipts/${returnReceiptId}`), {
        customer_email: 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function removeReceivedReturnUnit(returnReceiptId, receivedReturnUnitId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await httpDelete(
        apiUrl(`return_receipts/${returnReceiptId}/received_return_units/${receivedReturnUnitId}`),
      )

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function selectReturnReason(returnReceiptId, receivedReturnUnitId, reasonId) {
  return async dispatch => {
    try {
      const { response, responseJson } = await patch(
        apiUrl(`return_receipts/${returnReceiptId}/received_return_units/${receivedReturnUnitId}`),
        {
          selected_return_reason_ids: [reasonId],
        },
      )

      if (response.ok) {
        dispatch(sync.receiveReturnReceipt(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function processReturnReceipt(returnReceiptId, refundMethod) {
  return async (dispatch, getState) => {
    const returnReceipt = getReturnReceiptById(getState().returnReceipts, returnReceiptId)

    // If the only available refund method for a return is "store credit", then
    // we can safely bypass the "select refund method" screen and simply show the
    // amount of store credit we will refund.
    if (
      !refundMethod &&
      returnReceipt.refund_methods.length === 1 &&
      returnReceipt.refund_methods[0].type === 'store_credit' &&
      returnReceipt.is_gift_return
    ) {
      const returnReceipt = getReturnReceiptById(getState().returnReceipts, returnReceiptId)
      refundMethod = returnReceipt.refund_methods[0]
    }

    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(apiUrl(`return_receipts/${returnReceiptId}`), {
        refund: true,
        refund_type: refundMethod.type,
      })

      if (!response.ok) {
        const errorMsg = responseJson.message || 'an error occurred'

        // If the response comes with an error state, it refetches the return receipt
        // to retrieve the latest state from the server, so that we can display the most
        // up-to-date data about it. The state might have changed with a user manipulating
        // the same return receipt in a different tab, for instance.
        await dispatch(fetchReturnReceipt(returnReceiptId))

        dispatch(
          sync.showNotification(
            NotificationTypes.ERROR,
            `Return could not be processed: ${errorMsg}.`,
          ),
        )

        return Promise.reject()
      }
    } catch (err) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          `Return could not be processed: ${err.message}.`,
        ),
      )
      return Promise.reject()
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

// This action allows the ability to create a Return Receipt from an RMA,
// essentially allowing returns started online to be populated/finished in-store
export function createReturnReceiptFromOrder(order, rmaNumber, storefrontId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      // First step here is to create the Return Receipt against the order
      const { return_receipt } = await dispatch(
        createReturnReceipt(order.order_number, { isGiftReturn: false }, storefrontId),
      )

      const rma = order.return_authorizations.find(rma => rma.number === rmaNumber)

      // If the return is not in the `authorized` state, we don't want to auto-
      // select the return items, so we just early return here.
      if (rma.state !== 'authorized') {
        return { return_receipt }
      }

      const alreadyAddedIds = return_receipt.received_return_units.map(rru => rru.id)

      // Here we are looping through the items added to the online return (RMA)
      // so we can add them to the in-store (Return Receipt)
      for (const rmaUnit of rma.return_units) {
        const res = await dispatch(
          addItemToReturn(storefrontId, return_receipt.id, {
            variant_upc: rmaUnit.variant_upc,
          }),
        )

        // We are early returning if there is no return reason to add select
        if (!rmaUnit.return_reason_id) {
          return { return_receipt }
        }

        // Since the `addItemToReturn` action returns the entire `return_receipt`
        // and not just the added item, we are finding the newly added one by
        // comparing our list of already added items.
        const justAddedRetunUnit = res.return_receipt.received_return_units.find(
          rru => rru.upc === rmaUnit.variant_upc && !alreadyAddedIds.includes(rru.id),
        )

        alreadyAddedIds.push(justAddedRetunUnit.id)

        // Here we are adding the return reason the customer selected online to
        // the item just added to the `return_receipt`.
        await dispatch(
          selectReturnReason(return_receipt.id, justAddedRetunUnit.id, rmaUnit.return_reason_id),
        )
      }

      return { return_receipt }
    } catch (e) {
      console.log(e)
      return {}
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchOrderPreview(saleNumber) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(retailApiUrl('orders/preview'), {
        sale_number: saleNumber,
      })

      if (response.ok) {
        dispatch(sync.receiveOrderPreview(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function adjustNumberOfBags(saleNumber, newNumberOfBags) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        bag_count: newNumberOfBags,
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function addAddressToSale(saleNumber, address) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        address_id: address ? address.id : 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        sync.showNotification(NotificationTypes.ERROR, 'Could not add this address to this sale')
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createAddressForSale(customerEmail, saleNumber, address, saveForFutureUse) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(apiUrl('addresses'), {
        address,
        customer_email: customerEmail,
        disabled: !saveForFutureUse,
        unique_id: address.unique_id,
      })

      if (response.ok) {
        await dispatch(addAddressToSale(saleNumber, responseJson.data))
      } else {
        sync.showNotification(NotificationTypes.ERROR, responseJson.message)
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function changeItemSource(saleNumber, itemId, warehouseId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(
        retailApiUrl(`sales/${saleNumber}/sales_items/${itemId}`),
        { warehouse_id: warehouseId },
      )

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        sync.showNotification(
          NotificationTypes.ERROR,
          responseJson.message || "An error occured, and this item's source could not be updated.",
        )
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function applyShippingOptionToSale(saleNumber, shippingOption) {
  return async (dispatch, getState) => {
    const saleItemsBeingShipped = getSaleItemsBeingShipped(getState().sales, saleNumber)

    try {
      dispatch(sync.startRequest())
      await Promise.all(
        saleItemsBeingShipped.map(saleItem =>
          patch(retailApiUrl(`/sales/${saleNumber}/sales_items/${saleItem.id}`), {
            fulfillment_method_key: shippingOption.key,
          }),
        ),
      )

      return dispatch(fetchSale(saleNumber))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function toggleRetailDiscount(saleNumber) {
  return async (dispatch, getState) => {
    const sale = getSaleByNumber(getState().sales, saleNumber)

    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        apply_retail_discount: (!sale.apply_retail_discount).toString(),
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function toggleApplyCredit(saleNumber) {
  return async (dispatch, getState) => {
    const sale = getSaleByNumber(getState().sales, saleNumber)

    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        apply_credits: (!sale.apply_credits).toString(),
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function applyCashToSale(saleNumber, amount) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await patch(retailApiUrl(`sales/${saleNumber}`), {
        paid_in_cash: 'true',
        payment_method_id: 'null',
        payment_method_type: 'null',
      })

      if (response.ok) {
        dispatch(sync.receiveSale(responseJson))
        dispatch(sync.setCashAmount(saleNumber, amount))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function applyNewCredit(saleNumber, amount, reason) {
  return async (dispatch, getState) => {
    const sale = getSaleByNumber(getState().sales, saleNumber)

    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(apiUrl('credits'), {
        credit: {
          customer_email: sale.customer.email,
          amount,
          reason,
        },
      })

      if (response.ok) {
        return dispatch(fetchSale(saleNumber))
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchReceivedReturnUnits(storefrontId) {
  return async dispatch => {
    try {
      const { response, responseJson } = await get(
        retailApiUrl(`storefronts/${storefrontId}/storefront_received_units`),
      )

      if (response.ok) {
        dispatch(sync.receiveReceivedReturnUnits(responseJson))
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function moveReturnUnitToReturnToFloor(storefrontId, id) {
  return async dispatch => {
    try {
      const { response, responseJson } = await patch(
        retailApiUrl(
          `storefronts/${storefrontId}/storefront_received_units/${id}/move_to_return_to_floor`,
        ),
      )

      if (response.status === 422) {
        dispatch(sync.showNotification(NotificationTypes.INFO, responseJson.message))
      } else if (!response.ok) {
        dispatch(sync.showNotification(NotificationTypes.ERROR, 'An error occured'))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function markReturnUnitAsDonated(storefrontId, id) {
  return async dispatch => {
    try {
      const { response, responseJson } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/storefront_received_units/${id}/mark_as_donated`),
      )

      if (response.status === 422) {
        dispatch(sync.showNotification(NotificationTypes.INFO, responseJson.message))
      } else if (!response.ok) {
        dispatch(sync.showNotification(NotificationTypes.ERROR, 'An error occured'))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function updateReturnUnitUpc(storefrontId, id, upc) {
  return async dispatch => {
    try {
      const { response } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/storefront_received_units/${id}/update_upc`),
        { upc },
      )

      if (!response.ok) {
        dispatch(
          sync.showNotification(NotificationTypes.ERROR, 'UPC was not found. Please try again.'),
        )

        return Promise.reject() // the UI needs to know that the request failed in this case
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    }
  }
}

export function markTryonRequestAsDelivered(storefrontId, id) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response } = await patch(
        retailApiUrl(
          `storefronts/${storefrontId}/storefront_tryon_requests/${id}/mark_as_delivered`,
        ),
      )

      if (!response.ok) {
        const errorMessage = 'An error occured'
        dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (e) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          e.message || 'Refresh the page and try again.',
        ),
      )
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function markAllTryonRequestsAsDelivered(storefrontId, id) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/storefront_tryon_requests/mark_all_as_delivered`),
      )

      if (!response.ok) {
        const errorMessage = 'An error occured'
        dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (e) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          e.message || 'Refresh the page and try again.',
        ),
      )
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function markTryonRequestAsSoldOut(storefrontId, id) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response } = await patch(
        retailApiUrl(
          `storefronts/${storefrontId}/storefront_tryon_requests/${id}/mark_as_sold_out`,
        ),
      )

      if (!response.ok) {
        const errorMessage = 'An error occured'
        dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (e) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          e.message || 'Refresh the page and try again.',
        ),
      )
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function markTryonRequestAsReady(storefrontId, id) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/storefront_tryon_requests/${id}/mark_as_ready`),
      )

      if (!response.ok) {
        const errorMessage = 'An error occured'
        dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (e) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          e.message || 'Refresh the page and try again.',
        ),
      )
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function markTryonRequestAsActive(storefrontId, id) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response } = await patch(
        retailApiUrl(`storefronts/${storefrontId}/storefront_tryon_requests/${id}/mark_as_active`),
      )

      if (!response.ok) {
        const errorMessage = 'An error occured'
        dispatch(sync.showNotification(NotificationTypes.ERROR, errorMessage))
      }
    } catch (e) {
      dispatch(
        sync.showNotification(
          NotificationTypes.ERROR,
          e.message || 'Refresh the page and try again.',
        ),
      )
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function updateStorefrontConfiguration(storefrontId, configuration) {
  return async dispatch => {
    const { response, responseJson } = await patch(
      apiUrl(`storefronts/${storefrontId}`),
      configuration,
    )

    if (response.ok) {
      dispatch(sync.receiveStorefront(responseJson.storefront))
    }
  }
}

export function createNewStorefrontTicket(storefrontId, data) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        retailApiUrl(`storefronts/${storefrontId}/storefront_tickets`),
        data,
      )

      if (response.ok) {
        dispatch(
          sync.showNotification(
            NotificationTypes.INFO,
            'Your message has been sent to our CX team.',
          ),
        )
        dispatch(sync.hideDialog())
      } else {
        dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createFreeShippingCoupon(saleNumber, customerId) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(apiUrl(`free_shipping_coupons`), {
        user_id: customerId,
      })

      if (response.ok) {
        return dispatch(fetchSale(saleNumber))
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function createTransfer(storefrontId, { reason, warehouseId }) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await post(
        retailApiUrl(`storefronts/${storefrontId}/inventory_transfers`),
        { reason, warehouse_id: warehouseId },
      )

      if (response.ok) {
        dispatch(sync.receiveInventoryTransfer(responseJson.inventory_transfer))

        return responseJson.inventory_transfer
      }
      dispatch(sync.showNotification(NotificationTypes.ERROR, responseJson.message))
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

export function fetchOrderSearchResults(query, query_type) {
  return async dispatch => {
    try {
      dispatch(sync.startRequest())
      const { response, responseJson } = await get(retailApiUrl('orders'), {
        query,
        query_type,
      })

      if (!response.ok) {
        dispatch(
          sync.showNotification(
            NotificationTypes.ERROR,
            responseJson.message || 'An error occured, please try again.',
          ),
        )
      } else {
        const { orders, customer_metadata } = responseJson

        dispatch(
          sync.receiveOrderSearchResults({
            query,
            orders,
            customer_metadata,
          }),
        )
      }
    } catch (err) {
      dispatch(sync.showNotification(NotificationTypes.ERROR, err.message))
    } finally {
      dispatch(sync.finishRequest())
    }
  }
}

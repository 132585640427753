import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import getEnv, { isProduction } from 'lib/env'

export const HITS_PER_PAGE = 27

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  // https://github.com/algolia/algoliasearch-client-javascript/pull/694
  { _useRequestCache: true },
)

const searchClient = {
  ...algoliaClient,
  search(requests) {
    /* an initial request is sent to Algolia even when there is no query.
     * as a result, user will briefly see a flash of stale hits before
     * showing the query hits. this is a suggested workaround to avoid
     * sending a request to Algolia when there is no search query.
     */
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          page: null,
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

const getIndexName = () => {
  return isProduction() ? 'Product_production' : 'Product_development'
}

function InstantSearchWrapper(props) {
  const [shouldShowInactiveProducts, setShowInactiveProducts] = useState(false)

  return (
    <InstantSearch indexName={getIndexName()} searchClient={searchClient}>
      <Configure
        hitsPerPage={HITS_PER_PAGE}
        filters={shouldShowInactiveProducts ? '' : 'visible=1'}
        enableRules={false}
      />
      {props.children({ shouldShowInactiveProducts, setShowInactiveProducts })}
    </InstantSearch>
  )
}

export default InstantSearchWrapper

import React, { Component } from 'react'
import { array, func, object } from 'prop-types'
import classNames from 'classnames'
import Icon from 'components/shared/Icon'
import { pub, Event } from 'lib/events'

export default class CategoryNav extends Component {
  static propTypes = {
    pendingRestockRequests: array.isRequired,
    categorizedPendingRestockRequests: array.isRequired,
    onSubcategoryClick: func.isRequired,
    onTableViewClick: func.isRequired,
    categoryCounts: object,
  }

  state = {
    categories: [
      {
        name: 'female',
        title: 'Women',
        isOpen: false,
      },
      {
        name: 'male',
        title: 'Men',
        isOpen: false,
      },
      {
        name: 'unisex',
        title: 'Unisex',
        isOpen: false,
      },
    ],
    subcategories: [],
    selectedSubcategory: null,
    currentTableView: 'category',
  }

  static defaultProps = {
    categorizedPendingRestockRequests: [],
  }

  componentDidUpdate(prevProps) {
    if (
      // When we are initially setting the props for the first time
      (this.props.categorizedPendingRestockRequests.length > 0 &&
        this.state.subcategories.length === 0) ||
      // When subsequent updates are made
      prevProps.categorizedPendingRestockRequests.length !==
        this.props.categorizedPendingRestockRequests.length
    ) {
      this.addAllSubcategories()
    }
  }

  addAllSubcategories = () => {
    const allSubcategory = this.state.categories.reduce((all, category) => {
      const requests = this.props.categorizedPendingRestockRequests.reduce(
        (requests, subcategory) =>
          subcategory.subcategory_category === category.name
            ? [...requests, ...subcategory.restock_requests]
            : requests,
        [],
      )

      const newSubcategory = {
        subcategory_title: 'All',
        subcategory_category: category.name,
        restock_requests: requests,
      }

      return [...all, newSubcategory]
    }, [])

    this.setState({
      subcategories: [...this.props.categorizedPendingRestockRequests, ...allSubcategory],
    })
  }

  getSubcategories = category => {
    const subcategories = this.state.subcategories.filter(
      subcategory => subcategory.subcategory_category === category.name,
    )

    subcategories.sort((a, b) => {
      return (
        (a.subcategory_title === 'Other') - (b.subcategory_title === 'Other') ||
        (b.subcategory_title === 'All') - (a.subcategory_title === 'All') ||
        +(a.subcategory_title > b.subcategory_title) ||
        -(a.subcategory_title < b.subcategory_title)
      )
    })

    return subcategories
  }

  getNumberOfPendingRestocksForCategory = category => {
    return this.props.categoryCounts[category] ? this.props.categoryCounts[category].total : 0
  }

  getNumberOfPendingRestocksForSubcategory = subcategory => {
    return this.props.categoryCounts[subcategory.subcategory_category]
      ? this.props.categoryCounts[subcategory.subcategory_category][subcategory.subcategory_title]
      : 0
  }

  handleCategoryClick = (category, subcategories = []) => {
    if (subcategories.length > 1) {
      const updatedCategories = this.state.categories.map(cat => {
        if (cat.name === category.name) {
          category.isOpen = !category.isOpen
          return category
        }
        return cat
      })

      this.setState({ categories: updatedCategories })
    }
  }

  handleSubcategoryClick = subcategory => {
    if (
      subcategory &&
      this.state.selectedSubcategory &&
      this.state.selectedSubcategory.subcategory_title === subcategory.subcategory_title &&
      this.state.selectedSubcategory.subcategory_category === subcategory.subcategory_category
    ) {
      this.setState({ selectedSubcategory: null })
      this.props.onSubcategoryClick({})
    } else {
      this.setState({ selectedSubcategory: subcategory })
      this.props.onSubcategoryClick({ subcategory })
    }
  }

  handleTableViewChange = view => {
    this.setState({ currentTableView: view }, () => {
      if (this.state.currentTableView === 'chronological') {
        this.setState({ selectedSubcategory: null })
        this.props.onSubcategoryClick({})
      }
    })
    this.props.onTableViewClick(view)
  }

  renderCategories() {
    const sections = this.state.categories.map((category, index) => {
      const subcategories = this.getSubcategories(category)
      const numberOfRestocks = this.getNumberOfPendingRestocksForCategory(category.name)

      return (
        <li key={`category-${index}`}>
          <div
            className="h-100 pv2 ph3 bb b--gray-9 fw5 f4"
            onClick={() => this.handleCategoryClick(category, subcategories)}
          >
            <span>{category.title}</span>
            <span className="pl2 red-6">{numberOfRestocks}</span>
            {numberOfRestocks > 0 && (
              <Icon
                color="black"
                glyph="arrow-right"
                style={{
                  transition: 'transform 250ms',
                  transform: category.isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
                  float: 'right',
                  marginTop: '4px',
                }}
              />
            )}
          </div>
          {category.isOpen && subcategories.length > 1 && this.renderSubcategories(subcategories)}
        </li>
      )
    })

    return <ul className="list pl0 center mv0 overflow-y-scroll vh-75">{sections}</ul>
  }

  renderSubcategories(subcategories) {
    const subcategoryItems = subcategories.map((subcategory, index) => {
      const subcategoryClassNames = classNames('flex h5 f4 ph3', {
        'bg-gray-9':
          this.state.selectedSubcategory &&
          this.state.selectedSubcategory.subcategory_title === subcategory.subcategory_title &&
          this.state.selectedSubcategory.subcategory_category === subcategory.subcategory_category,
      })

      const numberOfRestocks =
        subcategory.subcategory_title === 'All'
          ? this.getNumberOfPendingRestocksForCategory(subcategory.subcategory_category)
          : this.getNumberOfPendingRestocksForSubcategory(subcategory)

      return (
        <li
          key={`subcategory-${index}`}
          className={subcategoryClassNames}
          onClick={() => this.handleSubcategoryClick(subcategory)}
        >
          <span className="w-90 tl mv1">{subcategory.subcategory_title}</span>
          <span className="w-10 tr mv1">{numberOfRestocks}</span>
        </li>
      )
    })
    return <ul className="list h-100 pl0 bb b--gray-9">{subcategoryItems}</ul>
  }

  renderIcons() {
    const className = 'w5 h5 pa1 flex items-center justify-center'
    const activeClassName = 'br2 bg-gray-9'

    return (
      <div className="w10 flex items-center justify-center">
        <Icon
          color="black"
          glyph="bullet-list"
          onClick={() => this.handleTableViewChange('category')}
          className={classNames(className, {
            [activeClassName]: this.state.currentTableView === 'category',
          })}
        />
        <Icon
          color="black"
          glyph="clock"
          onClick={() => {
            pub(Event.RestockQueue.CHRONOLOGICAL_VIEW)
            this.handleTableViewChange('chronological')
          }}
          className={classNames(className, {
            [activeClassName]: this.state.currentTableView === 'chronological',
          })}
        />
      </div>
    )
  }

  render() {
    const allCategoryClassNames = classNames('h-100 pv2 ph3 bb b--gray-9 fw5 f4', {
      'bg-gray-9': this.state.selectedSubcategory === null,
    })
    return (
      <nav className="fl w-25">
        <div className="w-100 relative z-1 dt bb b--gray-9 border-box pl3 pr1 h6 flex justify-between">
          <h1 className="fw5 f4">
            {this.state.currentTableView === 'category' ? 'Categories' : 'Chronological'}
          </h1>
          {this.renderIcons()}
        </div>
        <div className={allCategoryClassNames} onClick={() => this.handleSubcategoryClick(null)}>
          <span>All Restocks</span>
          <span className="pl2 red-6">{this.props.categoryCounts.total}</span>
        </div>
        {this.state.currentTableView === 'category' && this.renderCategories()}
      </nav>
    )
  }
}

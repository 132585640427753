import React from 'react'
import Scrollable from 'components/shared/Scrollable'
import Address from 'components/sales/Address'
import SaleItem from 'components/sales/SaleItem'

export default function SaleDescriptionSidebar({ sale, itemsBeingShipped, showAddress }) {
  const heightOfTopBar = 72
  const spaceBetweenBorder = 32

  return (
    <div
      className="relative w-100 br b--gray-9 pa1"
      style={{
        // This border needs to stop 32 pixes below the top of the screen and 32 pixels from the bottom of the screen.
        // In order to calculate the height of this container to accomplish such a thing, we need to do some grossness.
        height: document.documentElement.clientHeight - heightOfTopBar - spaceBetweenBorder * 2,
        top: 32,
      }}
    >
      <h3 className="f4 ma0 normal pl1 fw5">Items to Ship</h3>
      <Scrollable className="fake-scrollbar" height={300}>
        {itemsBeingShipped.map((saleItem, index) => (
          <SaleItem
            key={saleItem.id}
            id={saleItem.id}
            title={saleItem.title}
            price={saleItem.price}
            imageUrl={saleItem.image_url}
            size={saleItem.size}
            source={saleItem.source}
            warehouseId={saleItem.warehouse_id}
            restockDate={saleItem.variant_restock_date}
            orderableState={saleItem.variant_orderable_state}
            availableSources={saleItem.available_sources}
            allowEditing={false}
            isLastItem={index === itemsBeingShipped.length - 1}
          />
        ))}
      </Scrollable>
      {showAddress && (
        <div className="bg-white ml3 pt2 pb6">
          <h4 className="f4 fw5 mb2 mt0">Shipping Address</h4>
          <Address
            fullName={sale.shipping_address.full_name}
            streetAddress={sale.shipping_address.street_address}
            extendedAddress={sale.shipping_address.extended_address}
            phoneNumber={sale.shipping_address.formatted_phone}
            city={sale.shipping_address.city}
            region={sale.shipping_address.region_code}
            country={sale.shipping_address.country}
            postalCode={sale.shipping_address.postal_code}
            showBorder={false}
            ignoreSpacing
          />
        </div>
      )}
    </div>
  )
}

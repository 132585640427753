import React from 'react'
import LineItem from 'components/shared/LineItem'

export function getReturnItems(returnReceipts) {
  const items = returnReceipts.map(receipt => {
    return {
      id: receipt.id,
      time: receipt.time,
      number: receipt.order_number,
      customer_name: receipt.customer_name.trim().length
        ? receipt.customer_name
        : receipt.customer_email,
      total: receipt.total,
      ambassador: receipt.ambassador,
      paid_in_cash: receipt.paid_in_cash,
      line_items: receipt.items_returned.map((item, index) => (
        <LineItem
          key={item.id}
          imageUrl={item.image_url}
          source={item.source}
          title={item.title}
          size={item.size}
          upc={item.upc}
          color={item.color}
          quantity={item.quantity}
          restockDate={item.restock_date}
          isFirstItem={index === 0}
          isLastItem={index === receipt.items_returned.length - 1}
        />
      )),
    }
  })

  return items
}

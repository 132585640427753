import validid from 'validid'

export function isEmail(maybeEmail) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(maybeEmail)
}

export function isValidExpirationDate(expirationDateString) {
  const givenMonth = Number(expirationDateString.split('/')[0])
  const givenYear = Number(expirationDateString.split('/')[1])
  return new Date(`20${givenYear}`, givenMonth) >= new Date()
}

export function isValidPhoneNumber(phoneNumberString) {
  const onlyDigits = phoneNumberString.replace(/\D/g, '')
  // US phone numbers have 10 digits, but international countries are more variable,
  // due to 2–3 digit country codes (e.g. +44, +852).
  return [10, 11, 12].includes(onlyDigits.length)
}

export function isValidChineseResidentId(id) {
  return validid.cnid(id)
}

export function isSimplifiedChinese(text) {
  return /[\u3400-\u9FBf]/.test(text)
}

export function isValidChinesePhoneNumber(number) {
  return /^[1](\d\s*){10}$/.test(number)
}

export function isCashValueValid(cashValue) {
  if (!cashValue) {
    return false
  }

  const valueArray = cashValue.split('.')
  if (valueArray.length > 2 || (valueArray.length === 2 && valueArray[1].length > 2)) {
    return false
  }

  return true
}

export function isUpc(string) {
  return string?.indexOf('0-') === 0 && string?.length === 15
}

export function isValidTransferNumber(string) {
  return string.indexOf('IT') === 0 && string.length >= 12
}

export function isValidTrackingNumber(string) {
  return string.indexOf('1Z') === 0 && string.length === 18
}

export function isValidContainerIdentifier(string) {
  return /^([0-9]{1,20})$/i.test(string)
}

export function handleSearchOrders(query, showError, handleSearch) {
  const orderRegex = /^[rRmM]\d+$/
  const shipmentRegex = /^[rRmM]\d+-\d+$/
  const emailRegex = /^[^@]+@.+/

  let queryType
  let dropDownLabel
  let error = null

  if (orderRegex.test(query) || shipmentRegex.test(query)) {
    queryType = 'order'
    dropDownLabel = 'Order No.'
  } else if (emailRegex.test(query)) {
    queryType = 'email'
    dropDownLabel = 'Email'
  } else if (query.split(' ').length > 1) {
    queryType = 'name'
    dropDownLabel = 'Full Name'
  } else if (query.startsWith('s') || query.startsWith('RTNLY_')) {
    queryType = 'rma'
    dropDownLabel = 'Return Number'
  } else {
    error = 'You query is invalid. Please try again.'
  }

  if (error) {
    showError(error)
  } else {
    handleSearch(dropDownLabel, query, queryType)
  }
}
